.artist-bio {
  background-color: #F7F7F7;
  border-left: 1px #DDDDDD solid;
  padding-top: 10px;

  &__text-block {
    padding: 30px 10px;
    float: left;
    width: 100%;
  }

  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
    margin-top: 10px;
  }

  p {
    @include lato;
    font-size: 14px;
    color: #111;
  }
}