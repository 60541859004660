.artist-events {
  background-color: #FFFFFF;
  padding: 30px 20px 0;

  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
  }

  &__notice {
    @include lato-italic;
    color: rgba(17, 17, 17, 0.5);
    margin-top: 10px;
  }

  & .events {
    margin-bottom: 30px;
  }

  &__view-more {
    display: block;
    margin-left: 105px;
    color: $main-accent-color;
    @include lato-bold;
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 50px;

    &:focus, &:hover {
      color: $main-accent-color-darker;
      text-decoration: underline;
    }
  }
}