.left-content-section {
  background-color: #F7F7F7;
  border-right: 1px #DDDDDD solid;
}

.right-content-section {
  background-color: #FFFFFF;
  padding-bottom: 40px;
}


.search-results {
  background-color: #FFFFFF;
  padding: 20px;

  &__section-title {
    @include lato-black;
    font-size: 32px;
    color: #999999;
    margin-top: 10px;
  }
}