.newly-added-list {
  background-color: #F7F7F7;
  border-top: 1px #DDDDDD solid;
  padding: 20px 30px 30px;

  &__section-title {
    @include section-title;
    margin-bottom: 30px;
  }
}

.newly-added {
  @include center-block;
  color: white;
  position: relative;
  width: 100%;

  &__image {
    @include img-responsive;
    width: 100%;
    margin-bottom: 10px;
  }

  &__image-container {
    height: 220px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    margin-bottom: 20px;
  }

  &__date {
    @include lato-bold;
    font-size: 14px;
    color: #777777;
    margin-bottom: 4px;
  }

  &__title, &__title a {
    @include lato-bold;
    font-size: 16px;
    color: #131313;
    margin-top: 0;
    height: 34px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__requests {
    @include lato;
    font-size: 16px;
    color: $main-accent-color;

    &--number {
      @include lato-black;
    }
  }

  &__request {
    @include red-button;
    margin-bottom: 20px;
  }

}

#newly-added--audio {
  display: none;
}
