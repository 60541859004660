.schedule-event-details {
  min-height: 340px;
  background-color: #1B191A;
  background-size: cover;

  &__title {
    @include lato-black;
    font-size: 60px;
    text-transform: uppercase;
    color: $main-accent-color;
    margin-top: 43px;
    margin-bottom: 16px;
  }

  &__artists {
    @include lato-black;
    font-size: 14px;
    color: #fefefe;
    line-height: 160%;
    padding-right: 50px;
    letter-spacing: 0.07em;
    margin-bottom: 50px;
  }

  &__instrument {
    @include lato;
    font-size: 14px;
  }

  &__event-button {
    @include lato-bold-italic;
    color: #FEFEFE;
    border: 0;
    background-color: $main-accent-color;
    display: block;
    float: left;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 10px 4px 7px;
    margin-bottom: 20px;
  }
}

.schedule-event-select {
  min-height: 56px;
  color: #fefefe;
  background-color: $main-accent-color;

  &__title {
    @include lato-black;
    font-size: 24px;
    display: inline-block;
    margin-top: 13px;
  }

  &__date-select {
    @include lato-bold;
    font-size: 14px;
    background-color: transparent;
    color: #fefefe;
    height: 26px;

  }

  &__slots {
    @include lato-bold;
    display: inline-block;
    font-size: 14px;
    margin-top: 18px;


    li {
      color: #F4C4CC;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }

    li:hover {
      color: white;
    }


  }
}
