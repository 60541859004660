.signup-membership {
  padding: 0;
  background-color: #fff;
  @include lato;

  &:before {
    display: none;
  }

  &__header {
    background-image: url('../image/home-membership-cta.jpg');
    background-position: center center;
    background-size: cover;
    text-align: center;
    border-bottom: 8px solid $main-accent-color;
    color: #fff;
    width: 100%;
    padding: 20px 20px;
    position: relative;

    &__title {
      @include lato-black;
      font-size: 32px;
      max-width: 640px;
      margin: 0 auto 15px;
      line-height: 130%;

      @media(max-width:$screen-xs-max) {
        font-size: 26px;
      }
    }

    &__text {
      @include lato-bold;
      font-size: 14px;
      margin: 0 auto;
      line-height: 130%;
      max-width: 760px;
    }

    & .cto__button {
      margin-top: 30px;
    }

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $main-accent-color;
      border-width: 30px;
      margin-left: -30px;
    }
  }

  &__points {
    padding: 40px 10px 40px;
    text-align: center;
    float: left;
    @include display(flex);
    @include flex-wrap(wrap);
    border-top: 8px solid $main-accent-color;
    position: relative;
    margin-bottom: 0;
    width: 100%;

    & .slick-dots {
      position: relative;
      margin-bottom: 40px;
      margin-top: -20px;
    }

    &__single {
      padding: 0 20px;
      margin-top: 30px;

      &.slick-slide {
        display: block;
      }
    }

    &__graphic {
      margin-bottom: 20px;
      display: inline-block;

      & img {
        max-width: 54px;
        max-height: 54px;
        width: auto;
        height: auto;
      }
    }

    &:before {
      top: 0;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $main-accent-color;
      border-width: 20px;
      margin-left: -20px;
    }
  }
}

.signup-plans {
  width: 100%;
  padding: 40px 0;
  background-color: #efefef;

  @media(max-width:$screen-xs-max) {
    padding: 30px 0;
  }

  & .container {
    @include display(flex);
    @include align-items(stretch);
    @include flex-wrap(wrap);

    &:before {
      display: none;
    }

    @media(max-width:$screen-xs-max) {
      padding: 0;
    }
  }

  &__downgrade-notice {
      @include lato;
      font-size: 12px;
      display: block;
      width: 100%;
      margin-top: 20px;
      padding: 0 20px;
      margin-bottom: 0;
    }
}

.signup-plan {
  border: 1px solid #858585;
  background-color: #fff;
  margin-bottom: 30px;
  width: 100%;

  @include display(inline-flex);
  @include align-items(stretch);

  @media(max-width:$screen-xs-max) {
    padding: 0;
    margin: 20px;
    @include flex-wrap(wrap);
  }

  ul {
    list-style-image: url('/static/image/hollow-square.png');
    margin: 10px 0;
  }

  &__picture {
    text-align: center;
    background-color: #000000;
    border-right: 5px solid $main-accent-color;
    padding-left: 0px;
    padding-right: 0px;
    min-width: 270px;
    @include align-self(flex-end);
  }

  &__complete {
    border: 1px solid #858585;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  &__content {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    @include display(flex);
    @include flex-direction(column);

    @media(max-width:$screen-xs-max) {
      border-right: none;
      border-left: none;
      border-bottom: none;
      border-width: 4px;
    }
  }

  &__arrow-separator {
    width: 100%;
    @include flex-grow(0);
    border-bottom: 1px solid #d6d6d6;
    height: 1px;
    position: relative;
    margin: 15px 0 25px;

    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 15px;
      margin-left: -15px;
    }

    &:before {
      border-color: rgba(214, 214, 214, 0);
      border-top-color: #d6d6d6;
      border-width: 16px;
      margin-left: -16px;
    }
  }

  &__jump {
    background-color: #fff !important;
  }

  &__level {
    color: $main-accent-color;
    font-size: 20px;
  }

  &__name {
    @include align-self(flex-start);
    @include lato-black;
    min-height: 70px;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    display: block;

    @media(max-width:$screen-xs-max) {
      min-height: 0;
    }
  }

  &__price {

    &__container {
      width: 100%;
      @include align-self(flex-start);
      @include flex-grow(0);
      min-height: 171px;

      @media(max-width:$screen-xs-max) {
        min-height: 0;
      }
    }

    &__free {
      font-size: 28px;
      color: $main-accent-color;
      text-transform: uppercase;
      text-align: left;

      @media(max-width:$screen-xs-max) {
        padding: 0;
      }

      &.more-info {
        font-size: 24px;
      }
    }

    &__primary {
      font-size: 28px;
      color: $main-accent-color;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
      margin-top: -10px;
    }

    &__promo {
      @include lato-black;
      font-size: 14px;
      text-align: center;
      color: $main-accent-color;
      width: 100%;
      padding: 10px 0;
      border-top: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
      text-transform: uppercase;
    }
  }

  &__features {
    display: block;
    padding-left: 20px;
    @include flex-grow(1);

    &__single {
      @include lato;
      margin-bottom: 2px;
    }

    &__revenue {
      color: $main-accent-color;
      font-weight: bold;
    }
  }

  &__button {
    @include align-self(flex-end);
    max-width: 75%;
    margin: 0 auto;
    display: block;
    background-color: $main-accent-color;
    color: #fff;
    @include lato-bold;
    font-size: 16px;
    text-align: center;
    padding: 10px;
    margin-top: 1px;

    &:hover, &:focus {
      outline: none;
      border: none;
      color: rgba(255,255,255,0.6);
    }

    &.disabled {
      background-color: #dfdfdf;
    }

    @media(max-width:$screen-xs-max) {
      min-height: 0;
    }
  }
}

.button-wrapper {
  padding: 20px 0;
}

.drop {
  margin-top: 5px;
}

.signup-premium {
  background-image: url('../image/premium-bg-image.jpg');
  background-position: center center;
  background-size: cover;
  min-height: 250px;
  color: #fff;
  text-align: center;
  padding: 30px 30px;
  border-top: 8px solid $main-accent-color;
  border-bottom: 8px solid $main-accent-color;

  &__title {
    @include lato-black;
    font-size: 32px;
    max-width: 760px;
    margin: 0 auto 10px;
  }

  &__text {
    @include lato-bold;
    font-size: 14px;
    max-width: 760px;
    margin: 0 auto;
  }

  &__button {
    background-color: transparent;
    border-color: #fff;

    &:hover, &:focus {
      outline: none;
      color: rgba(255,255,255,0.7);
      background-color: rgba(255,255,255,0.2);
    }
  }
}

.signup-step {

  &__header {
    background-image: url('../image/home-membership-cta.jpg');
    background-position: center center;
    padding: 30px;
    color: #fff;
    text-align: center;
    border-bottom: 8px solid $main-accent-color;

    & h1 {
      @include lato-black;
      font-size: 48px;
      margin: 0 0 10px 0;
    }

    & h2 {
      @include lato-black;
      font-size: 36px;
      margin: 0 0 5px 0;
    }

    & h3 {
      @include lato;
      font-size: 16px;
      margin: 0 0 25px 0;
    }

    &__info {
      @include lato;
      font-size: 14px;
      color: rgba(255,255,255,0.7);
      padding: 10px 0;
      border-top: 1px solid rgba(255,255,255,0.2);
      border-bottom: 1px solid rgba(255,255,255,0.2);
      max-width: 480px;
      margin: 0 auto;
    }
  }

  &__social {
    background-color: #efefef;
    border-bottom: 1px solid #ddd;
    padding: 40px 20px;
    text-align: center;

    & h2 {
      @include lato-black;
      font-size: 18px;
      line-height: 130%;
      margin: 0 auto 20px;
      max-width: 360px;
    }

    & h3 {
      @include lato;
      font-size: 14px;
      opacity: 0.7;
      margin: 0 auto;
    }

    &__button {
      display: inline-block;
      background-color: #3B5998;
      padding: 15px 40px;
      color: #fff;
      @include lato-bold;
      margin-bottom: 40px;
      font-size: 16px;

      &:hover, &:focus {
        outline: none;
        opacity: 0.8;
        color: #fff;
      }
    }
  }
}

.signup__payment {
  padding: 40px 20px;
  background-color: #fff;

  &__heading {
    @include lato-black;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__text {
    @include lato;
    font-size: 14px;
    margin: 0;
  }

  &__type {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;

    &__selection {

      &__container {
        margin-top: 20px;
      }

      &__option {
        margin-top: 5px;

        & input[type="radio"] {
          display: none;
        }

        & label {
          cursor: pointer;

          &:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            position: absolute;
            background-color: #fff;
            border-radius: 100%;
            border: 1px solid #A4A4A4;
          }
        }

        & input[type=radio]:checked + label:before {
          content: "\2022";
          color: #666;
          font-size: 34px;
          text-align: center;
          line-height: 17px;
          padding-left: 1px;
          font-weight: normal;
        }

        & span {
          @include lato-bold;
          font-size: 14px;
          margin-left: 28px;

          &.price {
            @include lato;
            color: $main-accent-color;
            margin-left: 5px;
          }
        }
      }
    }
  }

  &__information {
    padding: 30px 0;
    @include display(flex);
    @include align-content(stretch);
    @include flex-wrap(wrap);

    &:before {
      display: none;
    }

    & label {
      @include lato;
      font-size: 12px;
    }

    & label#expiry-label {
      width: 51%;
    }

    & label#cvc-label {
      width: 47%;
    }

    & input, & select {
      @include lato;
      font-size: 16px;
      padding: 10px 15px;
      border-radius: 0;
      margin-bottom: 15px;
      display: inline-block;

      &.half {
        width: 47.5%;
        margin-right: 0;
      }

      &.quarter-group {
        width: 22.5%;
        margin-right: 1.5%;

        &:nth-child(2n) {
          margin-right: 3%;
        }
      }
    }

    & input[type="submit"], .submit-button {
      @include lato-bold;
      color: #fff;
      background-color: $main-accent-color;
      padding: 10px 60px;
      margin-top: 20px;
      font-size: 16px;
      margin-right: 5px;

      &:hover, &:focus {
        color: rgba(255,255,255,0.7);
        outline: none;
        border: none;
      }

      &.disabled {
        background-color: #dedede;
      }
    }

    .light-button {
      background-color: #cbcbcb;
      color: #9a9a9a;
    }
  }

  &__existing-card {
    @include lato;
    padding: 10px;
    border: 1px solid #ddd;
    display: inline-block;
    margin: 0 5px;
    width: 100%;

      & a {
        @include lato-bold;
        color: $main-accent-color;

        &:hover, &:focus {
          color: $main-accent-color-darker
        }
      }
    }

  &__card-icons {
      margin-top: 10px;
      margin-bottom: 15px;

      & img {
        height: 42px;
        width: auto;
        border: 1px solid #ddd;
        margin-bottom: 5px;
      }
    }

  &__card__image {
    @include display(flex);
    @include align-content(center);

    & .card-wrapper {
      @include display(flex);
      margin: 20px auto;

      & .jp-card.jp-card-identified {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}

.signup__done {
  @include display(flex);
  @include flex-wrap(wrap);
  @include align-content(stretch);
  background-color: #fff;
  padding: 40px 20px;

  &:before {
    display: none;
  }

  & .signup-plan__name {
    @include lato-black;
    font-size: 24px;
    color: $main-accent-color;
    margin-bottom: 0;
  }

  &__content {

    & .vertical-align-child {
      padding: 0;
    }

    &__heading {
      @include lato-black;
      font-size: 28px;
      margin-top: 0;
      margin-bottom: 20px;
    }

    & ul {
      padding-left: 20px;

      & li {
        @include lato;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.signup__what-next {
  padding: 60px 20px;
  border-top: 1px solid #ddd;
  background-color: #fff;
  text-align: center;

  & h1 {
    @include lato-black;
    font-size: 36px;
    color: $main-accent-color;
    margin-top: 0;
    margin-bottom: 15px;
  }

  & p {
    @include lato-bold;
    font-size: 14px;
    max-width: 640px;
    margin: 0 auto;
  }

  & .cto__button {
    background-color: $main-accent-color;
    border: none;
    margin-top: 20px;

    &:hover, &:focus {
      background-color: $main-accent-color;
      color: rgba(255,255,255,0.7);
    }
  }
}

@media (max-width: $screen-sm-max) {
  .signup-plan {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .card-wrapper {
    display: none !important;
  }

  .signup__done__plan {
    margin-bottom: 30px;
  }
}
