$tablet-break: 650px;

.store-header {
  padding: 0 30px;
  text-align: left;
  border-bottom: none;
  @include title2;

  @media (max-width: $screen-sm-max) {
    padding: 70px 0 0;
  }

  &__title {

    &__container {
      margin: 0;
      text-align: center;

      .title1 {
        display: inline-block;
        border-bottom: 4px solid var(--color-white);
        font-size: 5.4rem;
        line-height: 4.9rem;
        margin-bottom: 1.6rem;
        width: 70%;
        min-width: 395px;

        @media (max-width: 440px) {
          min-width: 280px;
          width: 100%;
        }

        &--thankyou {
          font-size: 45px;
          width: 95%;
          max-width: 1050px;
          line-height: 43px;

          @media (max-width: 1023px) {
            //margin-top: 30px;
          }
          @media (max-width: 1200px) {
            font-size: calc(1.93181818vw + 21.81818181px);
            line-height: calc(1.93181818vw + 19.81818181px);
          }
        }

        &--reservation {
          @media (max-width: 1023px) {
            padding-top: 30px;
          }
        }
      }
    }

    &__divider {
      margin: 0 15px;
      display: inline-block;

      @media (max-width: $screen-xs-max) {
        margin: 0 8px;
      }
    }
  }

  &__expanded {
    padding: 40px 30px 50px;
    text-align: center;
    color: var(--color-white);
    background-size: cover;
    background-image: url('../image/store/store-header-bg.jpg');
    background-position: center center;
    border-bottom: 8px solid var(--color-crimson);

    &__content {
      width: 100%;
      max-width: 760px;
      margin: 0 auto;
    }

    &__title {
      margin: 0 0 15px;
      font-size: 48px;

      @media (max-width: $screen-xs-max) {
        font-size: 30px;
      }
    }

    & p {
      margin: 0 0 10px;
      font-size: 16px;

      &.small {
        @include lato;
        font-size: 14px;
      }

      @media (max-width: $screen-xs-max) {
        font-size: 14px;
      }
    }
  }
}

.gift-button {
  margin-bottom: 20px;
}

.store-search {
  color: #131313;

  &__container {
    padding: 10px 15px;
    background-color: var(--color-white);
    border-radius: 5px;
    border: 1px solid var(--color-light-gray);
    margin: 20px auto 0;
    width: 100%;
    max-width: 460px;
    @include display(flex);
    @include align-items(center);
    @include justify-content(space-between);

    &.small {
      padding: 10px 8px;
    }
  }

  .m0 {
    margin: 0;
  }

  &__large {

    &__icon {
      font-size: 22px;
      color: #131313;
      opacity: 0.2;
      @include flex-shrink(0);
    }

    &__input {
      @include flex-shrink(1);
      font-size: 16px;
      outline: none;
      border: none;
      padding: 0 10px;
      @include transition(all 0.3s ease-in-out);
      color: #131313;
      width: 100%;

      &:hover,
      &:focus {
        border: none;
        outline: none;
        padding: 0 15px;
      }
    }

    &__button {
      @include flex-shrink(0);
      background: none;
      color: #131313;
      opacity: 0.2;
      border: none;
      outline: none;
      padding: 0;

      & i {
        font-size: 26px;
      }

      &:hover,
      &:focus {
        opacity: 0.8;
        @include transition(all 0.3s ease-in-out);
      }
    }
  }

  &__small {

    &__icon {
      font-size: 18px;
      color: #131313;
      opacity: 0.2;
      @include flex-shrink(0);
    }

    &__input {
      @include flex-shrink(1);
      font-size: 16px;
      @include lato-italic;
      outline: none;
      border: none;
      padding: 0 10px;
      @include transition(all 0.3s ease-in-out);
      color: #131313;
      width: 100%;

      &:hover,
      &:focus {
        border: none;
        outline: none;
        padding: 0 15px;
      }
    }

    &__button {
      @include flex-shrink(0);
      background: none;
      color: #131313;
      opacity: 0.2;
      border: none;
      outline: none;
      padding: 0;

      & i {
        font-size: 20px;
      }

      &:hover,
      &:focus {
        opacity: 0.8;
        @include transition(all 0.3s ease-in-out);
      }
    }
  }

  &__no-results {
    margin: 0 0 0 10px;
    @include lato-bold-italic;
    opacity: 0.5;
    font-size: 18px;
  }
}

.borderless {
  border: #000 0 solid;
}

.basket-header {
  font-family: 'Reem Kufi', sans-serif;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 1.4em;
  color: #808080;
}

.store-nav {
  padding: 20px 30px;
  position: absolute;
  z-index: 10;
  background: #f0f0eb;

  &.landing-nav {
    padding: 40px 30px 10px;
    min-width: 350px;
  }

  &--category.landing-nav {
    padding: 0 15px;
    float: none;
    border-bottom: 3px solid darken($main-accent-color, 10%);
    border-top: 1px solid darken($main-accent-color, 3%);
  }

  &__search-divider {
    margin: 25px 0 5px;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }

  &__title {
    padding: 15px 30px;
    background-color: $main-accent-color;
    color: #fff;
    display: block;
    @include lato-black;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: -32px;
    white-space: nowrap;

    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }

    &.expand-toggle {

      & i {
        @include transition(all 0.3s ease-in-out);
        margin-left: 15px;
      }

      &.active {

        & i {
          @include transform(rotate(180deg));
        }
      }
    }

    &--category {
      position: relative;
      top: 0;
      display: block;
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px 20px;
    }
  }

  &__categories {
    padding: 0;
    width: 100%;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &__category {
    padding: 15px 0;
    @include text4;
    list-style-type: none;
    font-size: 18px;
    border-bottom: 2px solid $white-transparent;

    @media (max-width: $screen-xs-max) {
      font-size: 16px;
      padding: 10px 0;
    }

    & a {
      color: rgba(#131313, 0.5);
      @include transition(all 0.3s ease-in-out);

      &:hover {
        color: #131313;
      }
    }

    & ul {
      padding-left: 20px;
      margin-top: 10px;

      &.artist-category {
        text-align: left;
        padding: 10px 0;
        margin-top: 0;
        border-bottom: 2px solid var(--color-white);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background-color: #fdfdfd;
        }
      }

      & li {
        font-size: 16px;

        @media (max-width: $screen-xs-max) {
          font-size: 14px;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.hidden-featured {
  @media (max-width: 1210px) {
    display: none !important;
  }
}

.artist-list {
  overflow-y: auto;
  height: 40vw;
}

.artist-image {
  width: 60px;
  height: 60px;
}

.artist-featured {
  margin-top: 10px;
  text-align: center;
  font-family: 'Reem Kufi', sans-serif;
  font-weight: 400;
  letter-spacing: 0.04em;
  list-style-type: none;
  font-size: 18px;
}

.artist-red-arrow {
  width: 10px;
  float: right;
  margin-right: 10px;
}

.artist-name {
  display: inline-block;
  text-align: left;
  padding-left: 10px;
  margin: 0;
}

.store-listing {
  padding: 0;
  overflow: hidden;

  &__content {

    .white-line-bottom {

      .title1 {
        font-size: 30px;

        &.text-left {
          padding-left: 3.5vw;
        }
      }
    }

    .slide-btn.prev,
    .slide-btn.next {
      border-radius: 0;
    }

    @media (max-width: 767px) {
      margin-left: 0;
    }

    .section-title {
      @media (max-width: 767px) {
        background-size: 50vw 5px;
      }
    }
  }

  &__big-item {
    padding-bottom: 20px;
    margin-bottom: 20px;

    &::before,
    &::after {
      display: none;
    }

    &__image {
      padding: 0 20px;

      & img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
        border: 4px solid $border-color;
      }
    }

    &__name {
      color: $black;
      margin: 0 0 15px;
      position: relative;

      & a {
        @include title3;
        color: $black;
      }

      @media (max-width: $screen-md-min) {
        padding: 0;
        background: none;
        color: $main-accent-color;
        width: 100%;
        left: 0;
        margin-top: 20px;

        & a {
          color: $main-accent-color;

          &:hover,
          &:focus {
            color: rgba($main-accent-color, 0.7);
          }
        }
      }

      @media (max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
    }

    &__description {
      @include text4;
      font-size: 14px;
      margin-bottom: 10px;
      padding-right: 20px;

      & p {
        margin: 0 0 5px;
      }
    }

    &__details {
      @include lato;
      font-size: 12px;
      margin-bottom: 10px;
      padding-right: 20px;

      & p {
        margin: 0 0 5px;
      }
    }

    &__price {
      display: block;
      @include title1;
      margin: 10px 0;
      font-size: 28px;
      color: $black;

      @media (max-width: $screen-xs-max) {
        font-size: 18px;
      }
    }
  }

  &__products-container {
    @include display(flex);
    @include flex-wrap(wrap);

    &::before,
    &::after {
      display: none;
    }
  }
}

.store-list-item {
  margin: 20px;

  .store-list-item-tile {
    max-width: 200px;
  }

  .store-list-item-image {

    img {
      max-width: 260px;
      min-width: 260px;
      min-height: 260px;
      position: relative;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../image/image_not_found.jpg');
        background-size: contain;
      }
    }
  }
}

.store-subtle-heading {
  @include lato-bold;
  font-size: 16px;
  width: 100%;
  color: rgba(#131313, 0.5);
  padding: 0 0 15px;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.store-add-large {
  @include display(flex);
  @include flex-wrap(nowrap);
  padding-right: 15px;
  flex-direction: column;

  .white-border-button {
    margin-top: 15px;
    margin-right: 15px;
    width: 100%;
  }

  .white-border-select {

    .select-selected,
    .select-items div {
      padding-top: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .select-selected {
      text-align: center;
      border: 3px solid rgba(255, 255, 255, 0.9);
      margin-right: 15px;
    }

    .select-items {
      text-align: center;
      top: 95%;
      margin-right: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__options {
    padding: 0 5px;
    float: left;
    @include flex-shrink(1);
    width: 100%;
    border: 1px solid #888;

    &:hover,
    &:focus {
      border: 1px solid $main-accent-color;
      outline: none;
    }
  }

  &__button {
    @include black-button;
    font-size: 16px;
    padding: 5px 8px;
    @include transition(all 0.3s ease-in-out);
    @include flex-shrink(0);
  }

  & .arrow {
    border-width: 15px;
    @include flex-shrink(0);
  }
}

.store-add-small .select-selected {
  width: 200px;
}

.store-add-small {
  @include display(flex);
  @include navbar-vertical-align(center);
  @include flex-wrap(nowrap);
  padding-right: 15px;

  &__options {
    padding: 3px 3px 4px;
    float: left;
    @include flex-shrink(1);
    width: 100%;
    border: 1px solid #888;

    &:hover,
    &:focus {
      border: 1px solid $main-accent-color;
      outline: none;
    }
  }

  &__button {
    @include red-button;
    padding: 4px 2px 4px 6px;
    margin-left: 2px;
    @include transition(all 0.3s ease-in-out);
    @include flex-shrink(0);
  }

  & .arrow {
    @include flex-shrink(0);
  }
}

.store-featured-music {
  padding: 0;
  display: flex;
  flex-direction: column;

  & .add-to-basket {

    & input {
      color: $base-text-color;
      @include transition(all 0.3s ease-in-out);

      &:hover,
      &:focus {
        color: rgba($base-text-color, 0.7);
      }
    }
  }

  &__title {
    padding: 15px 30px;
    background-color: var(--color-black);
    display: block;
    @include lato-black;
    font-size: 24px;
    position: absolute;
    left: -30px;
    top: -32px;
    z-index: 11;
    white-space: nowrap;

    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }

  &__header {
    position: relative;
    text-align: right;
    padding: 15px 0;
    border-bottom: 2px solid rgba(var(--color-white), 0.15);
    margin: 0 30px 30px;

    & a {
      opacity: 0.75;
      font-size: 16px;
      @include lato-black;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    @media (max-width: $screen-sm-max) {
      padding-top: 50px;
      text-align: left;
    }

    @media (max-width: $screen-xs-max) {
      padding-top: 30px;
    }
  }

  &__single {
    padding: 0 30px;

    &__cover {
      margin: 0 40px;
      @include text4;

      & img {
        width: 100%;
        height: auto;
        border-radius: 20px;
      }
    }

    &__details {
      margin-top: 20px;
    }

    & > .row {
      width: 100%;
    }

    &__price {
      @include title1;
      margin-bottom: 10px;
    }

    & .store-add-large.negative {

      & .store-add-large {

        &__button {
          border-right: none;
          position: relative;

          &::after,
          &::before {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &::after {
            border-color: rgba(136, 183, 213, 0);
            border-left-color: $main-accent-color;
            border-width: 15px;
            margin-top: -15px;
          }

          &::before {
            border-color: rgba(255, 255, 255, 0);
            border-left-color: var(--color-white);
            border-width: 16px;
            margin-top: -16px;
          }
        }
      }
    }

    &__title {
      @include title3;
      margin: 0 0 20px;

      & a {
        color: var(--color-black);
      }

      @media (max-width: $screen-xs-max) {
        margin: 0 0 10px;
      }
    }

    &__tracks-table {
      padding: 0 10px;

      .table-responsive {

        .white-border-button {
          padding: 0 18px;
        }

        @media (max-width: 425px) {
          border: none;

          tr,
          td,
          th {

            .table-button {
              padding: 0 0 5px;
            }
            border: none;
          }
        }
      }

      @media (max-width: 425px) {

        .store-td-title {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 150px;
          max-width: 290px;
          width: 46vw;

          &:first-of-type {
            border-top: none;
          }
        }
      }

      table {

        thead {
          @include text2;
          color: $text-grey;
        }

        tbody {
          @include text4;
        }
      }

      &__title {
        @include title2;
        font-size: 18px;
        margin: 0 0 20px -10px;

        @media (max-width: $screen-sm-max) {
          margin-left: 0;
        }
      }

      &__header,
      &__row {
        padding: 10px 0;
        border-bottom: 1px solid rgba(#000, 0.1);
        @include display(flex);
        @include align-items(center);
        @include flex-wrap(wrap);

        &::before,
        &::after {
          display: none;
        }
      }

      &__column {

        &.preview {
          text-align: center;
        }

        &.title,
        &.buy-track {
          text-align: left;
        }

        &.buy-track {
          padding-right: 20px;
          @include display(flex);
          @include align-items(center);
          @include justify-content(flex-end);
        }

        &.duration,
        &.price {
          text-align: right;
        }
      }

      & a {

        .fa-play {
          color: var(--color-black);
          transition: color 0.5s;
        }

        .fa-pause {
          color: $accent-color;
          transition: color 0.5s;
        }

        &:hover,
        &:focus {
          color: $accent-color;
        }
      }

      & .tracks-table__button {
        border-right: none;
        position: relative;
        float: right;

        & i {
          margin-left: 5px;
        }
      }

      & .arrow {
        float: right;
      }

      @media (max-width: $screen-sm-max) {
        margin-top: 30px;
      }
    }
  }

  &__carousel {

    &.slick-initialized.slick-slider .slick-list {
      overflow-y: visible;
    }

    &__controls {
      @include display(flex);
      @include align-items(center);
      @include justify-content(center);
      margin: 30px;
    }

    &__indicators {
      display: inline-block;
      padding: 0 15px;
      margin: 0;
    }

    &__indicator {
      margin: 0 5px;
      list-style-type: none;
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      background-color: $black;
      opacity: 0.5;
      @include transition(all 0.3s ease-in-out);
      cursor: pointer;

      &.active,
      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &__control {
      font-size: 20px;
      color: var(--color-black);
      cursor: pointer;
      opacity: 0.5;
      margin: 0 50px;
      @include transition(all 0.3s ease-in-out);

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
}

.store-featured-merch {
  @include lato;
}

.store-single {

  &__content {
    padding: 40px 30px;

    @media (max-width: $screen-sm-max) {
      padding: 40px 0;
    }
  }

  &__item {

    &::before,
    &::after {
      display: none;
    }

    &__images {
      padding: 0 20px;

      img {

        &.defineImageRatio {
          width: 100%;
          border-radius: 0;
          margin: 0;
        }
      }

      &__big-image {
        @include display(flex);
        @include align-items(center);

        & a {
          width: 100%;
          height: 100%;
          display: block;
        }

        & img {
          height: auto;

          &.tall {
            height: 100%;
            width: auto;
            margin: 0 auto;
          }
        }
      }

      &__helper {
        align-items: center;
        display: flex;
        font-size: 12px;
        @include lato;
        margin: 15px 0;
      }

      &__thumbnails {
        align-items: center;
        display: flex;
        padding: 15px 0;
        border-top: 1px solid #ddd;
      }

      &__thumbnail {
        list-style-type: none;
        margin-right: 3.3%;
        margin-bottom: 3.3%;
        padding: 5px;
        border: 1px solid #ddd;
        @include display(flex);
        @include align-items(center);
        float: left;
        cursor: pointer;
        @include transition(all 0.3s ease-in-out);

        &:hover,
        &:focus,
        &.active {
          opacity: 0.7;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }

        & img {
          width: 100%;
          height: auto;

          &.tall {
            height: 100%;
            width: auto;
            margin: 0 auto;
          }
        }
      }
    }

    &__name {
      @include title3;

      & a {
        color: $black;
      }
    }

    &__subtitle {
      @include text4;
      font-size: 22px;
      color: rgba($base-text-color, 0.6);
    }

    &__event-link {
      width: 100%;
      float: left;
      text-align: left;
      margin-bottom: 20px;

      & a {
        @include black-button;
        font-size: 14px;
      }

      & .arrow {
        float: left;
      }
    }

    &__description {
      @include text4;
      font-size: 14px;
      margin: 20px 0;

      * {
        font-family: 'Reem Kufi', sans-serif !important;
        font-size: 1.3em;
        color: var(--color-black);
      }
    }

    &__details {
      @include text4;

      * {
        font-family: 'Reem Kufi', sans-serif !important;
      }

      &__title {
        @include text4;
        display: block;
        margin: 0 0 5px;

        * {
          font-family: 'Reem Kufi', sans-serif !important;
        }
      }

      & p {
        margin-bottom: 5px;
      }
    }

    &__price {
      display: block;
      @include title1;
      margin: 10px 0;
      font-size: 28px;
      color: $black;
    }
  }

  &__recording {

    &__artists {
      padding: 40px 0 0;
      border-top: 1px solid #ddd;
      margin-top: 30px;

      &__heading {
        @include lato-black;
        font-size: 18px;
        margin: 0 0 30px;
      }

      & .mini-artist:last-of-type {
        padding-bottom: 0;
      }
    }

    &__tracks {
      padding: 40px 0 0;
      border-top: 1px solid #ddd;
      margin-top: 40px;
    }

    &__tracks-table {
      padding: 0 10px;

      &__header {
        padding: 10px 0;
        border-top: 1px solid rgba(#000, 0.1);
        border-bottom: 1px solid rgba(#000, 0.1);
        @include display(flex);
        @include align-items(center);
        @include flex-wrap(wrap);

        &::before,
        &::after {
          display: none;
        }
      }

      &__row {
        padding: 7px 0;
        border-bottom: 1px solid rgba(#000, 0.1);
        @include display(flex);
        @include align-items(center);
        @include flex-wrap(wrap);

        &::before,
        &::after {
          display: none;
        }

        &:last-of-type {
          border-bottom: none;
        }

        & .title {
          @include lato-bold;
        }
      }

      &__column {
      
        &.preview {
          text-align: center;
        }

        &.title,
        &.buy-track {
          text-align: left;
        }

        &.buy-track {
          padding-right: 20px;
          @include display(flex);
          @include align-items(center);
          @include justify-content(flex-end);
        }

        &.duration,
        &.price {
          text-align: right;
        }
      }

      & a {
        color: #131313;

        &:hover,
        &:focus {
          color: rgba(#131313, 0.7);
        }
      }

      & .tracks-table__button {
        border: 1px solid #fff;
        border-right: none;
        position: relative;
        float: right;
        color: #fff;

        &:hover,
        &:focus {
          color: rgba(#fff, 0.7);
        }

        &::after,
        &::before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &::after {
          border-color: rgba(136, 183, 213, 0);
          border-left-color: $main-accent-color;
          border-width: 14px;
          margin-top: -14px;
        }

        &::before {
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 15px;
          margin-top: -15px;
        }

        & a {
          color: #fff;

          &:hover,
          &:focus {
            color: rgba(#fff, 0.7);
          }

          & i {
            margin-left: 5px;
          }
        }
      }

      & .arrow {
        float: right;
      }

      @media (max-width: $screen-sm-max) {
        margin-top: 30px;
      }
    }

    &__photos {
      padding: 40px 0 0;
      border-top: 1px solid #ddd;
      margin-top: 40px;

      &__heading {
        @include lato-black;
        font-size: 18px;
        margin: 0 0 25px;
      }

      &__container {
        @include display(flex);
        @include flex-wrap(wrap);

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &__photo {
      width: 25%;
      padding: 5px;
      float: left;

      @media (max-width: $screen-sm-max) {
        width: 33.33%;
      }

      @media (max-width: $screen-xs-max) {
        width: 50%;
      }

      & a {
        display: block;
        border: 1px solid #ddd;
        padding: 5px;
      }

      & img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

.store-cart {

  &__empty {
    text-align: center;
    padding: 60px 30px;

    & h2 {
      @include lato-italic;
      opacity: 0.6;
      font-size: 18px;
    }
  }

  &__table {

    &__container {
      width: 70%;
      min-width: 395px;

      @media (max-width: 440px) {
        min-width: 280px;
        width: 100%;
      }
    }

    &__header {
      padding: 10px 20px;
      background-color: $black;
      color: #fff;

      &__title {
        padding-bottom: 10px;
        margin-bottom: 10px;

        & span {
          @include reen-kufi-normal;
          font-size: 16px;
          text-transform: uppercase;
          color: var(--color-black);
          float: left;
          display: block;
          margin-right: 30px;
        }

        & span.smaller {
          @include lato;
          font-size: 12px;
          opacity: 0.6;
          float: right;
          margin-top: 6px;
          display: block;
          margin-right: 0;

          & a {
            @include lato-bold;
            color: #fff;

            &:hover,
            &:focus {
              color: rgba(#fff, 0.8);
            }
          }

          @media (max-width: $screen-sm-max) {
            float: left;
          }
        }
      }
    }

    &__row {
      padding: 0;
      background-color: #f0f0eb;
      color: $base-text-color;
      @include display(flex);
      @include align-items(center);
      border-bottom: 1px solid #eee;
      width: 100%;
      @include transition(all 0.3s ease-in-out);
      position: relative;
      overflow: hidden;

      &__container {
        padding: 10px 20px;
        width: 100%;

        @media (max-width: 960px) {
          padding: 0;
        }

        @include display(flex);
        @include align-items(center);
        @include flex-shrink(1);
        @include transition(all 0.3s ease-in-out);
      }

      &__remove {
        width: 0;
        height: 100%;
        @include display(flex);
        @include align-items(center);
        @include transition(0.3s ease-in-out);
        @include flex-shrink(0);
        opacity: 0;
      }

      @media (min-width: $screen-sm-max) {

        .store-cart__table__row__remove {
          width: 20px;
          opacity: 1;
          margin-left: 15px;
          margin-right: -15px;
        }
      }
    }

    & .no-padding {
      padding: 0;
      @include display(flex);
      @include align-items(center);
      @include flex-wrap(wrap);
    }

    &__cell {

      &.ticket-info {

        @media (max-width: 440px) {
          float: none;
          width: 100%;
          text-align: center;
          padding: 0;
        }
      }

      &.ticket-pricing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;

        @media (max-width: 440px) {
          width: 100%;
          justify-content: center;
        }

        .quantity {
          margin-left: auto;

          @media (max-width: 440px) {
            margin: 0;
          }
        }

        .price {
          text-align: right;
          @media (max-width: 440px) {
            padding-right: 12px;
          }
        }
      }

      &.physical {
        padding-left: 32px;

        @media (max-width: 960px) {
          padding-left: 2px;
          margin-top: 10px;
          width: auto;
        }
      }

      &.image {
        @include transition(all 0.3s ease-in-out);
        @include flex-shrink(0);
        padding-left: 5.5%;

        & a {
          display: block;
          padding: 5px;
          border: 1px solid #ddd;
        }

        & img {
          width: 100%;
          height: auto;

          &.tall {
            height: 100%;
            width: auto;
            margin: 0 auto;
          }
        }
      }

      &.name {
        @include flex-shrink(1);

        @media (max-width: 640px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & a {
          @include lato-bold;
          color: $base-text-color;

          &:hover,
          &:focus {
            color: rgba($base-text-color, 0.8);
          }
        }
      }

      &.album {

        & a {
          @include lato;
          color: $base-text-color;

          &:hover,
          &:focus {
            color: rgba($base-text-color, 0.8);
          }
        }
      }

      &.remove {
        text-align: center;
      }

      &.option {
        @include flex-shrink(1);
      }

      &.price,
      &.total {
        text-align: right;

        @media (max-width: $screen-sm-max) {
          text-align: left;
        }
      }

      &__sub-total{
        padding-top: 1%;

        .title{
          margin-left: auto;
          padding-right: 1%;

          @media (max-width: 440px) {
            margin: 0;
          }
        }

        @media (max-width: 440px) {
          width: 100%;
          justify-content: center;
          margin-top: 20px;
        }
      }

      &.price {
        @include flex-shrink(0);

        @media (max-width: $screen-sm-max) {
          margin-left: 20px;

          &::before {
            content: 'Price:';
            margin-right: 5px;
          }
        }
      }

      &.total {
        @include flex-shrink(0);

        @media (max-width: $screen-sm-max) {
          margin-left: 20px;

          &::before {
            content: 'Total:';
            margin-right: 5px;
          }
        }
      }

      &.quantity {
        padding-right: 5%;
        text-align: center;
        width: 100%;
        @include display(flex);
        @include justify-content(center);
        @include flex-shrink(0);

        @media (max-width: $screen-sm-max) {
          @include justify-content(flex-start);
          padding-left: 5px;
        }
      }

      @media (max-width: $screen-sm-max) {
        padding-top: 1px;
        padding-bottom: 1px;
      }
    }

    &__remove-button {
      background: none;
      color: #d6d6d6;
      @include transition(all 0.3s ease-in-out);
      font-size: 18px;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      z-index: 2;

      &:hover,
      &:focus {
        color: $main-accent-color;
      }
    }

    &__footer {
      background-color: #f0f0eb;
      border-top: 1px solid #eee;
      padding: 15px 30px;
      @include lato-bold;

      &__cell {
        padding-right: 0;

        &.value {
          color: $main-accent-color;
          text-align: right;
        }
      }
    }
  }

  &__quantity-control {
    margin-left: auto;
    @include display(flex);
    @include align-items(center);

    &.physical {
      margin-left: 22px;

      @media (max-width: 960px) {
        width: 23%;
      }
    }

    &__label {
      width: 30px;
      display: block;
      text-align: center;
      margin: 0;
      margin: 0 4px 2px;
    }

    &__button {
      display: block;
      background: none;
      color: $main-accent-color;
      @include transition(all 0.3s ease-in-out);
      outline: none;

      &:hover,
      &:focus {
        color: $main-accent-color-darker;
        outline: none;
      }

      &.disabled {
        cursor: default;
        color: #ddd;

        &:hover,
        &:focus {
          color: #ddd;
        }
      }
    }
  }

  &__mobile-quantity {
    float: left;
    padding: 0 10px;

    @media (max-width: 960px) {

      &.digital {
        padding-left: 0;
      }
    }

    @include flex-shrink(0);

    @media (max-width: $screen-sm-max) {
      @include display(flex);
      @include flex-wrap(nowrap);
      @include align-items(center);
      margin-top: 10px;
    }

    &__half {
      float: left;
      width: 50%;
    }
  }

  &__footer {
    @include lato;
    background-color: #f0f0eb;
    padding: 20px;
    @include display(flex);
    @include flex-wrap(wrap);
    @include align-items(center);
    border-top: 2px solid #eee;

    &::before,
    &::after {
      display: none;
    }

    &__left {
      text-align: left;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 30px;
      }

      &__text {
        font-size: 12px;
        color: #4a4a4a;
        margin-bottom: 5px;
        display: block;
      }
    }

    &__right {
      text-align: right;
    }

    &__card-icons {
      display: block;
      float: left;

      & img {
        max-height: 28px;
        width: auto;
        margin-right: 3px;
        border: 1px solid #ddd;
      }
    }

    &__price-container {
      width: 100%;
      @include lato-bold;

      &__label,
      &__value {
        float: right;
        display: block;
        margin-bottom: 4px;
      }

      &__label {
        margin-right: 10px;
        font-size: 14px;
        margin-top: 2px;
      }

      &__value {
        clear: right;
        font-size: 16px;
        color: $main-accent-color;
      }

      &__total {
        float: right;
        clear: both;
        padding-top: 4px;
        border-top: 1px solid #ddd;
        padding-left: 30px;
      }
    }

    &__button-next,
    &__button-back {
      display: block;
      text-decoration: none;
      @include transition(all 0.3s ease-in-out);
      margin-top: 20px;
      font-size: 16px;
      text-align: center;

      @media (max-width: $screen-xs-max) {
        width: 100%;
      }

      & i {
        @include transition(all 0.3s ease-in-out);
      }

      &:hover,
      &:focus {
        outline: none;
        text-decoration: none;

        & i {
          margin-right: 5px;
        }
      }
    }

    &__button-next {
      padding: 10px 30px;
      float: right;
      background-color: $main-accent-color;
      color: #fff;

      &:hover,
      &:focus {
        background-color: darken($main-accent-color, 7%);
        color: #fff;
      }
    }

    &__button-back {
      padding: 10px 0;
      @include lato-bold;
      float: left;
      color: lighten($base-text-color, 60%);
      background: none;

      &:hover,
      &:focus {
        color: lighten($base-text-color, 50%);
        background: none;
      }
    }
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.justify-content-center {
  justify-content: center;
}

.store-cart__footer__buttons {
  align-items: center;
  display: flex;
  justify-content: center;

  .white-border-button {
    display: flex;
    margin: 0 5px;
  }
}

.checkout-white {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  width: 120px;

  @media (min-width: $screen-xs) {
    width: 200px;
  }
}

.center {
  text-align: center;
}

.back-white {
  position: relative;
  text-align: center;
  display: flex;
  width: 200px;

  @media (max-width: 420px) {
    margin-bottom: 10px;
    width: 30%;
  }
}

.store-shipping,
.store-billing {
  padding: 30px;
  background-color: #f0f0eb;
  @include lato;
  color: $base-text-color;

  &__heading {
    font-size: 18px;
    @include lato-black;
    margin: 20px 0 15px;
  }

  &__text {
    margin: 0 0 15px;
    @include lato;
    font-size: 14px;

    & a {
      @include lato-bold;
      color: $base-text-color;
    }
  }
}

.store__form {

  &__heading {
    font-size: 18px;
    @include lato-black;
    margin: 20px 0 15px;
  }

  &__label {
    font-size: 12px;
    @include lato;
    display: block;
    min-height: 17px;
  }

  &__group {
    padding: 0;
    border-top: 1px solid #eee;
    float: left;
    width: 100%;
  }

  &__input,
  &__select {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ddd;
    outline: none;
    width: 100%;
    margin-bottom: 10px;
    @include transition(all 0.3s ease-in-out);

    &:hover,
    &:focus {
      outline: none;
      border: 1px solid $main-accent-color;
    }
  }

  &__select {
    height: 44px;
  }

  &__filler-separator {
    height: 1px;
    width: 100%;
    background-color: #ddd;
    margin: 45px 0 32px;
  }

  &__selection {

    &__container {
      margin-bottom: 20px;
    }

    &__option {
      margin-top: 5px;

      & input[type='radio'] {
        display: none;
      }

      & label {
        cursor: pointer;
        padding-left: 26px;
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          background-color: #fff;
          border-radius: 100%;
          border: 1px solid #a4a4a4;
        }
      }

      & input[type='radio']:checked + label::before {
        content: '\2022';
        color: #666;
        font-size: 23px;
        text-align: center;
        line-height: 13px;
        padding-left: 1px;
      }

      & span {
        @include lato-bold;
        font-size: 14px;
        margin-left: 28px;

        &.price {
          @include lato;
          color: $main-accent-color;
          margin-left: 5px;
        }
      }
    }
  }

  &__field-error {
    display: block;
    @include lato;
    font-size: 12px;
    color: #d21333;
    margin: -5px 0 10px;
  }
}

.store-overview {
  padding: 30px 20px;
  background-color: #f0f0eb;
  @include lato;
  color: $base-text-color;
  @include display(flex);
  @include flex-wrap(wrap);

  &::before,
  &::after {
    display: none;
  }

  @media(max-width: $screen-md){
    padding: 0 10px;
  }

  &__left-column {

    .white-line-bottom {
      margin: 5px 0 20px;
    }

    @media (min-width: $screen-sm-min) {
      padding-right: 30px;
      border-right: 1px solid #ddd;
    }
  }

  &__right-column {
    @media (min-width: $screen-sm-min) {
      padding-left: 30px;
    }
  }

  &__group {
    margin-bottom: 30px;

    &__heading {
      @include reen-kufi-normal;
      font-size: 22px;
      margin: 0 0 20px;
    }

    &__textblock {
      margin-bottom: 15px;

      & p {
        margin: 0;

        &.small-heading {
          @include lato-bold;
          margin-bottom: 5px;
        }
      }
    }

    &__table {

      &__heading {
        @include reen-kufi-normal;
        font-size: 16px;
        margin: 0 0 15px;
      }

      & .total {
        text-align: right;
      }

      &__header {
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #eee;
      }

      &__row {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;

        &:last-of-type {
          border-bottom: none;
        }

        & .row {
          @include display(flex);
          @include align-items(center);
        }

        & .image a {
          display: block;
          width: 100%;
        }

        .details {
          font-family: 'Reem Kufi', sans-serif;
          font-weight: 400;
          letter-spacing: 0.02em;
          font-size: 1em;
          text-transform: none;
        }
      }

      &__product-name {
        margin: 4px 0;
        @include lato-bold;
      }

      &__product-details {
        margin: 2px 0;
        @include lato;
      }
    }

    &__total {
      font-family: 'Reem Kufi', sans-serif;
      font-weight: 400;
      letter-spacing: 0.02em;
      font-size: 1.5em;
      text-transform: none;
      text-align: right;

      &__value {
        color: $main-accent-color;
      }
    }
  }

  &__return-button {
    display: inline-block;
    text-decoration: none;
    @include transition(all 0.3s ease-in-out);
    font-size: 14px;
    color: lighten($base-text-color, 60%);
    @include lato-bold;

    &__container .white-border-button {
      @media (max-width: 960px) {
        padding: 0 18px;
      }
    }

    & i {
      @include transition(all 0.3s ease-in-out);
    }

    &:hover,
    &:focus {
      color: lighten($base-text-color, 50%);
      background: none;

      & i {
        margin-right: 5px;
      }
    }

    &__container {
      margin: 10px 0 30px;
    }
  }
}

img.defineImageRatio {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  margin: 15px 0;

  &.wide {
    width: auto;
    height: auto;
  }

  &.tall {
    height: auto;
    width: auto;
    margin: 0 auto;
  }
}

.arrow-button-container {
  @include display(flex);
  @include align-items(center);
}

.buy-button-container {
  position: relative;

  .dropdown-menu {
    min-width: 78px;
    background: $background-color;
    border: 3px solid rgba(255, 255, 255, 0.9);

    li {
      padding: 5px 19px;
    }

    input {
      padding: 0;
      font-size: 10px;
      font-family: 'Reem Kufi', sans-serif;
      text-transform: uppercase;
    }
  }

  & ul li {
    padding: 5px;

    &:hover,
    &:focus {
      color: $main-accent-color;
    }

    & input {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}

.store-done {

  @media (max-width: $screen-xs-max) {
    //padding: 30px;
  }

  & > .row {
    @include display(flex);
    @include align-items(center);
  }

  &__graphic {

    & img {
      width: 60%;
      margin: 0 20%;
    }
  }

  &__content {

    &.disclaimer{
      margin-top: 20px;
      padding: 0 15px;
    }
  }

  &__heading {
    @include lato-black;
    font-size: 24px;
    margin: 0 0 20px;
  }

  &__info-list {
    padding-left: 15px;
    margin: 0;
    list-style: none;


    & li {
      margin: 5px 0;
    }

    &__title{
      text-transform: uppercase;
    }
  }
}

.store-results-grid {
  @include display(flex);
  @include flex-wrap(wrap);
}

.store-pagination {
  margin-top: 30px;
}

.all-recordings-container,
.all-artist-tracks {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    flex: 1 0 -1%;
  }

  .store-list-item {
    margin: 0 4px;

    @media (max-width: 430px) {
      width: 45.5%;
    }

    .store-list-item-image img {
      border-radius: 0;

      @media (min-width: 0) {
        max-width: 46.1vw;
        min-width: 46.1vw;
        min-height: 46.1vw;
      }

      @media (min-width: $tablet-break) {
        max-width: 31.1vw;
        min-width: 31.1vw;
        min-height: 31.1vw;
      }

      @media (min-width: $screen-md) {
        max-width: 22.85vw;
        min-width: 22.85vw;
        min-height: 22.85vw;
      }

      @media (min-width: 1366px) {
        max-width: 15.1vw;
        min-width: 15.1vw;
        min-height: 15.1vw;
      }
    }

    .store-list-item-tile {
      @media (min-width: 0) {
        max-width: 46.1vw;
        min-width: 46.1vw;
        font-size: 13px;
      }

      @media (min-width: $tablet-break) {
        max-width: 31.1vw;
        min-width: 31.1vw;
      }

      @media (min-width: $screen-md) {
        max-width: 22.85vw;
        min-width: 22.85vw;
      }

      @media (min-width: 1366px) {
        max-width: 15.1vw;
        min-width: 15.1vw;
      }
    }
  }
}

.all-recordings-container {
  justify-content: start;
  margin-bottom: 40px;
  padding-left: 3.2vw;

  @media (max-width: 960px) {
    column-gap: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 15px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 430px) {
    column-gap: 10px;
    display: flex;
    padding: 0 15px 0 10px;
  }
}

.load-more-btn {
  width: 200px;
  margin: 30px auto;
}

.newest-recordings-container {
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
  width: auto;

  .event-display {
    cursor: pointer;

    .event-info {
      margin-bottom: 0;
      min-height: 0;
    }

    .event-info-title {

      @media (min-width: 0) {
        max-width: 46.1vw;
        min-width: 46.1vw;
        font-size: 13px;
        line-height: 13px;
      }

      @media (min-width: $tablet-break) {
        max-width: 31.1vw;
        min-width: 31.1vw;
      }

      @media (min-width: $screen-md) {
        max-width: 22.85vw;
        min-width: 22.85vw;
      }

      @media (min-width: 1366px) {
        max-width: 15.1vw;
        min-width: 15.1vw;
      }
    }
  }

  &.slide-btn.prev,
  .slide-btn.next {

    @media (min-width: 0) {
      height: 0;
    }

    @media (min-width: $screen-md-min) {
      height: 22.85vw;
    }

    @media (min-width: 1366px) {
      height: 15.1vw;
    }

    @media (max-width: 960px) {
      display: none;
    }
  }

  &.slide-btn.next,
  .slide-btn.prev {

    @media (min-width: 0) {
      height: 0;
    }

    @media (min-width: $screen-md) {
      height: 22.85vw;
    }

    @media (min-width: 1366px) {
      height: 15.1vw;
    }
  }

  .event-card {

    &:hover {

      .event-picture {
        filter: grayscale(100%);
      }
    }
  }

  @media (max-width: 960px) {
    overflow-x: auto;
  }

  .event-card .event-picture-container .event-picture,
  .event-card .event-picture-container .event-picture img {
    @media (min-width: 0) {
      height: 46.1vw;
      width: 46.1vw;
    }

    @media (min-width: $tablet-break) {
      height: 31.1vw;
      min-height: 31.1vw;
      width: 31.1vw;
    }

    @media (min-width: $screen-md) {
      height: 22.85vw;
      min-height: 22.85vw;
      width: 22.85vw;
    }

    @media (min-width: 1366px) {
      width: 15.1vw;
      height: 15.1vw;
      min-height: 15.1vw;
    }
  }

  .event-card .event-picture-container {
    border-radius: 0;
  }
}

.store-error {
  background-color: #eee;
  border-bottom: 1px solid #ddd;
  padding: 0 20px;

  &__message {
    display: inline-block;
    padding: 8px 15px;
    font-size: 14px;
    color: #fff;
    background-color: $main-accent-color;
    @include lato-bold;
    margin: 20px 10px;

    a {
      @include lato-black;
      color: #fff;
      text-decoration: underline;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }
}

.artist-bar-search {
  display: none;
  overflow-y: auto;
  max-height: 100px;
  text-align: center;
  position: absolute;
  background-color: #f0f0eb;
  border-color: var(--color-white);
  border-style: solid;
  border-radius: 8px;
  border-width: 2px;
  width: 350px;
  z-index: 9999;
  top: 40px;
  left: 0;
  padding: 10px;
}

.reset-search {
  position: absolute;
  top: 8px;
  left: 75%;
  margin-left: 20px;

  &::before,
  &::after {
    height: 24px;
  }
}

.catalog-title {
  text-align: center;
}

.store-single__item {
  justify-content: center;

  @media (max-width: $screen-md) {
    flex-direction: column;
    align-items: center;
  }
}

.store-single__item__images {
  width: auto;
  height: auto;
  max-width: 400px;

  @media (max-width: $screen-md) {
    width: auto;
  }
}

.slick-track a {
  width: auto;
}

form .back-to-payment-btn {
  text-align: center;
  line-break: 33px;
  display: flex;
  padding: 10px;

  @media (max-width: 400px) {
    margin-bottom: 10px;
    width: 100%;
  }
}

.catalogue-container {

  .events-gif {
    margin: 50px auto;
    width: 50px;
  }
}

.store-cart__footer.container {

  @media (max-width: 400px) {

    .back-to-payment-btn {
      width: 45%;
    }
  }
}

@media (min-width: 1024px) {

  .store-cart__table__header__title span.smaller.digital-info {
    float: unset;
  }
}

@media (max-width: 480px) {

  .album.physical,
  div.store-cart__table__cell {
    padding-right: 0;
  }

  .store-cart__mobile-quantity.physical {
    padding: 0;
  }
}

.store-home {

  .white-line-bottom {
    margin-top: 0;
  }

  .description-container {
    margin-bottom: 20px;
  }
}

#downloadConfirmationDialog {

  .table-container {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  tr {
    vertical-align: middle;
    background-color: rgba(240, 240, 235, 0.9);
    border-bottom: 1px solid #dddddd;
  }

  td,
  th {
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }

  span {

    &.separator {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .link {
    font-family: 'Reem Kufi', sans-serif;
    font-weight: 400;
    font-size: 2em;
    color: var(--color-black);
    letter-spacing: 0;
  }
}

@media (max-width: 767px) {

  .store-overview {

    .title1 {
      font-size: 22px !important;
    }
  }
}
