.archive-new {

  &__header-search {
    background-image: url('../image/archive-new-head-bg.jpg');
    background-size: cover;
    background-position: center center;
    padding: 50px 30px;
    text-align: center;
    color: #fff;

    h1 {
      font-size: 42px;
      @include lato-black;
      margin: 0 0 20px;
    }

    p {
      display: block;
      margin: 0 auto 10px;
    }

    &__search-container {
      margin: 30px auto;
      max-width: 560px;
      @include display(flex);
      @include align-items(center);
      background-color: #fff;
      padding: 6px 15px;
      border-radius: 5px;

      .search--control {
        background: none;
        padding: 4px;

        &:hover, &:focus {

          i {
            color: $base-text-color;
          }
        }
      }

      .form-control.search-input {
        width: 100%;
        @include flex-shrink(1);
        padding: 10px 5px;
        border-radius: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background: none;
        color: $base-text-color;
        font-size: 16px;

        &:hover, &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      i {
        @include flex-shrink(0);
        font-size: 20px;
        color: rgba($base-text-color, 0.4);
        height: auto;
        position: relative;
        @include transition(all 0.3s ease-in-out);
      }

      .legend-icon {
        margin-right: 5px;
      }
    }

    &__instructable {
      color: rgba(#fff, 0.6);
      @include lato-bold;
    }
  }

  &__header-cta {
    background-image: url("../image/home-membership-cta.jpg");
    background-size: cover;
    background-position: center center;
    padding: 50px 30px;
    text-align: center;
    color: #fff;
    line-height: 140%;

    h1 {
      font-size: 32px;
      @include lato-black;
      margin: 0 0 20px;
    }

    p {
      display: block;
      margin: 0 auto 10px;
      max-width: 800px;

      a {
        @include lato-bold;
        color: #fff;

        &:hover, &:focus {
          color: rgba(#fff, 0.8);
        }
      }
    }

    &__cta {
      display: inline-block;
      font-size: 18px;
      padding: 15px 25px;
      margin-top: 20px;
      background-color: rgba(#000, 0.4);
      border: 2px solid $main-accent-color;
      border-radius: 2px;
      @include lato-bold-italic;
      color: #fff;

      &:hover, &:focus {
        color: rgba(#fff, 0.8);
      }
    }
  }

  &__carousel {
    background-color: #f6f6f6;
    padding: 50px 15px;

    &__switches {
      padding: 0 15px;
      margin: 0;
    }

    &__switch {
      font-size: 20px;
      @include lato-black;
      margin-right: 20px;
      color: rgba($base-text-color, 0.5);
      @include transition(all 0.3s ease-in-out);
      padding-bottom: 10px;
      margin-bottom: 15px;
      list-style-type: none;
      display: inline-block;
      cursor: pointer;

      &:hover, &:focus {
        color: $base-text-color;
      }

      &.active {
        color: $base-text-color;
        border-bottom: 4px solid $main-accent-color;
      }

      @media (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }

    &__container, &__container .slick-track {
      @include display(flex);
      padding: 0;
      margin: 0 0 40px;

      .slick-dots {
        left: 0;
        bottom: -38px;
      }
    }

    &__item {
      width: 100%;
      padding: 15px;
      list-style-type: none;

      &__container {
        width: 100%;

        &:hover, &:focus {
          opacity: 0.5;
        }
      }

      .event-image {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
      }

      .event-date {
        font-size: 14px;
        @include lato-bold;
        color: #767676;
        margin: 0 0 5px;
      }

      .event-name {
        font-size: 16px;
        @include lato-bold;
        margin: 0;
        color: $base-text-color;
      }

      .event-venue {
        font-size: 14px;
        margin: 0;
        color: $main-accent-color;
      }
    }
  }

  &__date-browser {
    border-top: 4px solid $main-accent-color;
    padding: 40px 15px 10px;
    background: #fff;

    &__header {
      @include display(flex);
      @include align-items(center);
      @include justify-content(space-between);
      margin-bottom: 20px;

      h1 {
        margin: 15px;
        @include lato-black;
        font-size: 32px;
        color: $main-accent-color;
      }

      .icons-legend {
        margin: 10px 15px 0;
        font-size: 1.4rem;

        .helper {
          color: rgba($base-text-color, 0.75);
        }

        i {
          margin: 0 10px 0 15px;
          color: $main-accent-color;
        }

        .icon-meaning {
          color: $base-text-color;
          @include lato-bold;
        }
      }

      &__date-picker {
        margin: 15px;
      }
    }
  }

  &__bottom-promo {
    padding: 15px 15px;
    background-color: #1f1f1f;
    color: #fff;
    border-top: 4px solid $main-accent-color;
    @include display(flex);
    @include align-items(center);

    @media (max-width: $screen-xs-max) {
      @include flex-wrap(wrap);
    }

    p {
      width: 100%;
      @include flex-shrink(1);
      margin: 15px;
      @include lato-bold;
    }

    &__cta {
      @include flex-shrink(0);
      margin: 15px;
      padding: 10px 20px;
      color: #fff;
      background-color: $main-accent-color;
      border-radius: 5px;
      @include lato-bold;

      &:hover, &:focus {
        color: rgba(#fff, 0.8);
        background-color: darken($main-accent-color, 5%);
      }
    }
  }
}

.custom-date-picker {
  @include display(flex);
  @include align-items(center);
  width: 100%;

  input {
    @include white-border-button;
    @include place-holder-color($main-accent-color);
    cursor: pointer;
    width: 100%;
    z-index: 2;

    @media (max-width: $screen-sm-max) {

    }
  }

  i {
    font-size: 18px;
    position: relative;
    z-index: -1;
    right: 25px;
    pointer-events: inherit;
    cursor: pointer;

    &::before {
      pointer-events: inherit;
    }
  }

  .datepicker {
    @include button1;
    @include input-border;
    border-radius: 0;
    min-width: 300px;


    &:after, &:before {
      display: none;
    }

    padding: 25px;
    box-shadow: none;

    &.dropdown-menu {
      background-color: $background-color;
      width: 85%;
      .table-condensed > thead > tr > th {
        padding: 10px 0;

        &.datepicker-switch {
          position: relative;

          > div:first-child {
            @include title1;
            position: relative;

            &::after {
              content: '▼';
              font-size: .3em;
              width: 3px;
              height: 3px;
              display: inline-block;
              top: -6px;
              position: relative;
              left: 2px;
            }
          }
        }

        &.datepicker-switch > .years-dropdown {
          background-color: white;
          font-size: 1em;
          padding: 2px;
          position: absolute;
          right: 35px;
          top: 100%;

          .years-choices {
            max-height: 300px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 10px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background-color: rgba(0,0,0,.5);
              -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
            }
          }

          .years-choices > div {
            padding: 2px 20px;
            line-height: 2em;
            background: $background-color;

            &:hover{
              background-color: white;
            }
          }
        }
      }

      td {
        height: 30px;
        border-radius: 10px;
        margin: 20px;

        @media(min-width: 1366px) and (max-width: 1700px)  {
          height: 1.2vw;
        }

        @media(min-width: $screen-sm-max) and (max-width: 1270px)  {
          height: 19px;
        }

        &.day {
          background-color: $background-color;
          padding: 10px 2px 2px;

          @media(min-width: 1366px) and (max-width: 1700px)  {
            padding-top: 0;
            padding-bottom: 0;
          }

          @media(min-width: $screen-sm-max) and (max-width: 1270px)  {
            padding-top: 0;
            padding-bottom: 0;
          }

          &.disabled {
            color: #999999;
          }
        }

        &.day, .month, .year {

          &.active {
            background-color: $white-transparent !important;
            color: black;

            &:hover {
              background-color: $white-transparent !important;
              color: black;
            }
          }
        }
      }

      // .disabled {
      //   display: none;
      // }
    }
  }
}

.custom-date-picker-apply {
  display: none;
  padding-left: 5vw;
  padding-right: 5vw;

  @media (max-width: $screen-sm-max) {
    display: flex;
  }
}

.calendar-section {
  margin-bottom: 0;
}

.datepicker thead tr:first-child th {

  &:hover {
    background: $background-color;
  }
}

.datepicker thead tr:last-child th.dow {
    // Hackaround because elements with opacity < 1 overlap
    // absolute-positioned elements despite the z-index.
    color: rgb(71,71,59);
    opacity: 1;
}

.select-container {

  .custom-date-picker {
    margin-left: 40px;
  }
}
