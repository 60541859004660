.photo-gallery {
  margin-left: -11px;
  margin-right: -11px;

  &__image {
    margin-bottom: 20px;
    @include filter(grayscale(100%));

    &:hover {
      @include filter(none);
    }
  }

  & img {
    width: 100%;
  }
}

@media (max-width: $screen-sm-max) {
  .photo-gallery {
    margin-left: -10px;
    margin-right: -10px;
  }

  .fancybox-nav span {
    visibility: visible;
  }
}

