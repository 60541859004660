.ui-autocomplete {
  background-color: #fff;
  width: 100% !important;
  @include lato;
  padding: 10px 0;
  float: left;
  border: 1px solid #cccccc;
  z-index: 100001;
  font-size: 14px;

  & li {
    float: left;
    width: 100%;
  }

  & .ui-menu-item {
    @include list-unstyled;
    padding: 10px 20px !important;
    cursor: pointer;

    & a {
      display: block;
      width: 100%;
      text-decoration: none;
      float: left;
    }

    &:hover, &:focus {
      background-color: #f0f0f0;
    }

    & .name {
      @include lato-bold;
      color: #121212;
      display: block;
      float: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .sublabel {
      @include lato;
      color: #A1A0A0;
      display: block;
      float: right;
    }
  }

  &-category {
    @include list-unstyled;
    @include lato-bold;
    color: #A1A0A0;
    text-transform: uppercase;
    padding: 20px 20px 10px !important;
    border-bottom: 1px solid #f0f0f0;
    font-size: 12px;
    width: 100%;

    & .name {
      float: left;
      margin-right: 5px;
    }

    & .results-count {
      float: left;
    }
  }

  &-more-count a {
    @include lato-bold;
    color: #A1A0A0;
    font-size: 12px;
  }
}

.ui-helper-hidden-accessible {
  display: none;
}

.navbar-nav .search {
  float: none;
  width: 100%;

  & input {
    border-color: #fff;
    background-color: #fff;
    font-size: 16px;
    padding: 12px;
    padding-left: 30px;
    height: auto;
  }

  & i {
    color: rgba(0,0,0,0.35);
    margin-top: 4px;
  }
}

@media (min-width: $screen-md-min) {
  .ui-autocomplete {
    position: absolute !important;
    margin-top: 42px;
  }
}

@media (min-width: $screen-md-min) {
  .ui-autocomplete {
      //width: 220px !important;

      & .ui-menu-item .name {
        max-width: 60%;
      }
  }
}

@media (min-width: $screen-lg-min) {
  .ui-autocomplete {
    //width: 360px !important;

    & .ui-menu-item .name {
        max-width: 75%;
      }
  }
}