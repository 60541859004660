.search-store {
  padding-bottom: 19px;
  border-bottom: 1px #DDDDDD solid;
  padding-top: 20px;

  &:last-child {
    border-bottom: 0;
  }

  &__image {
    display: table-cell;

    img {
      border: 1px #ccc solid;
      width: 138px;
      height: 138px;
    }
  }
}

.search-store-info {
  display: table-cell;
  vertical-align: top;
  padding-left: 19px;

  &__title {
    @include lato-black;
    font-size: 22px;
    color: #131313;
    margin-top: 7px;
    margin-bottom: 2px;
  }

  &__type {
    @include lato-bold;
    color: #777777;
    font-size: 13px;
    margin-bottom: 5px;
  }

  &__price {
    @include lato-bold;
    color: $main-accent-color;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__buynow {
    @include lato-bold-italic;
    color: white;
    border: none;
    background-color: $main-accent-color;
    display: block;
    float: left;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 10px 4px 7px;
    margin-bottom: 20px;
  }
}

.search-pagination-info {
  @include lato-bold;
  font-size: 12px;
  color: #777777;
  text-align: center;
}

.search-pagination {
  text-align: center;
  margin-bottom: 30px;
}