.event-description {
  background-color: #F7F7F7;
  border-left: 1px #DDDDDD solid;

  &__text-block {
    padding: 30px 10px;
  }
  
  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
    margin-top: 10px;
  }

  p {
    @include lato;
    font-size: 14px;
    color: #111;
    text-align: justify;
  }

  & img {
    width: 100%;
    @include filter(grayscale(100%));

      &:hover {
        @include filter(none);
      }
  }

  &__admin-buttons {
    @include lato-bold;
    padding: 10px;
    float: left;
    width: 100%;
    margin: 30px 0;
    text-transform: capitalize;

    &__edit, &__clone {
      display: block;
      float: left;
      padding: 10px 20px;
      color: #fff;
      border-radius: 10px;

      &:hover {
        color: rgba(255,255,255,0.7);
        text-decoration: none;
      }
    }

    &__edit {
      background-color: $main-accent-color;
    }

    &__clone {
      background-color: $main-accent-color-darker;
      margin-left: 10px;
    }
  }
}