.main-header {
  padding: 20.5px $content-left-padding;

  .button-row {
    p {
      margin-bottom:  0;
    }
  }

  display: block;
  @media(max-width: $screen-sm-max) {
    display: none;
  }

  .section-title {
    margin-left: 10px;
    margin-bottom: 0;

    @media (max-width: $screen-md-min) {
      width: 300px;
    }
  }

  .logo-container {
    flex: 1;
    display: flex;

    .section-title {
      padding-left: 1.5vw;



    }


    .search-input {
      flex: 1;
      margin-left: 30px;
      margin-right: 30px;

      @media (max-width: 1200px) {
        margin-left: 15px;
        margin-right: 0;
      }

      input {
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .navigation-bars {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: #E0E0DC;
  }
}

.mobile-header {
  background-color: $background-grey;
  display: none;
  height: 65px;
  margin-top: 0;
  position: relative;
  top: 0;
  width: 100%;

  &.mobile-artist-header {
    position: absolute;
  }

  .mobile-user-option {
    z-index: 9999;
    display: none;
    position: absolute;
    right: 0;
    >* {
      text-align: center;
      width: 240px;
      padding: 1em;
      background-color: $background-grey;
    }

    a {
      color:black;
    }
  }

  .mobile-logo-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    max-height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    .new-header-img-wrapper {
        gap: 10px;
        max-width: calc(100% - 100px);
        width: 220px;
    }

    .smalls-logo {
      pointer-events: all;
      margin: 12px 0;

      img {
        width: 50px;
      }
    }
  }
  .mobile-menu-icons {
    height: 100%;
    padding: 0 20px;

    i {
      font-size: 20px;
    }
  }

  @media(max-width: $screen-sm-max) {
    display: block;
  }
}

.mobile-search-header {
  height: 0;
  overflow: hidden;
  position: relative;
  display: none;

  @include animated();

  &.mobile-search-header-shown {
    @include animated();
    overflow: visible;

    @media(max-width: $screen-md-max) {
      height: $mobile-bar-height;
    }

    @media(max-width: $screen-sm-max){
      display: block;
    }

    box-shadow: 0 9px 9px 0 rgba(145,140,145,1);

    &::after {
      $triangle-size: 17px;
      width: 0;
      height: 0;
      border-left: $triangle-size solid transparent;
      border-right: $triangle-size solid transparent;
      content: '';

      border-bottom: $triangle-size solid white;
      position: absolute;
      top: -$triangle-size + 5;
      right: 60px;
    }
  }

  .mobile-search-container {
    background-color: white;
    $input-padding: 10px;
    padding: $input-padding;
    flex-grow: 1;
    height: 100%;

    form {
      @media(max-width: $screen-md-max) {
        height: 100%;
      }
      flex: 1;

      input {
        font-size: 2em;
        height: 100%;
        width: 100%;
        border: 1px solid $background-grey;
        border-radius: 3px;
        outline: none;
        padding: 0 0 0 15px;
      }
    }

    i {
      margin: 0 20px;
      font-size: 30px;
    }
  }
}

.side-bar-container {
  z-index: 1001;
  width: 100%;
  position: fixed;
  height: calc(100vh);
  top: 0;

  .side-navigation-overlay {
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0.8;
    position: absolute;
    @include animated;

  }

  .side-bar {
    background-color: $background-grey;
    width: 70%;
    height: 100%;
    position: absolute;
    padding: 20px;
    margin-left: 0;
    overflow: scroll;

    @include animated;

    .side-bar-header {
      margin-bottom: 40px;

      .new-header-img-wrapper {
        gap: 10px;
      }
    }

    a {
      font-size: 1.7em;
      color: $black;
      margin: 15px 0;

      &.active {
        color: $accent-color;
      }

      @media(max-width: 620px) {
        font-size: 2.5rem;
      }
    }
  }

  &.side-bar-hidden {
    pointer-events: none;
    .side-bar {
      @include animated;
      margin-left: -70%;
    }

    .side-navigation-overlay {
      @include animated;
      opacity: 0;
    }
  }
}

// TODO Move to footer file (seems to have problems importing variables
.footer-mobile {
  .footer-links {
    a {
      padding: 20px;
      border-top: 2px solid $white-transparent;
      font-size: 1.5em;
    }
  }

  .footer-info {
    padding: 40px;
    border-top: 2px solid $white-transparent;

    a {
      color: $black;
    }
  }
}

.footer-info {
  @media(max-width: $screen-md-max) {
    display: none;
  }
}
