.generic-text-page {
  padding: 20px 20px 40px;
  margin-top: 8px solid $main-accent-color;
  @include lato;
  color: #131313;
  background-color: #fff;
  text-align: justify;
  line-height: 140%;
  
  & h2 {
    @include lato-black;
    text-transform: uppercase;
    color: $main-accent-color;
    margin: 25px 0;
    word-wrap: break-word;
    text-align: left;
  }
  
  & h3 {
    @include lato-black;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
  }

  & h4 {
    @include lato-black;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  
  & p {

  }
  
  & a {
    @include lato-bold;
    text-decoration: none;
    color: $main-accent-color;
  }
  
  & ul {
    
  }
  
  & ul li {
    margin-bottom: 5px;
  }

  & .privacy__contact-link {
    margin-bottom: 40px;
    font-size: 16px;
    @include lato-black;
    display: inline-block;
  }
}