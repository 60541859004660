.smalls-mezzrow-crosspromo {
  position: relative;
  padding: 50px 30px;
  background-image: url('../image/smalls-mezzrow-crosspromo-bg.jpg');
  background-size: cover;
  background-position: center center;

  &__overlay {
    //@include linear-gradient(to right, $main-accent-color, $mezzrow-color);
    background-color: #000;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &:before, &:after {
    position: absolute;
    left: 0;
    right: 0;
    height: 8px;
    @include linear-gradient(to right, $main-accent-color 0%, $main-accent-color 30%, $mezzrow-color 70%, $mezzrow-color 100%);
    content: ' ';
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &__content {
    color: #fff;
    position: relative;
    z-index: 2;
    text-align: center;
    max-width: 920px;
    margin: 0 auto;
  }

  &__main-copy {
    @include lato-black;
    font-size: 20px;

    a {
      color: #fff;
      @include lato-black;

      &:hover, &:focus {
        opacity: 0.7;
      }
    }
  }

  &__small-copy {
    color: #fff;
    @include lato;
    font-size: 14px;
    padding-top: 40px;
    border-top: 2px solid rgba(#fff, 0.35);
    @include transition(all 0.3s ease-in-out);
    opacity: 0.75;

    &:hover, &:focus {
      opacity: 1;
    }

    a {
      color: #fff;
      @include lato-bold;

      &:hover, &:focus {
        color: rgba(#fff, 0.75);
        opacity: 0.7;
      }
    }
  }

  &__logo-container {
    @include display(flex);
    @include align-items(center);
    @include justify-content(space-around);
    max-width: 540px;
    margin: 40px auto 40px;
  }

  &__logo {
    display: inline-block;
    @include transition(all 0.3s ease-in-out);

    &:hover, &:focus {
      opacity: 0.5;
      @include transform(scale(1.05));
    }

    &.smalls {
      width: 100px;
    }

    &.mezzrow {
      width: 150px;
    }
  }
}
