.mini-event {
  padding: 10px 0 20px;

  &__image {
    display: table-cell;

    img {
      padding: 2px;
      border: 1px #ccc solid;
      width: 86px;
      height: 86px;
      @include filter(grayscale(100%));

      &:hover {
        @include filter(none);
      }
    }
  }

  & p {
    line-height: 22px;
  }
}

.mini-event-info {
  display: table-cell;
  vertical-align: top;
  padding-left: 19px;

  &__date {
    @include lato;
    font-size: 14px;
    color: #131313;
    margin-bottom: 5px;
  }

  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
    margin-top: 0;
    margin-bottom: 10px;

    & a {
      color: #131313;

      &:hover {
        text-decoration: none;
        color: #131313;
      }
    }

    span {
      font-size: 10px;
      display: inline-block;
      padding: 3px 7px;
      background-color: $main-accent-color;
      color: #fff;
      margin-left: 3px;
      position: relative;
      bottom: 3px;
      border-radius: 8px;
    }
  }

  &__sidemen {
    @include lato-bold;
    font-size: 14px;
    color: #1F1F1F;
    margin-bottom: 10px;
    text-transform: uppercase;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__instrument {
    @include lato;
    font-size: 14px;
    color: #1F1F1F;
    text-transform: lowercase;
  }

  &__button {
    @include lato-bold-italic;
    @include red-button;
  }
}
