.from-our-archive {
  background-color: #FFF;
  padding: 30px 30px 10px;

  @media(max-width:$screen-xs-max) {
    padding-bottom: 0;
  }
}

.from-our-archive__section-title {
  @include section-title;
  margin-bottom: 28px;

  & a {
    color: rgba(69, 67, 68, 0.5);

    &:hover, &:focus {
      color: rgba(69, 67, 68, 0.8);
    }
  }

  @media(max-width:$screen-xs-max) {
    margin-top: 5px;
  }
}

.from-our-archive__section-link {
  font-size: 16px;
  @include lato-bold;
  margin-left: 2px;
  white-space: nowrap;

  &:hover, &:focus {
    color: $main-accent-color-darker;
  }
}

.homepage-archive-header {
  padding: 40px 0;
  background-color: $main-accent-color;
  color: #fff;
  text-align: center;
  position: relative;

  &__title {
    @include lato-black;
    font-size: 42px;
    margin: 0 auto 10px;
  }

  &__text {
    @include lato-bold;
  }

  & .cto__button {
    margin-top: 10px;
    background-color: rgba(0,0,0,0.1);
    border-color: rgba(255,255,255,0.7);
  }

  &:after {
    top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: $main-accent-color;
	border-width: 30px;
	margin-left: -30px;
  }
}