.event-details {
  min-height: 400px;
  background-color: #000;
  background-size: cover;
  @include display(flex);
  @include align-items(stretch);
  overflow: hidden;

  &__cancelled-ribbon {
    @include lato-bold;
    color: #fff;
    background-color: $main-accent-color;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    position: absolute;
    @include transform(rotate(45deg));
    padding: 10px 90px;
    right: -70px;
    top: 40px;
    text-transform: uppercase;
  }

  &__image {
    @include cross-browser-grayscale;
  }

  & .info {
    padding: 0 50px 0 20px;
    @include display(flex);
    @include align-items(center);
  }

  &__title {
    @include lato-black;
    font-size: 34px;
    text-transform: uppercase;
    color: $main-accent-color;
    margin-bottom: 0;
    margin-top: 30px;
  }

  &__title a {
    color: $main-accent-color;
    text-decoration: none;
  }

  &__timeslot {
    @include lato-black;
    font-size: 18px;
    color: rgba(255,255,255,1);
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__title a:hover {
    opacity: 0.7;
  }

  &__artists {
    @include lato-black;
    font-size: 14px;
    color: #fefefe;
    line-height: 23px;
    padding-right: 50px;
    letter-spacing: 0.07em;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  &__artists a {
    color: white;
    text-decoration: none;
  }

  &__artists a:hover {
    opacity: 0.7;
  }

  &__instrument {
    @include lato;
    font-size: 14px;
    text-transform: lowercase;
  }

  &__event-button {
    @include lato-bold-italic;
    @include red-button;
    margin-bottom: 30px;
  }
}

.event-select {
  color: #fefefe;
  position: relative;
  padding: 15px 20px;
  background-color: $main-accent-color;

  &__title {
    @include lato-black;
    font-size: 24px;
    margin-top: 0;
  }

  &__date-select {
    @include lato-bold;
    font-size: 14px;
    background-color: transparent;
    color: #fefefe;
    cursor:pointer;
  }

  &__date-select option {
    color: #131313;
  }

  &__slots {
    @include lato-bold;
    font-size: 14px;
    margin-top: 10px;


    li {
      color: #F4C4CC;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
    }

    li:hover {
      color: white;
    }

    li.active {
      color: white;
    }


  }
}

.event-select:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(210, 21, 253, 0);
  border-bottom-color: $main-accent-color;
  border-width: 20px;
  margin-left: -20px;
  z-index: 10001;
}

#upcoming-carousel {
  margin-left: -10px;
  margin-right: -10px;
}

.carousel-indicators {
  position: relative;
  bottom: 0;
  left: 0;
  width: auto;
  margin-left: 0;
  padding-left: 0;
  text-align: left;

  & .active {
    margin: 0;
    width: auto;
    height: auto;
    background: none;
    color: white;
  }

  & li {
    text-indent: inherit;
    border: none;
    border-radius: 0;
    width: auto;
    height: auto;
    margin: 0;
  }
}

.no-events {

  &__container {
    @include display(flex);
    @include align-items(center);
    width: 100%;
  }

  &__title {
    @include lato-black;
    color: #fff;
    opacity: 0.5;
    font-size: 32px;
    display: block;
    width: 100%;
    text-align: center;
  }
}

.upcoming-events .slick-slider {
  margin-bottom: 0;
}

.slick-initialized .slick-slide {
  /*@include display(flex);*/
}

.slick-track {
  @include display(flex);
  @include align-items(stretch);
}

#header__date-picker {

  & input {
    @extend .btn;
    @include lato-bold;
    background-color: $main-accent-color;
    border: 1px rgba(255,255,255,0.6) solid;
    color: white;
    padding: 10px 35px 10px 15px;
    font-size: 14px;
    width: 100%;
    text-align: left;
    @include transition(all 0.2s ease-in-out);
    background-image: url('../image/calendar-dropdown-input-bg.png');
    background-position: right center;
    background-repeat: no-repeat;


    &:hover, &:focus {
      opacity: 0.8;
      color: #fff;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}

@media (max-width: 780px) {
  .event-details {
    min-height: 0;
    text-align: center;

    &__title {
      margin-top: 30px;
    }

    &__artists {
      padding-right: 0;
      margin-bottom: 50px;
    }

    &__event-button {
      display: none;
    }

    .arrow {
      display: none;
    }
  }

  .event-select {
      text-align: center;
      padding: 20px;

    &__title {
      margin-bottom: 20px;
    }

    &__date-select {

    }

    &__date-select option {;
    }

    &__slots {
      margin-top: 20px;
      padding: 0;
      text-align: center;

      li {
        padding: 15px 0;
        width: 100%;
      }

      li:hover {

      }
    }
  }

}

@media (max-width: 993px) {
  .event-details .div-fill-img.wide {
    width: 100%;
    height: auto;
  }

  .event-details .info {
    padding: 20px 30px;
    overflow: hidden;
  }

  .event-details__cancelled-ribbon {
    top: 40px;
    z-index: 100;
  }
}


.upcoming-events.mobile {
  border-bottom: 8px solid $main-accent-color;

  & .event-select {
    margin-left: -10px;
    margin-right: -10px;

    &__title {
      margin-bottom: 10px;
    }

    & a {
      @include lato-bold;
      color: #fff;
      opacity: 0.7;
      text-decoration: none;

      &:hover, &:focus {
        color: #fff;
        opacity: 1;
        text-decoration: none;
      }
    }
  }

  & .event-details__title {
    margin-top: 5px;
  }

  & .event-details__artists {
    margin-bottom: 0;
  }

  & .event-details__timeslot {
    @include lato-bold;
    font-size: 16px;
    color: #fefefe;
    opacity: 0.7;
  }

  .event-select:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(210, 21, 253, 0);
  border-top-color: $main-accent-color;
  border-width: 20px;
  margin-left: -20px;
  z-index: 10001;
}
}


.upcoming-events-mobile {
  width: 100%;
  text-align: center;
  border-bottom: 8px solid $main-accent-color;

  &__header {
    background-color: $main-accent-color;
    color: #fff;
    padding: 20px 0;
    position: relative;

    &__title {
      @include lato-black;
      font-size: 32px;
      margin: 0;
    }

    &__archive-link {
      @include lato-bold;
      color: rgba(#fff, 0.8);
      margin-top: 15px;
      margin-bottom: 10px;
      display: inline-block;
      padding: 5px 10px;
      border: 1px solid rgba(#fff, 0.8);

      &:hover, &:focus {
        color: #fff;
      }
    }

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(210, 21, 253, 0);
      border-top-color: $main-accent-color;
      border-width: 20px;
      margin-left: -20px;
      z-index: 10001;
    }
  }
}

.upcoming-event-mobile {
  @include display(flex);
  width: 100%;
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  background-color: #000;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 100%;
    z-index: 2;
    border-top: 1px solid rgba(#fff, 0.5);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.95)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }

  &__bg-image-cover {
    background-size: cover;
    background-position: center center;
    @include filter(grayscale(100%) blur(3px));
    @include transform(scale(1.05));
    opacity: 0.4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__info {
    position: relative;
    z-index: 2;
    width: 100%;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
  }

  &__title, &__title a {
    @include lato-black;
    font-size: 32px;
    text-transform: uppercase;
    margin: 0 0 8px;
    color: #fff;
  }

  &__artists {
    @include lato-bold;
    font-size: 16px;
    color: #fff;
    margin-top: 15px;

    & a {
      color: #fff;
    }

    & span {
      @include lato;
    }
  }

  &__timeslot {
    @include lato-bold;
    font-size: 18px;
    padding: 0 25px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(#fff, 0.7);
    color: #fff;
    display: inline-block;
  }

  &__event-button {
    @include lato-bold;
    font-size: 14px;
    padding: 10px 20px;
    color: #fff;
    display: inline-block;
    border: 1px solid rgba(#fff, 0.7);
    margin-top: 20px;
  }
}
