.event-tracks {
  background-color: $main-accent-color;
  padding: 0 30px;
}

.event-tracks-header {
  padding: 10px 0;

  &__title {
    @include lato-black;
    font-size: 24px;
    color: white;
    margin-top: 5px;
  }

  &__text {
    @include lato;
    font-size: 14px;
    color: white;
  }
}

.tracks-table-separator {
  border: 1px #333 solid;
  border-bottom: 0;
  margin: 0 -30px;
  opacity: 0.2;
}

.tracks-table {

  &__button {
    @include lato-bold-italic;
    background: $black;
    color: $white-transparent;
    padding: 3px;


    //&:after, &:before {
    //  left: 100%;
    //  top: 50%;
    //  border: solid transparent;
    //  content: " ";
    //  height: 0;
    //  width: 0;
    //  position: absolute;
    //  pointer-events: none;
    //}
    //
    //&:after {
    //  border-color: rgba(136, 183, 213, 0);
    //  border-left-color: $main-accent-color;
    //  border-width: 15px;
    //  margin-top: -15px;
    //}
    //
    //&:before {
    //  border-color: rgba(255, 255, 255, 0);
    //  border-left-color: #fff;
    //  border-width: 16px;
    //  margin-top: -16px;
    //}
  }
}

.event {

  &__tracks-table {
      padding: 0 10px;
      margin-left: -30px;
      margin-right: -30px;

      &__title {
        font-size: 18px;
        margin: 0 0 20px -10px;
      }

      &__header {
        padding: 20px 10px;
        border-top: 1px solid rgba(#000, 0.1);
        border-bottom: 1px solid rgba(#000, 0.1);
        @include display(flex);
        @include align-items(center);
        @include flex-wrap(wrap);
        color: #fff;

        &:before, &:after {
          display: none;
        }
      }

      &__row {
        padding: 7px 0;
        background-color: #fff;
        border-bottom: 1px solid rgba(#000, 0.1);
        @include display(flex);
        @include align-items(center);
        @include flex-wrap(wrap);
        padding: 10px 10px;

        &:before, &:after {
          display: none;
        }

        &:last-of-type {
          border-bottom: 2px solid rgba(#000, 0.1);
        }
      }

      &__column {

        &.preview {
          text-align: center;

          & a {
            color: $base-text-color;

            &:hover, &:focus {
              color: rgba($base-text-color, 0.7);
            }
          }
        }

        &.title, &.buy-track {
          text-align: left;
        }

        &.buy-track {
          padding-right: 30px;
          @include display(flex);
          @include align-items(center);
          @include justify-content(flex-end);
        }

        &.duration, &.price {
          text-align: right;
        }
      }

      & a {
        color: #fff;

        &:hover, &:focus {
          color: rgba(#fff, 0.7);
        }
      }

      & .tracks-table__button {
          border: 1px solid #fff;
          border-right: none;
          position: relative;
          float: right;

          &:after, &:before {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(136, 183, 213, 0);
            border-left-color: $main-accent-color;
            border-width: 14px;
            margin-top: -14px;
          }

          &:before {
            border-color: rgba(255, 255, 255, 0);
            border-left-color: #fff;
            border-width: 15px;
            margin-top: -15px;
          }

          & i {
            margin-left: 5px;
          }
        }

        & .arrow {
          float: right;
        }

      @media (max-width: $screen-sm-max) {
        margin-top: 30px;
      }
    }
}
