.artist-details {
  @include bottom-section-border;
  min-height: 340px;
  background-color: #000;
  background-size: cover;
  float: left;
  width: 100%;

  & .hero-image {
    padding: 0;

    & img {
      @include filter(grayscale(100%));
      width: 100%;
    }
  }

  &__info {
    padding: 20px;
  }

  &__name {
    @include lato-black;
    font-size: 56px;
    text-transform: uppercase;
    color: $main-accent-color;
    margin-bottom: 6px;
    min-width: 50%;
    max-width: 68%;
    margin-top: 0;
  }

  &__instruments {
    @include lato-black;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 13px;
    text-transform: uppercase;
    min-width: 40%;
    max-width: 80%;
  }

  &__stats {
    @include lato-black;
    font-size: 16px;
    padding: 14px 0;
    color: #ffffff;
    border-top: 2px #ccc solid;
    border-bottom: 2px #ccc solid;
    margin-bottom: 17px;

    & a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: rgba(255,255,255,0.7);
        text-decoration: none;
      }
    }
  }

  &__website {
    @include lato-black;
    color: $main-accent-color;
    font-size: 16px;
  }
}