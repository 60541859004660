html {
  overflow: scroll;
  overflow-x: hidden;
}


body {
  &.admin-header {
    @media (max-width: 960px) {
      .pad-content {
        padding-top: 54px;
      }

      .artist-search-profile-container {
        padding-top: 54px;
      }
    }
  }

//   @include background-image(url("../image/header_bg.jpg"), url("../image/background-tile.png"));
  background-color: $background-color;
  background-position: center top, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 100% 404px, 10px 10px;
  color: $black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-rendering: optimizeLegibility;

  @media (max-width: $screen-md-max) {
    padding-top: 65px;
  }

  .no-backgound {
    background-image: none;
  }
}

img {
  max-width: 100%;
}

.hidden-body {
  overflow: hidden;
  height: 100vh;
}

button,
input[type="submit"] {
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }
}

.form-control {
  height: auto;

  &:focus {
    border-color: $main-accent-color !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.pp-btn {
  border: 0px;
  background: #f0f0eb;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters>[class^="col-"],
.row.no-gutters>[class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.artist {
  @include bottom-section-border;
  background-color: #fff;
}

// equal height columns on large devices
@media (min-width: $screen-md-min) {
  .equal-heights.row {
    // overflow: hidden;
    @include display(flex);
  }

  //  .equal-heights [class*="col-"] {
  //    margin-bottom: -99999px;
  //    padding-bottom: 99999px;
  //  }
}

.bw-wrapper {
  @include filter(grayscale(100%));
}

.form-control:focus {
  border-color: rgba(254, 254, 254, 0.6);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(254, 254, 254, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(254, 254, 254, 0.6);
}

a {
  color: $black;
}

a:hover {
  color: var(--color-wine-red);
  text-decoration: none;
}

a:active {
  color: rgba(254, 254, 254, 0.8);
  text-decoration: none;
  box-shadow: none;
  outline: none;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.vbottom {
  display: inline-block;
  vertical-align: bottom;
  float: none;
}

.div-fill-img {
  z-index: 1 !important;
}

.vertical-align-parent {
  display: table;
}

.vertical-align-child {
  display: table-cell;
  vertical-align: middle;
  padding: 50px 0;

  @media (max-width: $screen-xs-max) {
    padding: 30px 0;
  }
}

.div-fill-img.wide {
  height: 100%;
  width: auto;
}

.div-fill-img.tall {
  width: 100%;
  height: auto;
}

.float-none {
  float: none;
}

.float-left {
  float: left;
}

.modal {
  z-index: 100000;
}

.modal-backdrop.fade.in {
  z-index: 101;
}

.alert {
  margin-bottom: 0;
  padding: 10px;
}

.alert-buttons {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

p.unavailable {
  opacity: 0.6;
  font-style: italic;
}

.alert-dismissible {
  margin-bottom: 3px;
}

.content-wrapper {
  border: 10px solid rgba(31, 31, 31, 0.07);
  border-top: none;
  margin-right: auto;
  margin-left: auto;
}

.form-error-text {
  @include lato;
  font-size: 12px;
  color: $main-accent-color;
}

@media (max-width: 740px) {
  .content-wrapper {
    width: 100%;
    border: none;
  }

  .text-center-xs {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .content-wrapper {
    width: 760px;
  }
}

@media (min-width: 992px) {
  .content-wrapper {
    width: 980px;
  }
}

@media (min-width: 1200px) {
  .content-wrapper {
    width: 1180px;
  }
}

.badge.header-user {
  margin-left: 5px;
  font-size: 10px;
}

.equal-heights-xs {
  @media (max-width: $screen-xs-max) {
    @include display(flex);
    @include flex-wrap(wrap);

    &:before,
    &:after {
      display: none;
    }
  }
}

@for $i from 1 through 8 {
  body.headers-#{$i} {
    &.admin-header {
      @media (max-width: $screen-xs-max) {
        .pad-content {
          padding-top: $mobile-bar-height * $i + 30px;

          &.artist-search-profile-container {
            padding-top: 54px;
          }
        }
      }

      @media (max-width: $screen-xs-max) {
        .pad-content {
          padding-top: $mobile-bar-height * $i;
        }
      }
    }
  }
}
