$background-color: #333;
$default-accent-color: #d21535;
$default-accent-color-darker: #a01129;
$main-accent-color: #d21535;
$main-accent-color-darker: #a01129;
$hover-color: #a41029;
$base-text-color: #131313;

$metric-positive: #3F9E52;
$metric-neutral: #ccc;
$metric-negative: #D21333;
$metric-normal: #555;

$mezzrow-color: #F26F40;

$light-gray-color: #dddddd;

$input-height: 40px;
$input-border: 8px;
$event-card-width: 250px;
$event-card-height: 150px;

$mobile-bar-height: 54px;
$screen-sm-max: 960px;

$content-left-padding: 3.5vw;
