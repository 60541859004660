.buy-album {
  background-color: white;
  padding: 40px 30px 60px;

  &__cto-title {
    @include lato-black;
    font-size: 24px;
    color: rgba(69, 67, 68, 0.32);
    margin-top: 0;
  }

  &__description, &__format {
    @include lato;
    font-size: 14px;
    color: #777777;
    margin-bottom: 0;

    & a {
      color: $main-accent-color;

      &:hover, &:focus {
        color: darken($main-accent-color, 10%);
      }
    }
  }

  &__title {
    @include lato-bold;
    font-size: 24px;
    color: #131313;
    margin-top: 0;
  }

  &__price {
    @include lato-bold;
    font-size: 20px;
    color: $main-accent-color;
  }

  &__button {
    @include lato-bold-italic;
    @include red-button;
  }

  & img {
    display: block;
    float: none;
    margin: 0 auto;
  }

  &__add {
    max-width: 320px;
  }
}

@media (max-width: 740px) {
  .buy-album .buy-button-container {
    margin: 0 auto;
    float: none;
    display: inline-block;
    margin-left: 30px;
  }

  .buy-album img {
    margin: 20px auto;
  }
}