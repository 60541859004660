.static-page {
  background-color: var(--color-white);

  &__content {
    @include display(flex);
    @include flex-wrap(wrap);

    &::before,
    &::after {
      display: none;
    }
  }
}

.static-tab {
  display: none;

  &.active {
    display: inherit;
  }
}

.passes-days {
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  .day-list {
    margin: 0 10px;

    @media (max-width: 960px) {
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

.venues-static {
  display: flex;
  flex-direction: row;
  justify-content: start;

  .flex-column {
    margin: 0 30px 0 0;
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.static-nav-menu {
  @include list-unstyled;
  padding-left: 0;
  list-style: none;
  position: fixed;
  width: 18%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;

  @media (max-width: $screen-sm-max) {
    display: none;
  }

  li {
    @include white-border-button;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}

.info-page-container {
  width: 80vw;

  @media (max-width: $screen-sm-max) {
    width: 100vw;
  }

  &.legal-content {
    font-family: 'Reem Kufi', sans-serif;
    font-weight: 400;
    font-size: 16px;

    ul {

      li {
        list-style: none;
      }
    }
  }

  .white-border-button {
    display: inline;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: $screen-sm-max) {
      display: none;
    }
  }
}

.static-content {
  @include text4;
  width: 100%;
  overflow: hidden;
  transition: all 1.4s;
  padding: 0 10%;
  max-height: 120%;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 0 2vw;
  }

  &.closed {

    @media (max-width: $screen-sm-max) {
      height: calc(100vw * 0.0390625 + 37.5px);
      transition: all 0.6s;
    }
  }

  .container {

    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  img {
    margin-bottom: 10px;
    width: 20%;

    &.big {
        width: 40%;

        @media(max-width: $screen-md-min) {
            width: 100%
        }
    }

    &.logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
      width: 80%;
    }

    &.centered {
      display: block;
      margin: 0 auto 40px;
    }
  }

  a {
    word-wrap: break-word;
  }

  p {
    margin-bottom: 40px;
  }

  .less-space {
    margin: 20px 0;
  }

  &.basic-page {
    margin-top: 30px;
  }

  .flex-row {
    width: 100%;
  }

  strong {
    color: $accent-color;
  }

  .flatpage-content {
    width: 100%;
  }

  .main-title {
    @include event-overlay-title;
    margin-bottom: 20px;

    .white-text {
      color: $white-transparent;
    }
  }

  @media(max-width: $screen-md-min) {
    position: relative;
    z-index: 999;

    img {
      width: 100%;

      &.logo {
        width: 100%
      }
    }
  }

  @media (max-width: 400px) {

    #mc_embed_shell {

      .mc_field_group {
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

.newsletter-wrapper {
  max-width: 350px;
  margin: 0 auto;
  
  p {
    margin-bottom: 10px;
  }

  @media (max-width: 960px) {
    max-width: 100%;
  }
}

.map-wrapper {
  padding-bottom: 40px;
  @include filter(grayscale(60%));

  @media (max-width: 960px) {
    position: relative;
    width: 100%;
    z-index: 0;
  }
}

.about-page {

  .about-content-wrapper {
    margin-bottom: 40px;
  }

  .mc_field_group {
    align-items: start !important;
    justify-content: start !important;
    margin-left: -10px;

    label {
      display: none;
    }

    .mce_inline_error {
      width: unset !important;
      left: 10px !important;
    }

    .clear {

      @media (max-width: 400px) {
        margin-left: 10px;
      }
    }
  }
}


.big-quote {
  position: relative;
  left: -20px;
  line-height: 0;
  font-size: 3em;
  font-weight: 600;
  top: 20px;
  transform: none;
  margin-right: -15px;

  &.closing {
    left: 0;
  }
}

.overview{
  font-family: "Reem Kufi", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  font-style: italic;
  letter-spacing: 0.04em;
  height: auto;
  margin-top: 0;
  line-height: 110%;
}
