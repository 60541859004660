.cto{
  @include bottom-section-border;
  background-size: cover;
  color: white;
  min-height: 160px;
  text-align: center;

  &__title {
    @include lato-black;
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 11px;

    @media(max-width:$screen-xs-max) {
      font-size: 26px;
    }
  }

  &__text {
    @include lato-bold;
    font-size: 14px;
    margin-bottom: 21px;

    &--large {
      font-size: 24px;
      margin-bottom: 4px;
    }
  }

  &__button {
    @extend .btn;
    @include lato-bold;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px $main-accent-color solid;
    color: white;
    font-style: italic;
    padding: 11px 35px;
    font-size: 16px;
    @include transition(all 0.2s ease-in-out);

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
      color: rgba(255, 255, 255, 0.6);
    }

    &--block {

      @media(max-width:$screen-xs-max) {
        display: block;
      }
    }
  }

  &__span {

    &--block {
      @media(max-width:$screen-xs-max) {
        display: block;
        margin: 10px 0;
      }
    }
  }

  &--archive {
    background-image: url('../image/archive-cto.jpg');
    background-position: center center;
    position: relative;
  }

  &--membership {
    background-image: url('../image/home-membership-cta.jpg');
    background-position: center center;
    padding: 30px;

    &__title {
      @include lato-black;
      font-size: 42px;
      max-width: 760px;
      margin: 0 auto 10px;
    }

    & p {
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;
    }

    & .cto__button {
      margin-top: 0px;
    }
  }

  &--visit {
    background-image: url('../image/visit-us-bg.jpg');
    background-position: center center;
  }

  &--subscribe-to-artist {
    background-image: url('../image/subscribe-artist-cto.jpg');
    background-position: center center;
  }

  &--listen-to-show {
    background-image: url('../image/listen-to-show-cto.jpg');
    background-position: center center;
  }

  &--video-not-available {
    background-image: url('../image/video-not-available-cto.jpg');
    background-position: center center;
  }

  &--schedule-not-authenticated {
    background-image: url('../image/video-not-available-cto.jpg');
    background-position: center center;

    & span {
      margin: 0 10px;

      @media(max-width:$screen-xs-max) {
        margin: 10px 0;
      }
    }
  }

  &--schedule {
    background-image: url('../image/schedule-cto.jpg');
    background-position: center center;

    & .cto__button {
      margin-top: 20px;
      outline: none;
    }

    & .cto__text {
      font-size: 16px;
    }
  }

  &--archive-header {
    background-image: url('../image/archive-header-cto.png');
    background-position: center center;
  }

  &--search {
    background-image: url('../image/search-cto.jpg');
    background-position: center center;
  }
}

@media (max-width: 700px) {
  .cto {
    width: 100%;
  }
}