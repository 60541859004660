@media (max-width: $screen-md-max) {

  .container {
    width: 100%;
  }

  .content-wrapper {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}