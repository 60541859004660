.audio-player {

  &__container {
    padding: 0;
  }

  &__text {
    margin-top: 50px;
    margin-bottom: 80px;
    padding: 0 20px;
  }
}