.reset-password {
  background-color: #FFFFFF;
  padding: 30px 20px 20px;

  & a {
    @include lato-bold;
    color: $main-accent-color;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  &__main-title {
    @include lato-black;
    font-size: 32px;
    color: $main-accent-color;
    line-height: 130%;
    margin-bottom: 20px;
  }

  &__form {

    &__heading {
      @include lato-black;
      font-size: 18px;
      color: #131313;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    & label {
      @include lato;
      font-size: 12px;

      & .required {
        @include lato-bold;
        font-size: 12px;
        color: $main-accent-color;
      }
    }

    & .form-control {
        @include lato;
        font-size: 16px;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 0;
      }

    & input[type="checkbox"] {
        float: left;
        clear: left;
        display: block;
        margin-left: 10px;
    }

    & .checkbox-label {
        @include lato;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
      }

    &__button {
      @include lato-bold;
      border: 0;
      font-size: 16px;
      padding: 10px 60px;
      background-color: $main-accent-color;
      color: #fff !important;
      text-decoration: none;
      margin-top: 30px;
      margin-bottom: 10px;
      display: block;
      float: left;

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }

  &__hints {
    padding-left: 20px;
    @include lato;
    font-size: 14px;

    &__list {
      list-style: none;
      padding: 0;

      & li {
          margin-bottom: 5px;
          padding-left: 20px;
          text-indent: -15px;

          &:before {
            content: "• ";
            color: $main-accent-color;
            margin-right: 6px;
          }
      }
    }
  }
}