.invite-artist {
  padding: 40px 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  &__heading {
    @include lato-black;
    color: #121212;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  &__text {
    display: block;
    @include lato;
    font-size: 14px;
    margin-bottom: 30px;
    max-width: 640px;
  }

  & .form-control {
    max-width: 480px;
    line-height: 24px;
    padding: 10px 20px;
    display: inline-block;
  }

  & input[type="submit"] {
    display: inline;
  }
}

.modal-backdrop {
  position: fixed;
}

.invite-artist-modal {
  padding: 40px 20px;

  &__heading {
    @include lato-black;
    color: #121212;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  &__text {
    display: block;
    @include lato;
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;
  }

  & .form-control {
    max-width: 100%;
    line-height: 24px;
    padding: 10px 20px;
    display: inline-block;
  }

  & input[type="submit"] {
    background-color: $main-accent-color;
    color: #fff;
    @include lato;
    padding: 12px 20px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-top: 15px;
  }
}