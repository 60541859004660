.home {
  .white-border-button {
  }
}

.musicians-results .calendar-filters {
  position: absolute;
  left: calc(544px + 3.5vw);
}

.date-error {
  padding-left: 3.5vw;

  &.no-h-padding {
    padding-left: 0;
  }
}

.section-title-centered {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  background: linear-gradient(to right, $white-transparent, $white-transparent)
    no-repeat bottom;
  background-size: 50% 5px;

  width: 100%;
}
.calendar-filters {
  &.desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 30px;
    position: relative;
    width: 400px;
    @media (max-width: 1023px) {
      margin: 0 auto;
      padding-left: 0;
      padding-top: 10px;
    }
    @media (max-width: 380px) {
      margin: 0 auto;
      padding: 0 0%;
      width: 300px;
    }

    @media (max-width: 1023px) {
      display: block;
      margin-left: 3%;
    }
  }
}
.new-calendar .home .calendar-filters .datepicker-btn {
  margin-left: 20px;
}
.section-title {

  &__container{
    display: flex;
    margin-bottom: 20px;

    @media(max-width: $screen-md){
      display: inline;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  $underline-length: 600px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 0;
  padding-left: $content-left-padding;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  background: linear-gradient(to right, $white-transparent, $white-transparent)
    no-repeat bottom left;
  background-size: calc(600px + 3.5vw) 5px;

  width: 100%;

  @media (max-width: $screen-sm-max) {
    background-size: 100vw 10px;
    padding-bottom: 9px;
    width: 500px;
    margin-top: 15px;
    margin-bottom: 0;

    &.musicians-results-title {
      //background-size: 40vw 3px;
      //padding-bottom: 3px;
      //width: auto;
      margin-top: 5px;
      margin-bottom: 25px;

      border-bottom: 4px solid white;
      line-height: 3vw;
      width: fit-content;
      margin-left: 0;
      padding-left: 2vw;
      padding-right: 20px;
      padding-bottom: 0;
      background: unset;
      overflow-y: visible;
    }
  }


  .label{
    &__related{
      &--more{
        display: inline-block;
        @media(max-width: $screen-xs){
          display: none;
        }
      }
      &--less{
        display: none;
        @media(max-width: $screen-xs){
          display: inline-block;
        }
      }
    }
  }

  @media (max-width: $screen-md-min) {
    .date-separator {

    }
  }

  &.catalog {
    &.featured {
      background-size: 49vw 5px;
      margin-bottom: 20px;
      padding-bottom: 0px;
      width: 100vw;

      @media(max-width: $screen-md) {
        padding-bottom: 3px;
      }
    }

    &.list {
      background-size: 52vw 5px;
      margin-bottom: 20px;
      padding-bottom: 0px;
      width: 100vw;

      @media(max-width: $screen-md) {
        padding-bottom: 3px;
      }
    }
  }
  &.calendar {
    background-size: calc(520px + 3.5vw) 5px;

    &.upcoming-shows {
      background-size: calc(520px + 3.5vw) 5px;
      width: 40%;

      @media (max-width: 1360px) {
        width: 50%;
      }

      @media (max-width: $screen-md-max) {
        width: 80%;
      }

      @media (max-width: $screen-md-min) {
        width: 90%;
      }

    }
  }

  &.archive {
    position: relative;
    z-index: 99;
    margin-top: 25px;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
    padding-right: 2vw;
    width: auto;
    background-size: calc(600px + 8.5vw) 5px;

    @media (max-width: 420px) {
      //background-size: 100vw 5px;
      margin-bottom: 20px;
      padding-bottom: 3px;
      //width: 100vw;
    }

    .archive-filter {
      align-items: center;
      display: flex;
      height: 59px;
      margin-left: 140px;

      @media (max-width: $screen-sm-max) {
        display: none;
      }

      .select-container {
        margin-right: 30px;
      }
    }

    @media (max-width: $screen-sm-max) {
      //background-size: 80vw 5px;
      margin-bottom: 40px;
      //padding-bottom: 3px;
      //width: 97vw;
      width: fit-content;
      line-height: 2vw;
      border-bottom: 5px solid white;
      background: unset;
      padding-bottom: 0;
      padding-left: 2vw;

    }
    @media(max-width: $screen-sm-max) {
      margin-top: 7vw;
    }
    @media (max-width: $screen-xs) {
      //background-size: 90vw 5px;
      //width: 100vw;
    }

    span {
      padding-bottom: 4px;
    }
  }

  &.calendar {
    position: relative;
    z-index: 99;

    @media (max-width: $screen-md-min) {
      background-size: 65vw 5px;
      margin-bottom: 20px;
      padding-bottom: 3px;
      width: 100vw;
    }

    @media (max-width: 350px) {
      background-size: 75vw 5px;
    }

    span {
      padding-bottom: 4px;
    }
  }

  &.home {
    position: relative;
    z-index: 99;
    background-size: calc(415px + 3.5vw) 5px;

    &.clubs {
        background-size: calc(580px + 3.5vw) 5px;

        @media (max-width: $screen-md-min) {
            background-size: calc(50vw + 3.5vw) 5px;
        }

        @media (max-width: 420px) {
            background-size: calc(35vw + 3.5vw) 5px;
        }
    }

    .white-border-button {
      margin-left: 120px;

      @media (max-width: $screen-sm-max) {
        width: 120px;
        text-align: center;
        margin-left: 100px;
        font-size: 11px;
      }

      @media (max-width: 420px) {
        text-align: center;
        margin-left: 80px;
      }
    }

    span {
      cursor: pointer;
    }

    @media (max-width: 451px) {
      .at-the-clubs {
        display: none;
      }

      span {
        &.trim {
          width: 60px;
          text-overflow: ellipsis;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          vertical-align: bottom;
        }
      }
    }

    @media (max-width: $screen-md-min) {
      background-size: 60vw 5px;
      margin-bottom: 20px;
      padding-bottom: 3px;
      width: 100vw;

      &.recently {
        background-size: 62vw 5px;
      }

      .date-separator {
        display: none;
      }

      .date {
        display: none;
      }

      .select-container {
        height: 100%;
      }

      .white-border-select.home-filter {
        width: 120px;
        height: 100%;

        .select-items {
          top: 98%;
        }

        .select-selected {
          height: 100%;
          justify-content: center;

          /*flex-end;*/
          /*padding-right: 7vw; */
          &::after {
            top: auto;
            right: 3vw;
          }
        }

        .select-items {
          font-size: 2vw;

          @media (max-width: $screen-xs-max) {
            font-size: 11px;
          }
        }
      }

    }

    @media (max-width: 451px) {
      background-size: 40vw 5px;

      &.highlights {
        background-size: 80vw 5px;
      }

      &.recently {
        background-size: 80vw 5px;

        &.trim {
          background-size: 58vw 5px;
        }

      }
    }

    &.recently {
      @media (max-width: $screen-md-min) {
        height: 40px;
      }
    }
  }

  &.section-flex {
    width: 100%;

    @media (max-width: $screen-sm-max) {
      background-size: 65vw 5px;
      margin-bottom: 20px;
      padding-bottom: 3px;
      padding-left: 2vw;
      width: 100vw;
      position: relative;
      z-index: 99;
    }
  }

  &.section-title-no-padded {
    padding-left: 0;
  }

  .date-separator {
    color: $accent-color;
  }

  @media (max-width: 720px) {
    .home {
      &.date-separator {

      }
      &.date {

      }
    }
  }

  // Adjust titles
  > div {
    &.title1,
    &.title2,
    &.title7 {
      display: inline-block;
    }

    &.title1,
    &.title2 {
      &.header-sub {
        margin-bottom: 0;
      }
    }

    &.title1 {
      display: inline-block;
      line-height: 46px;
      white-space: nowrap;
    }

    &.title2 {
      line-height: 25px;
    }

    @media (max-width: $screen-md-min) {
      &.title1 {
        font-size: 4vw;
        line-height: 5vw;
      }

      &.title2 {
        font-size: 3vw;
        line-height: 2.5vw;
      }
    }

    @media (max-width: 450px) {
      &.title1 {
        font-size: 5vw;
        line-height: 6vw;
      }
    }

    &.title7 {
      line-height: 1.2em;
    }
  }
}

.top-event-slider {

  .section-title {

    &.calendar {
      background-size: calc(645px + 3.5vw) 5px;
    }
  }
}

.tabs div .title1 {
  @media (min-width: 690px) and (max-width: 850px) {
    //line-height: 5vw;
  }
  @media (min-width: 851px) and (max-width: $screen-sm-max) {
    //line-height: 4.4vw;
  }
  @media (min-width: $screen-sm-max) and (max-width: 1024px) {
    line-height: 4.7vw;
  }
}
@media (max-width: $screen-md-max) {
  .store-overview__group .section-title {
    padding-bottom: 0;
    background-size: 750px 5px;

    div.title1 {
      font-size: 30px;
      line-height: 46px;
      white-space: nowrap;
    }
  }

  .store-overview .white-line-bottom > .title1 {
    font-size: 30px;

    @media (max-width: $screen-sm-max) {
      line-height: 36px;
      text-align: left;
    }
  }
}

.store-overview__group {
  .section-title {
    white-space: nowrap;
  }
}

@media (max-width: $screen-sm-max) {
  .store-header__content .title1.center {
    padding-bottom: 8px;
    line-height: 16px;
  }
}

.title-and-filters-container {
  display: flex;
  flex-direction: row;
  @media (max-width: $screen-md-max) {
    flex-direction: column;
    align-items: flex-start;
  }
}
