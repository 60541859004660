.home-membership {
  padding: 0;
  background-color: #fff;
  @include lato;

  &:before {
    display: none;
  }

  @media(max-width: $screen-xs-max) {
    display: none;
  }

  &__header {
    background-image: url('../image/home-membership-cta.jpg');
    background-position: center center;
    background-size: cover;
    text-align: center;
    border-bottom: 8px solid $main-accent-color;
    color: #fff;
    width: 100%;
    padding: 10px 30px;
    position: relative;

    @media(max-width:$screen-xs-max) {
      padding: 0 15px;
    }

    &__title {
      @include lato-black;
      font-size: 32px;
      max-width: 640px;
      margin: 0 auto 15px;
      line-height: 130%;

      @media(max-width:$screen-xs-max) {
        font-size: 20px;
      }
    }

    &__text {
      @include lato-bold;
      font-size: 14px;
      margin: 0 auto;
      line-height: 130%;
      max-width: 760px;
    }

    & .cto__button {
      margin-top: 30px;
    }

  }

  &__points {
    padding: 20px 10px 40px;
    text-align: center;
    float: left;
    @include display(flex);
    @include flex-wrap(wrap);
    border-bottom: 2px solid #eeeeee;

    &__single {
      padding: 0 20px;
      margin-top: 30px;
    }

    &__graphic {
      margin-bottom: 20px;
      display: inline-block;

      & img {
        max-width: 54px;
        max-height: 54px;
        width: auto;
        height: auto;
      }
    }
  }

}
