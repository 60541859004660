$mobile-event-card-width: $event-card-width / 1.5;
$live-card-width-mobile: 44.1vw;
$live-card-width-tablet: 22.85vw;
$live-card-width-medium: 22.85vw;
$live-card-width-large: 15.1vw;
$regular-card-width-mobile: 44.1vw;
$regular-card-width-tablet: 15.1vw;
$regular-card-width-medium: 15.1vw;
$regular-card-width-large: 11.225vw;
$event-card-margin: 0.4vw;
$event-row-padding: 3.5vw;

.live {
  .slide-btn, .slick-slide-btn {
    &.prev,
    &.next {
      @media (min-width: 1366px) {
        height: $live-card-width-large * 0.6;
      }

      @media (max-width: 1365px) and (min-width: 1024px) {
        height: $live-card-width-medium * 0.6;
      }
    }

    &.next {
      border-radius: 10px 0 0 0;
    }
  }

  .event-info-title {
    @media (min-width: 1366px) {
      width: 14vw;
    }

    @media (max-width: 1365px) and (min-width: 1024px) {
      width: 20vw;
    }

    @media (max-width: 1023px) and (min-width: 961px) {
      width: 20vw;
    }

    @media (max-width: $screen-sm-max) {
      width: 44vw;
    }
  }
}

.slide-btn, .slick-slide-btn {
  &.prev,
  &.next {
    border-radius: 10px 0 0 10px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("/static/image/arrow-right-red.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 1000;
    transition: 0.2s ease all;

    &:hover {
      background-color: rgba(0, 0, 0, 0.9);
    }

    @media (min-width: 1366px) {
      height: $live-card-width-large * 0.6;

      &.recently, &.popular {
        height: $regular-card-width-large * 0.6;
      }

    }

    @media (max-width: 1365px) and (min-width: 1024px) {
      height: $live-card-width-tablet * 0.6;
      &.recently, &.popular {
        height: $regular-card-width-medium * 0.6;
      }
    }

    @media (max-width: 1023px) and (min-width: 961px) {
      height: $live-card-width-tablet * 0.6;
      &.recently, &.popular {
        height: $regular-card-width-medium * 0.6;
      }

    }

    &.highlights, &.recently, &.popular {
      border-radius: 0;
    }
  }

  &.prev {
    transform: scaleX(-1);
    width: calc(3.5vw - 10px);
    left: 0;

    b:before {
      content: "\002b";
      position: absolute;
      right: 0.78vw;
      top: -0%;
    }

    &.today-and-tomorrow-arrow {
      width: 2.5vw;
      z-index: 10;

      @media (max-width: 960px) {
        width: 3.5vw;
        visibility: hidden;
      }

      @media (max-width: 550px) {
        width: 3.5vw;
        visibility: hidden;
      }
    }

    &.highlights, &.recently, &.popular {
      border-radius: 0;
    }

    &.slider {
      width: 3.1vw;
    }
  }

  &.next {
    width: calc(3.5vw - 10px);
    right: 0;

    b:before {
      content: "\002b";
      left: 0.78vw;
      position: absolute;
      top: 0;
    }

    &.today-and-tomorrow-arrow {
      width: 2.5vw;
      z-index: 10;

      display: none;

      @media (max-width: 960px) {
        width: 3.5vw;
        visibility: hidden;
      }

      @media (max-width: 550px) {
        width: 3.5vw;
        visibility: hidden;
      }
    }

    &.slider {
      width: $content-left-padding;
    }
  }

  b {
    display: none;
    transform-origin: 45% 50%;
    height: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: $accent-color;
    font-size: 10vh;
    -webkit-transition: -webkit-transform 0.1s ease-out 0s;
    transition: -webkit-transform 0.1s ease-out 0s;
    -o-transition: -o-transform 0.1s ease-out 0s;
    -moz-transition: transform 0.1s ease-out 0s, -moz-transform 0.1s ease-out 0s;
    transition: transform 0.1s ease-out 0s;
    transition: transform 0.1s ease-out 0s, -webkit-transform 0.1s ease-out 0s,
      -moz-transform 0.1s ease-out 0s, -o-transform 0.1s ease-out 0s;
  }
}

#artists {
  .slide-btn, .slick-slide-btn {
    &.prev,
    &.next {
      @media (min-width: 1366px) {
        height: $regular-card-width-large;
      }

      @media (max-width: 1365px) and (min-width: 960px) {
        height: $regular-card-width-tablet;
      }

      @media (max-width: 960px) {
        display: none;
      }
    }
  }

  .loading-image {
    position: absolute;
    top: 40%;
    height: 10%;
    right: 0;
    width: 3vw;
    pointer-events: none;
    z-index: 1000;

    @media (max-width: $screen-sm-max) {
      bottom: 5px;
      left: calc(50% - 1.5vw);
      height: 10px;
      right: auto;
      top: auto;

      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.event-stripe.archive {
  .slide-btn, .slick-slide-btn {
    &.prev,
    &.next {
      @media (min-width: 1366px) {
        height: $regular-card-width-large * 0.6;
      }

      @media (max-width: 1365px) and (min-width: 1024px) {
        height: $regular-card-width-tablet * 0.6;
      }

      @media (max-width: 1023px) and (min-width: 961px) {
        height: $regular-card-width-tablet * 0.6;
      }
    }
  }
}

.event-row {
  display: flex;
  padding-left: $content-left-padding;

  @media (min-width: $screen-sm-max) {
    padding-left: $content-left-padding;
  }

  @media (max-width: $screen-sm-max) {
    overflow-y: hidden;
    overflow-x: auto;

    &.artists {
      overflow-x: hidden;
    }
  }
}

.tonight-events {
  @extend .event-row;
  padding: 0;

  @media (min-width: $screen-sm-max) {
    padding: 0;
  }
}


.tonight-events {
  @extend .event-row;
  padding: 0;

  @media (min-width: $screen-sm-max) {
    padding: 0;
  }
}

.recently-carousel, .popular-carousel, .catalog-carousel {
  @extend .event-row;
  padding: 0;

  @media (min-width: $screen-sm-max) {
    padding: 0;
  }
}

.event-display {
  margin-right: $event-card-margin;
  position: relative;
}

.owl-carousel {
  .event-display {
    margin-right: 0;
  }
}

.event-row .event-display-today-and-tomorrow {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 35vh;
  background-size: cover;
  background-blend-mode: saturation;
  flex-direction: column;
  justify-content: space-between;

  a {
    width: 100%;
    height: calc(100% - 6vw);

    @media (max-width: $screen-md){
      height: calc(100% - 11vw);
    }
    @media (max-width: 650px){
      height: calc(100% - 14vw);
    }
  }

  .event-card,
  .event-picture-container,
  .picture-border {
    min-height: auto;
    height: 100%;

    div.event-picture {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        min-height: 220px;
        max-width: 350px;
        min-height: unset;
        max-width: unset;
        object-fit: cover;
      }
    }
  }

  .artists {
    background-color: white;
    height: 6vw;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-bottom: 0.7vw;
    padding-left: 0.7vw;
    padding-top: 0.7vw;
    margin-top: -5vw;
    z-index: 999;

    @media (max-width: $screen-md) {
      height: 11vw;

      .title5 {
        font-size: 2vw;
        height: 2.3vw;
        line-height: 2.8vw;
      }
    }

    @media (max-width: 650px){
      height: 14vw;
    }

    a,
    .artists-name {
      line-height: 32px;
      width: 48%;
      height: 1.4vw;
      margin-right: 2%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      line-height: 1.4vw;
      font-size: 1.2vw;

      &:hover {
        color: $accent-color;
        text-decoration: underline;
      }

      @media (max-width: 650px) {
        &.title5 {
          padding-right: 10px;
          font-size: 2.5vw;
          height: 4vw;
          line-height: 4vw;
        }

        &.full-width {
          width: 100%;
        }
      }
    }

    .artists-name {

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }

  .event-card  {
    .event-picture-container {
      border-radius: 0;
    }
  }

  .event-info {
    width: 100%;
    height: calc(100% - 6vw);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: auto;
    pointer-events: none;

    @media (max-width: $screen-md-min) {
      .title5 {
        font-size: 2vw;
      }
      .venue {
        font-size: 1.8vw;
      }
      .sub-info .event-info-title {
        font-size: 2.2vw;
      }

      height: calc(100% - 9vw);
    }

    div {
      //margin-bottom: 5px;
    }

    .title5.sets {
      color: white;
      width: 100%;
    }

    .event-info-title {
      //max-height: 3.6vw;
      line-height: 1.7vw;
      font-size: 1.58vw;
      color: white;
      margin: 0 0 7px 0;
      width: fit-content;
      font-weight: 400;
      font-family: "Oswald", sans-serif;
      padding-top: 0.3vw;
      transform: scaleY(1.3);

      @media(max-width: $screen-md){
        //max-height: 5.6vw;
        line-height: 2.7vw;
      }

      @media(max-width: $screen-sm){
        //max-height: 7.6vw;
      }
    }

    .sub-info {
      display: flex;
      flex-direction: column;
      padding: 5px 7px;
      height: 100%;
      font-size: 0.7vw;
      font-weight: bold;
      margin-bottom: 0px;

      &__date-time{
        //margin: auto 0 0 0;

      }

      .title5 {
        font-weight: 200;
        letter-spacing: 0.09em;
        line-height: 1.84em;
        text-transform: uppercase;
        transform: scaleY(1.3);
      }

      .venue {
        margin: 0;
      }

      @media (max-width: 650px) {
        .event-info-title {
          font-size: 4.5vw;
          line-height: 4.5vw;
          margin: 1.2vw 0 2.6vw 0;
          overflow-x: visible;
        }

        .title5 {
          font-size: 3.5vw;
        }
      }
    }
  }

  @media (max-width: 350px) {

  }
}

.album-row {
  display: flex;
  overflow: auto;
  padding-left: 20px;

  @media (min-width: $screen-md-min) {
    padding-left: 40px;
  }

  .event-display {
    margin: 5px;
  }
}

.event-info,
.album-row {
  @include links1;
  margin-top: 10px;
  margin-bottom: 15px;

  &.top-border {
    padding-bottom: 30px;
    padding-top: 5px;
    border-top: $underline-height solid $white-transparent;
  }

  &.calendar {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 10px;
    @media (min-width: 1024px) {
      display: none;
    }
  }
}

.calendar {
  .event-info-title {
  }
}

.margin-bottom {
  margin-bottom: 6px;
}

.event-info-title {
  @media (min-width: 1366px) {
    width: 9.85vw;
  }

  @media (max-width: 1365px) and (min-width: 1024px) {
    width: 14.7vw;
  }

  @media (max-width: 1023px) and (min-width: 961px) {
    width: 14.7vw;
  }

  @media (max-width: $screen-sm-max) {
    width: 40.7vw;
  }
}

@mixin event-card($event-card-width, $event-card-height) {
  min-height: $event-card-height;
  position: relative;

  .event-picture-container {
    $picture-border-radius: 10px;
    border-radius: $picture-border-radius;
  }

  .event-picture-container,
  .event-picture-container-dashboard {
    position: relative;

    overflow: hidden;

    .event-picture {
      @include cross-browser-grayscale;
      height: $event-card-height;
      width: $event-card-width;
      position: relative;

      &.featured {
        height: 21.71vw;
        width: 21.85vw;
      }

      img {
        width: 100%;
      }
    }

    .icon-overlay {
      @include icon-overlay;
    }
  }

  &:hover {
    .event-picture {
      filter: $sepia-filter;
    }

    .icon-overlay {
      opacity: 1;
    }
  }

  .event-dates {
    position: absolute;

    left: 0px;
    bottom: 2px;

    z-index: 999;

    &.links1 {
      @media (max-width: $screen-sm-max) {
        font-size: 12px;
      }

      line-height: normal;
      z-index: 0;

      &.live {
        position: absolute;
        z-index: 99;
      }

    }

    > span {
      color: $white-transparent;
      background-color: black;
      padding: 2px 10px;

      &.accent-box {
        background-color: $accent-color;
        color: $white-transparent;
      }
    }
  }
}

@mixin error-image($error-image) {
  .event-picture-container,
  .event-picture-container-dashboard {
    .event-picture {
      img {
        &:before {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-image: $error-image;
          background-size: cover;
        }
      }
    }
  }
}

.event-card {
  @media (min-width: 1366px) {
    @include event-card($live-card-width-large, $live-card-width-large * 0.6);
  }

  @media (max-width: 1365px) and (min-width: 1024px) {
    @include event-card($live-card-width-tablet, $live-card-width-tablet * 0.6);
  }

  @media (max-width: 1023px) and (min-width: 961px) {
    @include event-card($live-card-width-tablet, $live-card-width-tablet * 0.6);
  }

  @media (max-width: $screen-sm-max) {
    @include event-card($live-card-width-mobile, $live-card-width-mobile * 0.6);
  }

  &.square {
    @media (min-width: 1366px) {
      @include event-card($live-card-width-large, $live-card-width-large);
    }

    @media (max-width: 1365px) and (min-width: 1024px) {
      @include event-card($live-card-width-tablet, $live-card-width-tablet);
    }

    @media (max-width: 1023px) and (min-width: 961px) {
      @include event-card($live-card-width-tablet, $live-card-width-tablet);
    }

    @media (max-width: $screen-sm-max)  and (min-width: 651px) {
      @include event-card($live-card-width-tablet, $live-card-width-tablet);
    }

    @media (max-width: 650px) {
      @include event-card($regular-card-width-tablet, $regular-card-width-tablet);
    }
  }
  @include error-image(url("../image/no-event-image-placeholder.jpg"));
}

.owl-carousel {

  &.highlights-carousel, &.recently-carousel, &.popular-carousel {
    .event-card, .event-card-secondary {
      .event-picture-container {
        border-radius: 0;
      }
    }
  }

}


.event-row .event-display-today-and-tomorrow {
  height: calc(36.7vw * 0.64 - 60px);
  @media (max-width: 1300px) {
    height: calc(36.7vw * 0.64 - 30px);
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    height: calc(67vw * 0.64 - 100px);

    //a .event-card .event-picture-container .picture-border .event-picture {
      //height: calc(67vw * 0.6 - 60px);
  //  }
  }

  @media (max-width: $screen-sm) {
    height: calc(67vw * 0.64 - 100px);
  }
  //@media (min-width: 768px) and (max-width: 960px) {
  //  height: 350px;
  //  a .event-card .event-picture-container .picture-border .event-picture {
  //    height: 350px;
  //  }
  //}

  @media (max-width: 650px) {
    height: calc(90vw * 0.64 - 60px);
    min-height: 200px;
//    a .event-card .event-picture-container .picture-border .event-picture {
//      height: calc(90vw * 0.6 - 25px);
//    }
  }
}

.event-picture {
  &.square {
    height: 9.06vw;
    width: 9.06vw;
  }
}

.event-card-secondary {
  @media (min-width: 1366px) {
    @include event-card(
      $regular-card-width-large,
      $regular-card-width-large * 0.6
    );
  }

  @media (max-width: 1365px) and (min-width: 1024px) {
    @include event-card(
      $regular-card-width-medium,
      $regular-card-width-medium * 0.6
    );
  }

  @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
    @include event-card(
      $regular-card-width-medium,
      $regular-card-width-medium * 0.6
    );
  }

  @media (max-width: $screen-sm-max) {
    @include event-card(
      $regular-card-width-mobile,
      $regular-card-width-mobile * 0.6
    );
  }

  @include error-image(url("../image/no-event-image-placeholder.jpg"));
}

.event-card-square {
  @media (min-width: 1366px) {
    @include event-card($regular-card-width-large, $regular-card-width-large);
  }

  @media (max-width: 1365px) and (min-width: 1024px) {
    @include event-card($regular-card-width-medium, $regular-card-width-medium);
  }

  @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
    @include event-card($regular-card-width-medium, $regular-card-width-medium);
  }

  @media (max-width: $screen-sm-max) {
    @include event-card(21.1vw, 21.1vw);
  }

  @media (max-width: 650px) {
    @include event-card($regular-card-width-mobile, $regular-card-width-mobile);
  }
  @include error-image(url("../image/no-artist-photo-thumbnail.jpg"));
}

.event-card-small {
  @include event-card($mobile-event-card-width, $event-card-height / 1.5);

  .event-picture-container {
    border-radius: 0;
  }

  @include error-image(url("../image/no-event-image-placeholder.jpg"));
}

//TODO Move
.album-row {
  .event-display {
    max-width: 100%;

    .event-card {
      @include event-card(300px, 300px);
      @include error-image(url("../image/no-event-image-placeholder.jpg"));

      .event-picture-container {
        .event-picture {
          filter: none;
        }
      }
    }
  }
}

.newest-recordings-container
  .event-row
  .event-display
  .event-card
  .event-picture-container
  .event-picture {
  filter: none;
}

@media (max-width: 960px) {
  .event-card,
  .event-info,
  .album-row {
    font-size: 2.4vw;
    position: relative;
  }
}

.artists {
  .event-info.new {
    left: 12px;
    top: 12px;
    height: 65%;
  }
}

.event-info.new {
  color: white;
  font-size: 12px;
  height: 60%;
  left: 15px;
  margin: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 15px;

  @media (max-width: 960px) {
    font-size: 13px;
  }

  @media (max-width: 650px) {
    font-size: 16px;
  }

  @media (max-width: 530px) {
    font-size: 13px;
  }

  @media (max-width: 420px) {
    font-size: 11px;
  }
}

@media (max-width: $screen-sm-max) {
  .event-display {
    position: relative;
  }
}

.img-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.today-and-tomorrow-arrow {
  height: calc(100%) !important;
  border-radius: 0 !important;
}

.new-archive {
  .event-display {
    cursor: pointer;
    float: left;
    vertical-align: top;

    @media (max-width: $screen-sm-max) {
      margin-left: 1vw;
      margin-right: 1vw;
      margin-bottom: 1vw;
    }
  }
}

.wrapped-events {
  @media (max-width: $screen-sm-max) {
    clear: both;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0 !important;
  }
}

.draft-label-most-popular {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  font-family: "Reem Kufi", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 1.25em;
  text-transform: uppercase;
  text-shadow: 0.001em 0.001em;
  margin-bottom: 0;
}
