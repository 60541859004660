.search-event {
  padding-bottom: 19px;
  border-bottom: 1px #DDDDDD solid;
  padding-top: 20px;

  &:last-child {
    border-bottom: 0;
  }

  &__date {
    @include lato-bold;
    font-size: 14px;
    color: $main-accent-color;
    margin-bottom: 3px;
  }

  &__title {
    @include lato-black;
    font-size: 22px;
    color: #131313;
    margin-top: 0;
    margin-bottom: 2px;
  }

  &__venue {
    @include lato-black;
    font-size: 15px;
    color: #777777;
    margin-top: 0;
    margin-bottom: 2px;
  }

  &__sidemen-section-title, &__features-section-title {
    @include lato-bold;
    margin-top: 8px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #777777;
  }

  &__sidemen {
    @include lato-bold;
    font-size: 12px;
    color: #131313;

    & a {
      color: #131313;
    }
  }

  &__sidemen-instrument {
    @include lato;
    color: #777777;
  }

  &__features {
    @include lato;
    font-size: 12px;
    color: #777777;

    .fa {
      font-size: 18px;
      margin-left: 10px;
      margin-right: 5px;
      vertical-align: middle;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.search-pagination-info {
  @include lato-bold;
  font-size: 12px;
  color: #777777;
  text-align: center;
}

.search-pagination {
  text-align: center;
  margin-bottom: 30px;
}
