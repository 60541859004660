.education{
  &__article{
    font-size: 24px;
    font-family: 'Reem Kufi', sans-serif;
    font-weight: 400;
    letter-spacing: 0.04em;

    @media(max-width: $screen-md){
      font-size: 2.5vw;
    }
    @media(max-width: $screen-sm){
      font-size: 18px;
    }

    p{
      margin-bottom: 40px;

      @media(max-width: $screen-md){
        padding: 0 15px;
      }
    }

    .logo{
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
      width: 80%;
      padding: 15px;

      @media(max-width: $screen-md-max){
        width: 100%;
      }
    }

    &__quote{
      margin: 0 5vw;
    }

    &__picture{
      width: 20vw;
      float: left;
      margin: 0 40px 0 0;
      @media(max-width: $screen-md-max){
        width: 25vw;
      }
      @media(max-width: $screen-sm){
        width: 35vw;
      }
      @media(max-width: $screen-xs){
        width: 100%;
        margin: unset;
      }

      img{
        width: 100%;
        margin: 0 0 0 15px;
        @media(max-width: $screen-xs){
          margin: unset;
          padding: 0 0 15px 0;
        }

      }
    }
  }
}
