.newsletters {

  &__subscribe {
    margin-top: 30px;
    margin-bottom: 50px;

    &__text {
      @include lato-black;
      font-size: 18px;
      color: #545454;
      margin-bottom: 10px !important;
      padding-bottom: 0 !important;
    }

    &__input {
      @include border-left-radius(4px);
      border: 1px solid #DDDDDD;
      font-size: 14px;
      color: #333;
      height: 40px;
      padding-left: 15px;
      width: 70%;
      vertical-align: middle;
      float: left;
    }

    &__button {
      @include transition(all 0.2s ease-in-out);
      @include lato-black;
      @extend .btn;
      border: 1px solid #555;
      font-weight: bold;
      color: #fff;
      background-color: #555555;
      height: 40px;
      @include border-left-radius(0);
      //margin-left: -5px;
      margin-right: 10px;
      float: left;
      width: 28%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
      color: #fefefe;
      background-color: $main-accent-color;
      border-color: $main-accent-color;
    }
    }
  }

  &__year {
    margin-bottom: 20px;
    @include display(flex);
    @include flex-direction(row);
    @include flex-wrap(wrap);

    &__heading {
      @include lato-black;
      font-size: 24px;
      color: #A2A1A1;
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 20px !important;
      padding-bottom: 6px !important;
    }
  }
}

.newsletter {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px !important;
  margin-top: 30px;
  padding-bottom: 0 !important;
  align-items: flex-end;

  @media(max-width: 960px){
    display: block;
  }

  p {
    padding-bottom: 5px;
    margin-right: 10px;
  }

  input {
    &.white-border-button {

      height: 44px;
      margin-left: 10px;

    }
  }

  &__title {
    @include lato-bold;
    font-size: 18px;
    margin-bottom: 2px;
  }

  &__date {
    @include lato-bold;
    font-size: 14px;
    color: $main-accent-color;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }

  &__excerpt {
    @include lato;
    font-size: 14px;
    line-height: 120%;
    text-align: left;
    height: 112px;
    text-overflow: ellipsis;
    overflow: hidden;

    &.unavailable {
      font-style: italic;
      opacity: 0.5;
    }
  }

  &__separator {
    background-color: #ddd;
    height: 1px;
    margin: 10px 0 12px;
  }

  &__view-button {
    @include red-button;
  }
}

@media (max-width: 760px) {
  .newsletter {
    &__excerpt {
      text-align: left;
    }
  }
}
