.modal {
  top: 10%;
  max-height: 100%;
  overflow: scroll;
}

#login-modal{
  .title1{
    font-weight: 700;
  }
}
.modal-backdrop {
  height: 100%;
  opacity: 0.65 !important;
}

.custom-modal .modal-body {
  overflow: hidden;
}

@mixin form-styles {
  .form-group {
    width: 100%;

    label, .login-agreement {
      @include text4;
      color: $text-grey;
    }
    a.login-agreement{
      &:focus{
        text-decoration: none;
      }
      &:hover{
        color:black;
      }
    }
    input[type="text"], input[type="password"], input[type="email"] {
      @include bordered-input;
      @include place-holder-color($black);
      background-color: $white-transparent;
      border-radius: 0;
    }

    input[type="checkbox"] {
      margin: 1px 0 0 -20px;
    }
  }

  .login-button {
    @include white-border-button;
    @include input-border;

    margin-top: 30px;
    padding: 0 40px;
  }
}

.custom-form {
  @include form-styles;
}

.custom-modal {
  border: 8px solid $white-transparent;
  border-radius: 0;
  background-color: $background-color;
  background-clip: unset;

  padding: 35px;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    content {
      align-items: flex-start;
    }

    form {
      @include form-styles;
      width: 70%;

      margin-top: 10px;
      flex-direction: column;
      justify-content: flex-start;
    }

    .title1 {
      margin-bottom: 40px;
    }

    .title3 {
      text-align: center;
    }
  }
}

.form-button {
  @include white-border-button;
  @include input-border;

  margin-top: 40px;
  padding: 0 40px;
}

.modal-separator {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  margin: 30px 0;
  background-color: white;
}

.empty-separator-20 {
  width: 100%;
  height: 20px;
  float: left;
}

.modal-buttons {
  margin-top: 20px;
}

.modal-dialog .close {
  background: none;
  border: 0;
  font-size: 40px;
  position: absolute;
  top: 10px;
  right: 20px;
}

@media (max-width: 750px) {
  .custom-modal {
    padding: 30px 10px;
  }

  .fb-login-button {
    margin-top: 10px;
  }

  .modal-dialog .close {
  right: 0px;
}
}
