.homepage-upcoming {

  .slick-slider {
    margin-bottom: 0;
  }

  &__event {
    min-height: 400px;
    @include display(flex);
    height: auto;
    position: relative;
    background-color: #1B191A;

    &__image-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 50%;
      z-index: 1;
      background-size: cover;
      background-position: center center;
      @include cross-browser-grayscale;

      @media(max-width: $screen-sm-max) {
        right: 0;
        @include filter(blur(5px));
      }
    }

    &__gradient {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 50%;
      width: 60px;
      z-index: 2;
      @include linear-gradient(to right, rgba(#1B191A, 0), rgba(#1B191A, 1));

      @media(max-width: $screen-sm-max) {
        display: none;
      }
    }

    &__info {
      margin-left: 50%;
      @include display(flex);
      @include flex-wrap(wrap);
      position: relative;
      z-index: 3;
      background-color: #1B191A;
      padding: 40px;

      @media(max-width: $screen-sm-max) {
        margin-left: 0;
        background-color: rgba(#1B191A, 0.85);
      }
    }

    .event-title {
      @include lato-black;
      font-size: 42px;
      color: $main-accent-color;
      text-transform: uppercase;
      margin: 0 0 15px;

      @media(max-width: $screen-xs-max) {
        font-size: 32px;
      }
    }

    .event-venue {
      @include lato-black;
      font-size: 22px;
      color: rgba(#fff, 0.7);

      @media(max-width: $screen-xs-max) {
        font-size: 32px;
      }
    }

    .event-timeslot {
      @include lato-black;
      color: #fff;
      font-size: 18px;
      margin: 0 0 20px;
      padding: 0 0 20px;
      border-bottom: 2px solid rgba(#fff, 0.5);
    }

    .event-artists {
      @include lato-black;
      display: block;
      width: 100%;
      font-size: 16px;
      background: none;
      color: #fff;
      line-height: 160%;
      padding: 0;
      margin-bottom: 30px;

      a {
        color: #fff;
        text-transform: uppercase;
        display: inline-block;

        &:hover, &:focus {
          opacity: 0.8;
          color: #fff;
        }
      }
    }

    .event-artists-divider {
      display: inline-block;
      margin: 0 8px;
    }

    .event-artist-instrument {
      @include lato-bold;
      text-transform: none;
    }

    .event-cta-button {
      @include lato-bold;
      color: white;
      border: 0;
      background-color: #D21535;
      display: block;
      margin: 0 auto;
      float: left;
      font-size: 14px;
      line-height: 20px;
      padding: 4px 10px 4px 7px;
      margin-bottom: 30px;
    }
  }

  &__footer {
    background-color: $main-accent-color;
    @include display(flex);
    @include align-items(center);
    padding: 20px 0;
    position: relative;

    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(210, 21, 253, 0);
      border-bottom-color: $main-accent-color;
      border-width: 20px;
      margin-left: -20px;
      z-index: 10001;
    }

    h2 {
      font-size: 24px;
      color: #fff;
      @include lato-black;
      margin: 0 30px;
    }

    &__timeslots {
      font-size: 14px;
      @include lato-black;
      padding: 0;
      margin: 8px 15px 0;
    }

    li.timeslot {
      color: rgba(#fff, 0.7);
      margin: 10px 15px;
      @include transition(all 0.3s ease-in-out);
      cursor: pointer;
      list-style-type: none;
      display: inline-block;

      &:hover, &:focus {
        color: #fff;
      }

      &.active {
        color: #fff;
      }
    }
  }
}
