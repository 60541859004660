.admin-interface {
  /* background-color: #FFFFFF; */
  padding: 30px 20px 40px;

  &__group-separator {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 30px 0;
  }

  & a {
    @include lato-bold;
    color: $main-accent-color;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  &__main-title {
    @include lato-black;
    font-size: 32px;
    color: black;
    line-height: 130%;
    margin-bottom: 20px;
  }

  &__form {

    textarea {
      width: 100%;
    }

    #div_id_photo {
      a {
        display: none;
      }
    }

    &__heading {
      @include lato-black;
      font-size: 18px;
      color: #131313;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    & label {
      @include lato;
      font-size: 14px;

      & .required {
        @include lato-bold;
        font-size: 12px;
        color: $main-accent-color;
      }

      &.white-border-button {
        width: 150px;
        input {
          position: absolute;
          top: -1000px;
        }

        &.photo {
          width: auto;
        }
      }

      #div_id_cropping {
        label {
          display: none;
        }
      }

    }

    & .form-control,
    & .selectize-input {
      @include lato;
      font-size: 16px;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 0;

      &.focus {
        border-radius: 0;
        outline: none;
        border-color: $main-accent-color;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &.textarea {
        min-height: 200px;
      }
    }

    & input[type="checkbox"] {
      float: left;
      clear: left;
      display: block;
    }

    & .checkbox-label {
      @include lato;
      font-size: 14px;
      display: block;
      margin-bottom: 10px;
    }

    & .btn {
      @include lato;
      background-color: #ccc;
      border-radius: 0;
      border: none;
      color: #6f6f6f;
      padding: 10px 20px;
      font-size: 14px;
      margin-right: 10px;
      margin-top: 10px;
    }

    &__button {
      @include lato-bold;
      border: 0;
      font-size: 16px;
      padding: 13px 60px;
      background-color: $main-accent-color;
      color: #fff;
      text-decoration: none;
      margin: 30px 10px 10px 0;
      display: block;

      &.danger {
        color: $main-accent-color;
        background-color: #ccc;
      }

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }

    & .in-form-button {
      padding: 10px 20px;
      color: #fff;
      background-color: $main-accent-color;
      border-radius: 5px;
      @include transition(all 0.3s ease-in-out);

      &:hover,
      &:focus {
        padding: 10px 30px;
      }
    }

    & .inline-field-button-group {
      @include display(flex);
      @include align-items(center);

      .in-form-button {
        margin-left: 10px;
      }
    }

    & .table {
      margin: 30px 0;
      border: none;
      background: none;
      @include lato;

      & thead {
        @include lato-bold;
        color: #4a4a4a;
        background-color: #eeeeee;
        font-size: 12px;
        padding: 10px;
        border-bottom: 1px solid #dddddd;
      }

      & tbody {
        border: none;
      }

      & tr {
        vertical-align: middle;
        background-color: rgba(240, 240, 235, 0.9);
        border-bottom: 1px solid #dddddd;
      }

      & td {
        vertical-align: middle;
      }

      & .form-control,
      & .selectize-input {
        margin: 0;
        @include lato;
      }

      & .fa,
      & .artist-remove {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 20px;
      }

      & .fa-arrows {
        color: #c1c1c1;
      }

      & input[type="checkbox"] {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-top: -2px;
      }

      & th.col-md-1 {
        text-align: center;
      }
    }
  }

  & .artists-table__button,
  & .event-sets-table__button {
    background-color: $main-accent-color;
    color: #fff;
    margin-top: -10px;
    margin-bottom: 20px;
    padding: 10px 40px;

    & i {
      margin-right: 5px;
    }

    &.darker {
      background: $main-accent-color-darker;
    }
  }

  & .input[type="file"] {
    padding: 10px;
  }

  & #div_id_state {
    margin-top: 30px;

    & label {
      font-size: 14px;
      @include lato;

      &.txt-warning {
        color: #dc9231;
      }

      &.txt-success {
        color: #5fa051;
      }

      &.txt-alert {
        color: $main-accent-color;
        @include lato-bold;
      }

      &.txt-hidden {
        color: #888;
      }
    }
  }

  .event-set-list-form {

    table {

      tbody {

        tr {
          display: table-row;
        }
      }
    }
  }
}

.bootstrap-datetimepicker-widget {
  min-width: 300px;
}

#hint_id_cropping {
  display: none;
}