@mixin cross-browser-grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}
@mixin cross-browser-nofilter {
  filter: none;
  -webkit-filter: none;
}

@mixin bottom-section-border {
  border-bottom: 8px $main-accent-color solid;
}

@mixin section-title {
  @include lato-black;
  color: rgba(69, 67, 68, 0.5);
  font-size: 24px;
}

@mixin animated {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@mixin red-button {
  @include lato-bold-italic;
  color: white;
  border: 0;
  background-color: $main-accent-color;
  display: block;
  margin: 0 auto;
  float: left;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 10px 4px 7px;

  &:hover {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    outline: none;
  }
}
@mixin black-button {
  @include red-button;
  background-color: $black;
}

@mixin black-button {
  @include red-button;
  background-color: $black;
}

@mixin flexbox-vertical-center {
  @include display(flex);
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-content: center;
  @include flex-wrap(wrap);
}

@mixin place-holder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin background-image-with-overlay($overlay-color) {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;
  img {
    width: 100%;
  }

  &:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $overlay-color;
  }
}

@mixin caret() {
  cursor: pointer;
  position: relative;

  &:after {
    content: " ";
    height: 0;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    position: absolute;
    right: 16px;
    top: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    pointer-events: inherit;
    z-index: -1;
  }
}

// Events
@mixin column_events() {
  padding-top: 0;

  .shows-container {
    flex-direction: column;
    padding-left: 0;

    .event-display {
      display: flex;
      padding: 16px 0;
      justify-content: center;
      padding-left: 12px;
      border-bottom: solid 2px white;
      // background-image: url("/static/image/arrow-right-red.svg");
      // background-repeat: no-repeat;
      // background-position: 95% 50%;
      // background-size: 16px;
      width: 100%;

      .event-card-secondary {
        min-height: 0;
        margin: 0px;

        .event-picture-container {
          border-radius: 0;

          .event-picture {
            height: 20vw;
            width: 20vw;
          }

          .event-picture > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .event-info {
        div > * {
          margin-top: 8px;
        }

        div > *:first-child {
          margin-top: 0;
        }

        .event-info-title {
          width: auto;
          max-width: calc(85vw - 110px);
        }
      }
    }
  }

  .event-stripe {
    margin-top: 70px;
  }
}

@mixin icon-overlay {
  @include animated;
  color: $accent-color;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  font-size: 1.8vw;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $screen-md-max) {
    font-size: 3vw;
  }

  .fa-thin-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    $circle-size: 50px;

    height: $circle-size;
    width: $circle-size;

    border-radius: $circle-size / 2;
    border: 1px solid $accent-color;

    &:before {
      left: 3px;
      position: relative;
    }
  }
}
