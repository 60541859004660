.live-stream {
  background-color: #000000;

  &__header {
    background-color: $main-accent-color;
    color: white;
    padding: 0 20px 8px;

    p {
      @include lato-black;
      font-size: 24px;
      padding: 13px 0;
      margin: 0;

      @media(max-width:$screen-xs-max) {
        font-size: 18px;
      }
    }
  }

  &__player {
    margin: 0 auto;
    width: 720px;
    display: block;
  }
}

.live-stream-info {
  background-color: white;

  &__about-mezzrow {
    padding: 20px 0;

    p {
      margin: 0 0 10px;
    }

    .website-button {
      margin-top: 20px;
    }
  }
}

.live-stream-current {
  padding: 30px 20px;

  @media(max-width:$screen-xs-max) {
    padding-top: 25px;
    padding-bottom: 15px;
  }

  &__title {
    @include lato-black;
    font-size: 32px;
    text-transform: uppercase;
    color: $main-accent-color;
    margin-bottom: 5px;

    & a {
      color: $main-accent-color;

      &:hover {
        color: $main-accent-color;
        opacity: 0.7;
        text-decoration: none;
      }
    }
  }

  &__date {
    @include lato-bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__sidemen {
    @include lato-black;
    font-size: 14px;
    color: #131313;
    line-height: 160%;
    padding-right: 50px;
    letter-spacing: 0.04em;
    margin-bottom: 18px;

    & a {
      color: #131313;

      &:hover {
        opacity: 0.7;
        text-decoration: none;
      }
    }
  }

  &__instrument {
    @include lato;
    font-size: 14px;
  }

  &__button {
    @include lato-bold-italic;
    color: #FEFEFE;
    border: 0;
    background-color: $main-accent-color;
    display: block;
    float: left;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 10px 4px 7px;
    margin-bottom: 20px;

    &:hover {
      color: rgba(255,255,255,0.7);
      text-decoration: none;
    }
  }
}

.coming-up {
  background-color: #F7F7F7;
  border-left: 1px #DDDDDD solid;
  padding: 32px 20px 40px;
  min-height: 250px;

  @media(max-width:$screen-xs-max) {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
  }

  &__no-events {
    @include lato;
    opacity: 0.7;
    font-style: italic;
    margin-top: 20px;
  }
}


#jwplayer-placeholder_wrapper {
    margin: 0 10%;
  }

.live-stream__android {
  padding: 40px 20px;
  background-color: $main-accent-color;

  &--mezzrow {
    background-color: $mezzrow-color;
  }

  &__cta {
    color: #fff;
    font-size: 16px;
    padding: 20px 30px;
    display: block;
    text-align: center;
    border: 2px solid rgba(#fff, 0.8);
    @include lato-bold;

    &:hover, &:focus {
      color: rgba(#fff, 0.7);
      border-color: rgba(#fff, 0.5);
    }

    i {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.live-stream-header {
  background-color: $main-accent-color;
  padding: 15px 20px 15px;;
  @include display(flex);
  @include align-items(center);
  @include justify-content(space-between);
  color: #fff;
  margin-top: -8px;
  @include transition(all 0.3s ease-in-out);

  &:before, &:after {
    display: none;
  }

  &__upsell {
    font-size: 14px;
    max-width: 50%;

    a {
      color: #fff;
      @include lato-bold;

      &:hover, &:focus {
        color: rgba(#fff, 0.7);
      }
    }
  }

  &.mezzrow {
    background-color: $mezzrow-color;
  }

  &__switch {
    font-size: 18px;
    color: rgba(#fff, 0.7);

    span {
      margin-right: 10px;
    }

    &__options {
      margin: 0;
      padding: 0;
      display: inline-block;
    }

    &__option {
      display: inline-block;
      list-style-type: none;
      padding: 5px 0;
      margin: 0 10px;
      @include lato-bold;
      cursor: pointer;

      a {
        color: rgba(#fff, 0.7);

        &:hover, &:focus {
          color: #fff;
        }
      }

      &:hover, &:focus {
        color: #fff;
      }

      &.active {
        border-bottom: 2px solid #fff;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
  }
}

#live-stream--mezzrow {
  .cto, .cto__button {
    border-color: $mezzrow-color;
  }

  .live-stream__header, .mini-event-info__button, .live-stream-current__button {
    background-color: $mezzrow-color;
  }

  .arrow {
    border-left-color: $mezzrow-color;
  }
}
