.white-border-select {
  @include button1;
  position: relative;
  color: $black;

  select {
    display: none;
  }

  &:not(.no-arrow) {

    .select-selected {

      &::after {
        content: ' ';
        height: 0;
        width: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--color-black);
        position: absolute;
        right: 10%;
        transition: all 0.3s linear;
        cursor: pointer;
        pointer-events: inherit;
        z-index: 1;
      }
    }
  }

  &.white-border-multiline {

    .select-items div,
    .select-selected {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &.white-border-select-left {

    .select-items div,
    .select-selected {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
    }
  }

  &.club-filter-select {
    width: 120px;
  }

  &.leader-filter-select {
    @media(min-width: $screen-sm){
      min-width: 180px;
    }
  }
}

.select-selected {
  padding-left: -20px;
  background-color: transparent;
  border: 2px solid $white-transparent;

  &:hover {
    background-color: $white-transparent;
  }
}

/*style the arrow inside the select element:*/
/*point the arrow upwards when the select box is open (active):*/
/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: var(--color-black);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 1em 3em 1em 1em;

  &:not(.no-arrow) {
    padding: 1em;
    padding-right: 1.7em;
  }
}

/*style items (options):*/
.select-items {
  position: absolute;
  border: 2px solid $white-transparent;
  max-height: 225px;
  overflow: auto;
  background-color: $background-color;
  top: 77%;
  left: 0;
  right: 0;
  /*below recently added \ most popular title on home page at index 99*/
  z-index: 100;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: $white-transparent;
}
