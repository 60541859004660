.mini-video {
  margin-bottom: 20px;

  &__image {
    display: table-cell;

    img {
      padding: 2px;
      border: 1px #ccc solid;
      width: 86px;
      height: 86px;
    }
  }
}

.mini-video-info {
  display: table-cell;
  vertical-align: top;
  padding-left: 19px;

  &__date {
    @include lato;
    font-size: 14px;
    color: #131313;
    margin-bottom: 5px;
  }

  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
    margin-top: 0;
    margin-bottom: 6px;

    @media(max-width:$screen-xs-max) {
      font-size: 16px;
    }

    & a {
      color: #131313;

      &:hover, &:focus {
        opacity: 0.8;
      }
    }
  }

  &__stats {
    @include lato;
    font-size: 12px;
    color: #777777;
    float: left;
    margin-right: 5px;

    & p {
      margin-bottom: 0;
    }

    & span {
      @include lato-bold;
    }
  }
}

@media (min-width: 992px) {
  .mini-video-info__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 345px;
    height: 19px;
  }
}

@media (min-width: 1200px) {
  .mini-video-info__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 430px;
    height: 19px;
  }
}