.search-instrument {
  padding-bottom: 19px;
  border-bottom: 1px #DDDDDD solid;
  padding-top: 20px;

  &:last-child {
    border-bottom: 0;
  }

  &__title {
    @include lato-black;
    font-size: 22px;
    color: #131313;
    margin-top: 0;
    margin-bottom: 2px;
  }

  &__link {
    @include lato-bold;
    font-size: 14px;
    color: $main-accent-color;

    & a {
      color: $main-accent-color;
      text-decoration: none;

      &:hover {
        color: $main-accent-color-darker;
      }
    }
  }
}

.search-pagination-info {
  @include lato-bold;
  font-size: 12px;
  color: #777777;
  text-align: center;
}

.search-pagination {
  text-align: center;
  margin-bottom: 30px;
}