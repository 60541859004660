.press {

  &__section {
    margin: 20px 0;
    @include display(flex);
    @include flex-direction(row);
    @include flex-wrap(wrap);

    &__heading {
      @include lato-black;
      font-size: 24px;
      color: #A2A1A1;
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 20px !important;
      padding-bottom: 6px !important;
    }
  }

  &__file {
    margin-bottom: 30px !important;
    padding-bottom: 0 !important;

    &__photo {
      clear: both;
      border: 1px solid #ddd;
      padding: 0 !important;
      margin-bottom: 10px !important;

      & img {
        width: 100%;
        height: auto;
      }
    }

    &__title {
      width: 100%;
      float: left;
      @include lato-bold;
      font-size: 18px;
      margin-bottom: 4px;
    }

    &__meta {
      width: 100%;
      float: left;
      @include lato;
      font-size: 14px;
      color: #777;
      margin-bottom: 8px;
    }

    &__download-button {
      @include red-button;
    }
  }
}
