.search-sidebar {
  background-color: #F7F7F7;
  border-right: 1px #DDDDDD solid;
  padding: 20px;
}

.search-nav {
  list-style: none;
  padding-left: 0;
  
  &__item {
    @include lato-black;
    font-size: 18px;
    color: rgba(19, 19, 19, 0.5);
    border-bottom: 1px #DDDDDD solid;
    padding: 20px 0;

    & a {
      color: rgba(19, 19, 19, 0.5);
      text-decoration: none;

      &:hover {
        color: #131313;
        text-decoration: none;

        .badge {
          background-color: #131313;
        }
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    .badge {
      vertical-align: middle;
      margin-top: -5px;
      margin-left: 2px;
    }
  }
}

.search-pagination {
  @include lato-bold;

  &__previous, &__next {
    font-size: 20px;
    vertical-align: middle;
    color: #131313;
  }

  &__previous {
    margin-right: 5px;
  }

  &__next {
    margin-left: 5px;
  }

  &__page {
    margin: 0 2px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    color: #131313;

    &.active {
      min-width: 10px;
      padding: 3px 7px;
      color: #fff;
      white-space: nowrap;
      background-color: $main-accent-color;
      border-radius: 10px;
    }
  }
}