.event-object {
  @include center-block;
  color: white;
  position: relative;
  margin-bottom: 30px;
  border-bottom: 8px solid;
  overflow: hidden;
  cursor: pointer;
  background-color: #000;
  height: 300px;

  @media (max-width:$screen-xs-max) {
    background-color: transparent;
    min-height: 0;
    border-bottom: none;
    margin-bottom: 20px;
  }

  &__image {
    @include img-responsive;
    width: 100%;
    opacity: 0.6;
    @include transition(opacity 0.2s ease-in-out);
    @include filter(grayscale(100%));

    @media (max-width:$screen-xs-max) {
      opacity: 1;
      border-bottom: 8px solid;
      @include filter(grayscale(0));
    }
  }

  &:hover &__image {

    @media (min-width:$screen-xs-max) {
      opacity: 0.3;
      @include filter(grayscale(0%) blur(3px));
    }
  }

  &__info {
    position: absolute;
    bottom: 20px;
    left: 18px;
    width: 65%;

    @media (max-width:$screen-xs-max) {
      position: relative;
      width: 100%;
      bottom: 0;
      left: 0;
      margin-top: 0;
    }
  }

  &__title {
    @include lato-black;
    font-size: 24px;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width:$screen-xs-max) {
      font-size: 16px;
      text-transform: none;
      color: $base-text-color;
      @include lato-bold;
      margin-top: 10px;
    }
  }

  &__date {
    @include lato-bold;
    font-size: 18px;
    margin: -5px 0 5px;

    @media (max-width:$screen-xs-max) {
      font-size: 14px;
      color: #777;
      margin: 0;
    }
  }

  &__artists {
    @include lato;
    @include list-unstyled;
    @include transition-property(max-height);
    @include transition-duration(0.1s);
    @include transition-timing-function(ease-out);
    @include transition-delay(0.05s);
    color: white;
    max-height: 0;
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 10px;

    @media (max-width:$screen-xs-max) {
      display: none;
    }
  }

  &:hover &__artists{
    @include transition-property(max-height);
    @include transition-duration(0.4s);
    @include transition-timing-function(ease);
    @include transition-delay(0.05s);
    max-height: 300px;
  }

  &--green {
    border-bottom-color: #5FA051;

    &:hover {
      color: #5FA051;
    }

    .event-object__image {
        border-bottom-color: #5FA051;
    }
  }

  &--blue {
    border-bottom-color: #68ACE3;

    &:hover {
      color: #68ACE3;
    }

    .event-object__image {
        border-bottom-color: #68ACE3;
    }
  }

  &--yellow {
    border-bottom-color: #E5DA33;

    &:hover {
      color: #E5DA33;
    }

    .event-object__image {
        border-bottom-color: #E5DA33;
    }
  }

  &--gold {
    border-bottom-color: #f39c12;

    &:hover {
      color: #f39c12;
    }

    .event-object__image {
        border-bottom-color: #f39c12;
    }
  }

  &--red {
    border-bottom-color: #e74c3c;

    &:hover {
      color: shade(#e74c3c, 20%);
    }

    .event-object__image {
        border-bottom-color: #e74c3c;
    }
  }

  &--purple {
    border-bottom-color: #9b59b6;

    &:hover {
      color: #9b59b6;
    }

    .event-object__image {
        border-bottom-color: #9b59b6;
    }
  }

  &--darkblue {
    border-bottom-color: #1abc9c;

    &:hover {
      color: shade(#1abc9c, 20%);
    }

    .event-object__image {
        border-bottom-color: #1abc9c;
    }
  }

  &--orange {
    border-bottom-color: #DC9231;

    &:hover {
      color: #DC9231;
    }

    .event-object__image {
        border-bottom-color: #DC9231;
    }
  }
}
