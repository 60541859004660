header {
  &.container {
    @include bottom-section-border;
  }
}

.smalls-logo {
  float: left;
  img {
    width: 60px;
  }
}

.navbar-default {
  background: none;
  border: none;
}

.header-nav-group {
  clear: right;
  @include display(flex);
  @include align-items(center);
  @include flex-wrap(nowrap);
}

.navigation {
  @include transition(all 0.2s ease-in-out);
  float: left;
  width: 100%;

  & .navigation__menu {
    @include display(flex);
    @include align-items(center);
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    width: 100%;
    position: relative;
    top: 15px;
  }

  &__separator {
    height: 50px;
    width: 1px;
    background-color: #3c3b3c;
  }


  &__item {
    @include lato;
    font-size: 16px;
    text-align: center;
    @include transition(all 0.3s ease-in-out);
    list-style-type: none;
    padding: 0 20px;
    @include flex-grow(0);
    min-width: 60px;

    & span {
      display: block;
      white-space: nowrap;
      @include transition(all 0.2s ease-in-out);
    }

    @media (max-width: $screen-md-max) {
      padding: 0 15px;
    }

    a {
      @include transition(all 0.2s ease-in-out);
      color: #929292;
      display: block;
      text-decoration: none;
    }

    &.nav-search {
      width: 72px;
    }

    a:hover {
      color: white;
    }

    & i {
      @include transition(all 0.2s ease-in-out);
    }

    &.active a {
      color: #fff;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
      max-width: none !important;

      & i {
        margin-top: 0 !important;
      }
    }

    &.search-active {
      max-width: 51px;

      & .menu-label {
        opacity: 0;
      }

      #mezzrow-logo-header {
        opacity: 0;
      }

      &.hide-on-search {
        display: none;
      }

      & i {
        margin-bottom: 0;
        margin-top: 24px;
      }

      &.nav-search {
        @include flex-grow(1);
      }

      .search {
        width: 100%;
        padding-right: 10px;
      }

      .search i {
        font-size: 16px;
        padding-top: 12px;
        margin-left: 7px;
      }
    }
  }

  &__icon {
    font-size: 22px;
    width: 100%;
    display: block;
    margin: 0 auto 12px;
  }
}

.header-login {
  float: right;
  margin-top: 7px;
  list-style: none;

  &__user-name {
      @include lato-bold;
      font-size: 14px;
      @include transition(all 0.2s ease-in-out);
      color: #8F8E8F;
      text-decoration: none;

      &:hover, &:active {
        color: white;
        text-decoration: none;
      }
  }

  &__user-dropdown {
      @include lato;
      font-size: 14px;
      color: white;
      background-color: rgba(0,0,0,0.8);
      border: 1px solid rgba(255,255,255,0.2);
      margin-top: 10px;
      padding: 0;

      & li a {
        padding: 15px 30px;
        color: white;

        &:hover {
          background-color: rgba(255,255,255,0.1);
          color: white;
        }
      }
  }

  &__item {
    @include lato;
    display: inline-block;
    font-size: 14px;
    border-right: 1px #3C3B3C solid;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    vertical-align: middle;
    @include transition(all 0.3s ease-in-out);

    &:last-child {
      border-right: 0;
    }

    a {
      @include transition(all 0.2s ease-in-out);
      color: #8F8E8F;
      text-decoration: none;
    }

    a:hover {
      color: white;
    }
  }

  &__upgrade {
    @include lato;
    font-size: 14px;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 20px;
    height: 25px;
    vertical-align: middle;
    @include transition(all 0.3s ease-in-out);
    
    a {
      @include transition(all 0.2s ease-in-out);
      color: #8F8E8F;
      text-decoration: none;
    }

    a:hover {
      color: white;
    }
  }
}

.header-social {
  float: right;
  margin-top: 7px;
  list-style: none;
  padding-left: 20px;

  &__item {
    @include lato;
    display: inline-block;
    font-size: 14px;
    border-right: 1px #3C3B3C solid;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    vertical-align: middle;
    color: #8F8E8F;

    &:last-child {
      border-right: 0;
    }

    & i {
      font-size: 16px;
      vertical-align: middle;
    }

    a {
      @include transition(all 0.2s ease-in-out);
      color: #8F8E8F;
      text-decoration: none;
    }

    a:hover {
      color: white;
    }
  }
}

.login-section {
  float: right;
}

.nav.menu {
  margin-left: 30px;
}

.nav.menu li {
  text-align: center;
  padding: 0 15px;

  & a {
    padding: 0;
  }
}

.search {
  width: 54px;
  margin-left: 10px;
  padding-right: 20px;
  @include transition(all 0.3s ease-in-out);

  & .form-group {
    margin-bottom: 0;
  }

  i {
    padding-top: 8px;
    margin-right: 5px;
    left: 0;
    right: inherit;
    pointer-events: none;
    color: #929292;
    font-size: 22px;
  }

  &__input {
    @include lato-italic;
    height: 40px;
    width: auto;
    font-size: 14px;
    background: rgba(255,255,255, 0);
    border: none;
    padding-left: 0;
    opacity: 0.4;
    border-color: #828181;
    @include transition(all 0.1s ease-in-out);
    @include transition(width 1s ease-in-out);
    cursor: pointer;

      &::-webkit-input-placeholder {
        color: rgba(#fff,0);
      }
      &:-moz-placeholder {
        color: rgba(#fff,0);
      }
      &::-moz-placeholder {
        color: rgba(#fff,0);
      }
      &:-ms-input-placeholder {
        color: rgba(#fff,0);
      }

    &:focus {
      background: rgba(255,255,255,0.8);
      @include transition(all 0.1s ease-in-out);
      color: #121212;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      opacity: 1;
      border-radius: 20px;
      padding-left: 40px;
      padding-right: 30px;
      width: 100%;

      &::-webkit-input-placeholder {
        color: rgba($base-text-color,0.5);
      }
      &:-moz-placeholder {
        color: rgba($base-text-color,0.5);
      }
      &::-moz-placeholder {
        color: rgba($base-text-color,0.5);
      }
      &:-ms-input-placeholder {
        color: rgba($base-text-color,0.5);
      }
    }
  }
}

.mobile-navbar {
  margin-bottom: 0;

  .navbar-nav li {
    color: #ffffff !important;
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding: 8px 0;

    &.nopadding {
      padding: 0;
    }

    & a {
      color: #fff;
      padding: 8px 30px;
      display: inline-block;

      &:hover, &:focus {
        color: rgba(#fff, 0.8);
      }
    }
  }

  .navbar-nav li {

    &:last-child {
      border-bottom: none;
    }

    &.divider {
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 0;
      margin: 15px 0;
    }

    & i {
      margin-left: 5px;
    }
  }

  &__submenu {
    padding: 0;
    background-color: $main-accent-color-darker;

    & li {
      list-style-type: none;
    }
  }

  &__user-name {
    @include lato-bold;
    margin-bottom: 10px;
  }
}

.navbar-header {
  padding: 5px 0;
  background-color: $main-accent-color;
}

.navbar-collapse {
  padding-left: 0;
  padding-right: 0;

  &.menu {
    border: none;
    background-color: $main-accent-color;
    padding-bottom: 20px;
  }

  &.search-dropdown {
    border: none;
    background-color: $main-accent-color-darker;
    padding-top: 10px;
    padding-bottom: 10px;

    & li {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background: none;
  border: none1;
}

.nav > li > a:hover, .nav > li > a:focus {
  background: none;
}

.mobile-intro {
  background-color: #1B191A;
  width: 100%;
  display: block;
}

.mobile-intro .smalls-logo {
  width: 180px;
  margin: 50px auto 20px;
  float: none;
  display: block;

  img {
    width: 100%;
    height: auto;
  }
}

.smalls-hor-logo {
  position: relative;
  top: 4px;
  height: 26px;
  display: block;
  margin: 10px;
  margin-left: 20px;
  float: left;

  img {
    height: 100%;
    width: auto;
  }
}


@media (max-width: $screen-sm-max) {
  .search {
    margin: 0;
  }
}

.mobile-nav {

  &__button.navbar-toggle {
    border: none;
    background: none;
    color: #fff;

    &:hover, &:focus {
      background: none;
    }

    &.collapsed {
      opacity: 0.75;
      color: #000;
    }

    & i {
      font-size: 20px;
    }
  }

  &__signup-button {
    padding: 8px 10px;
    border: 2px solid rgba(#fff, 0.7);
    min-width: 70%;
    background-color: #fff;
    color: $main-accent-color !important;
    @include lato-bold;
  }

  &__signin-button {
    padding: 8px 10px;
    border: 2px solid rgba(#fff, 0.7);
    min-width: 70%;
  }
}

#mezzrow-logo-header {
  height: 18px;
  position: relative;
  top: -2px;
  opacity: 0.7;
  @include transition(opacity 0.3s ease-in-out);

  &:hover, &:focus {
    opacity: 1;
  }
}
