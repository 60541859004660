.alert {
  color: rgb(0, 0, 0);
  background-color: #f0f0eb;
  border-radius: 0;
  @include lato;
  border: none;
  @include display(flex);
  @include align-items(center);
  @include transition(all 0.3s ease-in-out);
  width: 100%;
  margin: 0;
  height: auto;
  border: 8px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
  -webkit-box-shadow: 0px 0px 113px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 113px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 113px -16px rgba(0, 0, 0, 0.75);

  z-index: 999;

  &__tickets{
    box-shadow: none;
    margin-bottom: 20px;
  }


  & p {
    margin: 0;
  }

  & button.close {
    opacity: 0.5;
    @include transition(all 0.3s ease-in-out);

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

}


.messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  div {
    width: auto;

    .alert-buttons {
      a {
        margin: 0 5px;
      }
    }

  }
}
