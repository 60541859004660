.seaction-title-with-search {
    align-items: center;
    display: flex;
    gap: 30px;
    margin: 0 0 20px;

    .section-title {
        margin: 0;
        padding-right: 50px;
        width: auto;
    }

    .event-section {
        margin: 0;
    }
}

.search-container-with-tabs {

  .search-wrapper {
    display: flex;
    gap: 30px;
    justify-content: center;
    padding: 0 50px;

    > div {
      margin: 0;
    }

    .search-input-wrapper {
      position: relative;

      .search-bar-autocomplete-container {
        left: 0;
        width: 100%;
      }
    }

    .archive-datepicker {
      position: relative;

      .datepicker-container {
        display: block;
        height: auto;
        position: relative;
        top: 0;
        width: 375px;

        .custom-date-picker {

          input {
            border: 0;
            height: 34px;
          }
        }

        .datepicker-dropdown {
          border: 3px solid #fff !important;
          border-top: 0 !important;
          display: none !important;
          height: 340px;
          left: 0 !important;
          margin: 0;
          padding: 0 15% 50px;
          top: 37px !important;
          width: 100%;

          .years-dropdown {

            .years-choices {
              max-height: 235px !important;
            }
          }
        }
        .datepicker-reset {
          display: none !important;
          height: 30px;
          left: 0 !important;
          padding: 0 15%;
          top: 340px;
          width: 100%;
          z-index: 111;
        }

        &.active {

          .active {

            + .datepicker-dropdown {
              display: block !important;
            }
          }

          .datepicker-reset{
            display: block !important;
          }

        }
      }
    }

    #select-instrument-btn {
      padding: 8px 30px;

      &:after {
        border: 0;
        content: "⌄";
        font-size: 20px;
        top: 0;
        right: 18px;
        z-index: 1;
      }
    }

    .white-border-select {
      margin: 0;

      select {
        display: block;
      }
    }
  }

  .search-tabs {
    background: no-repeat;
    display: flex;
    gap: 30px;
    justify-content: center;
    max-width: fit-content;
    margin: 50px auto;

    .title1 {
      padding: 0 30px;

      &:hover {
        border-bottom: 2px solid $smallslive;
        color: $smallslive;
      }
    }

    .active {

      .title1 {
        border-bottom: 2px solid $smallslive;
        color: $smallslive;
      }
    }
  }

  .search-tab-content {
    display: none;

    &.active {
      display: block;
    }

    .static-text {
      margin-left: 5px;
    }
  }

  .search-content {
    display: none;

    &.active {
      display: block;
    }
  }

  .shows-show-more-wrapper {
    margin: 30px 0;
    text-align: center;

    .shows-show-more {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0;
      color: #fff;
    }
  }

  .all-options {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 100%;

    .instrument {
      width: 100%;
    }
  }

  .shows-container-wrapper{

    .wrapped-events {
      padding-left: 0;
    }
  }

  .instruments-container {
    top: 5px;
  }

  .shows-filter-container {
    gap: 30px;
  }
  
}

.artist-result-wrapper {
    text-align: center;

    .slide-btn.next.slider {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0;
        color: #fff;
        height: auto !important;
        margin: 30px 0 15px;
        position: initial;
        visibility: visible !important;
        width: auto;
    }

    .loading-image {
        position: initial !important;
    }

    .artist-result {
        display: grid !important;
        grid-template-columns: repeat(5, 1fr);
        gap: 15px;
        margin: 0 !important;
        padding: 0 3.5vw;

        .event-display {
            margin: 0;

            > a {
                align-items: center;
                border: 2px solid #fff;
                display: flex;
                gap: 15px;

                &:hover {

                  .event-info {
                    color: var(--color-wine-red);
                  }
                }

                .event-info {
                    color: $black;
                    flex-basis: calc(100% - 100px);
                    height: auto;
                    padding: 5px 0;
                    position: initial;
                    text-align: left;
                }

                .event-card-square {
                    flex-basis: 85px;
                    min-height: unset;

                    .event-picture-container {
                        border-radius: 0;
                    }
                }

                .event-picture {
                    height: 85px;
                    width: auto !important;

                    img {
                        max-height: 100%;
                        object-fit: cover;
                    }
                }

                &:hover {

                    .event-picture {
                        filter: none;
                    }
                }
            }
        }
    }
}

.shows-container-wrapper {

    .wrapped-events {
        display: grid !important;
        grid-template-columns: repeat(5, 1fr);
        gap: 15px;
        margin: 0 !important;
        padding: 0 3.5vw;

        .event-display {
            margin: 0;

            > a {
                align-items: center;
                border: 2px solid #fff;
                display: flex;
                gap: 15px;

                &:hover {

                  .event-info {
                    color: var(--color-wine-red);
                  }
                }

                .event-info {
                    color: $black;
                    flex-basis: calc(100% - 100px);
                    height: auto;
                    padding: 5px 0;
                    position: initial;
                    text-align: left;
                }

                .event-card-secondary {
                    flex-basis: 85px;
                    margin: 0;
                    min-height: unset;

                    .event-picture-container {
                        border-radius: 0;
                    }
                }

                .event-picture {
                    height: 85px;
                    width: auto !important;

                    img {
                        max-height: 100%;
                        object-fit: cover;
                    }
                }

                &:hover {

                    .event-picture {
                        filter: none;
                    }
                }
            }
        }
    }
}


@media (max-width: 960px) {

  //hide top search result div
  .mobile-search-container {
    display: none !important;
  }

  .search-container-with-tabs {
    padding-top: 30px !important;
    position: relative;

    .search-tab-content {
      min-height: unset;
      position: unset;

      &.instrument-btn {
        min-height: auto;
        position: relative;
      }

      &.archive-datepicker-wrapper,
      &.event-section {
        min-height: auto;
        overflow: visible;
      }

      &.active {

        .archive-datepicker {
          display: block !important;
        }
      }

      .musicians-results-title {
        display: block;
        margin-bottom: 10px;
        line-height: 20px;
        text-align: left;
        padding-left: 20px;
      }
    }

    .shows-filter-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .all-options {
      column-count: 2;
      display: block;
    }

    .search-tabs {
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 30px;

      .title1 {
        font-size: 3.5vw;
        border-bottom: none !important;
      }
    }

    .search-content {

      .section-title {
        margin: 20px 0;
        padding-left: 13px;
      }
    }

    //hide datepicker btns
    .datepicker-btn {
      display: none;
    }
    

    .search-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;

      .search-input-wrapper {

        input {
          min-width: 360px;
        }

        .search-bar-autocomplete-container {
          top: 50px;
        }
      }

      .instrument-btn {
        min-width: 360px;

        &:after {
          z-index: 1;
          right: 5px;
        }
      }

      .archive-datepicker {
        display: block;

        .datepicker-container {
          width: 360px;
        }
      }
      
      //hide custom date picker apply btn
      .custom-date-picker-apply {
        display: none;
      }

      .white-border-select {
  
        select {
          min-width: 360px;
          text-align: center;
        }
      }
    }

    .instruments-container {
      height: auto;
      width: 100%;
      padding: 20px 0;
      top: 205px;

      .instrument {
        font-size: 16px;
        padding-bottom: 10px;
        width: 100%;
      }
    }

    .shows-container-wrapper{

      .wrapped-events {
        padding-left: 10px;
      }
    }
  }

  .artist-result-wrapper {

    .slide-btn.next.slider {
      display: block !important;
      margin: 30px auto 15px;
      width: max-content;
    }

    .artist-result {
      grid-template-columns: repeat(2, 1fr);

      .event-display {

        .event-info {
          font-size: 12px;
        }

        .event-info-title {
          width: 100%;
        }
      }
    }
  }

  .shows-container-wrapper {
    padding-left: 0;

    .wrapped-events {
      grid-template-columns: repeat(2, 1fr);

      .event-display {
        
        .event-info {
          font-size: 12px;
        }

        .event-info-title {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 480px) {

  .search-container-with-tabs {

    .search-wrapper {

      .caret {

        &::after {
          display: block;
        }
      }
    }
  }

  .artist-result-wrapper {

    .artist-result {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .shows-container-wrapper {

    .wrapped-events {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}