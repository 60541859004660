.schedule {
  background-color: white;
  border: 1px #CCCCCC solid;
  border-top: none;
  border-left: none;
  padding: 0;

  &.container:before {
    display: none;
  }

  &.flex {
    @include display(flex);
    @include flex-direction(row);
    @include flex-wrap(wrap);
  }

  &__event-details-container {
    width: 100%;
    border-top: $main-accent-color 8px solid;
    border-bottom: $main-accent-color 8px solid;
  }

  &__nav {
    @include display(flex);
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: $main-accent-color;
    color: #fff;
    @include align-items(stretch);

    &:hover {
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      background-image: url("../image/background-tile-red.png");
    }

    & a {
      color: #fff;
      width: 100%;
      padding: 20px;
      @include display(flex);
      -webkit-box-align: center;
      -webkit-flex-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      @include flex-wrap(wrap);
    }

    & .flex-center {
      margin: 0 auto;
    }

    & .icon {
      font-size: 64px;
      line-height: 72px;
      float: left;
    }

    & .text {
      @include lato;
      font-size: 24px;
      text-transform: uppercase;
      float: left;
      line-height: 28px;
      margin: 0 20px;

      & span {
        font-size: 18px;
        display: block;
        width: 100%;
        line-height: 20px;
      }
    }
  }
  
  &__admin-buttons {
    width: 100%;
    float: left;
    margin-top: 5px;
    margin-bottom: 15px;

    &__edit, &__clone {
      @include lato;
      display: block;
      padding: 4px 6px;
      color: #fff;
      border-radius: 5px;
      float: left;
      text-transform: capitalize;

      &:hover {
        color: rgba(255,255,255,0.7);
        text-decoration: none;
      }
    }
  
    &__edit {
      background-color: $main-accent-color;
    }
  
    &__clone {
      background-color: $main-accent-color-darker;
      margin-left: 5px;
    }
  }  
}

.schedule-groupof-4, .schedule-groupof-2 {
  padding: 0;
  margin: 0;
}

.day {
  border-bottom: 1px #CCCCCC solid;
  border-left: 1px #CCCCCC solid;
  background-color: #fff;
  padding: 30px 20px;

  & h3 {
    @include lato;
    color: #ccc;
    font-style: italic;
    font-size: 14px;
  }

  &.flex {

  }

  &:first-child {
      border-left: 1px #CCCCCC solid;
  }

  &--navigate {
    background-color: $main-accent-color;
  }

  &__date {
    @include lato;
    font-size: 16px;
    color: $main-accent-color;
    margin-top: 0;
    margin-bottom: 15px;
  }

  &__event-time {
    @include lato;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 10px;
    margin-bottom: 3px;
  }

  &__event-venue {
    @include lato;
    font-size: 12px;
    color: $main-accent-color;
    //margin-top: 10px;
    //margin-bottom: 3px;
  }

  &__event-title {
    @include lato-bold;
    font-size: 14px;
    color: #000000;

    &.cancelled {
      text-decoration: line-through;
      color: #666;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;

      & .day__event-features {
        color: $main-accent-color;
      }
    }

    & a {
      color: $base-text-color;

      &:hover, &:focus {
        color: lighten($base-text-color, 15%);
      }
    }
  }

  &__event-features {
    margin-top: 4px;
    margin-left: -5px;
    display: block;
    color: lighten($base-text-color, 70%);
    font-size: 12px;
    @include transition(all 0.5s ease-in-out);

    & i {
      margin: 0 5px;
    }
  }

  &__event-cancelled {
    @include lato-bold;
    color: $main-accent-color;
    font-size: 12px;
  }


  & dl {
    margin-bottom: 0;
  }
}

.event-active {
  color: $main-accent-color;
}

.day-active:after {
    bottom: -1px;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(210, 21, 253, 0);
    border-bottom-color: $main-accent-color;
    border-width: 20px;
    margin-left: -20px;
    z-index: 10001;
  }


.schedule .event-select:after {
  border-top-color: $main-accent-color;
  border-bottom-color: rgba(210, 21, 253, 0);
  bottom: -40px;
}

@media (max-width: 767px) {
  .schedule {

    &__nav {

      & .text {
        margin: 10px 0;
      }
    }
  }
}
