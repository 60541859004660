footer {
  background: $light-gray-color;
  font-size: 12px;
  margin-top: auto;
  padding: 15px 50px;

  .footer-inner {
    margin-top: 30px;
  }
}

footer a {
  color: #121212;
}

.footer-info {
  display: none;

  &.active {
    display: flex;
    @media (max-width: $screen-sm-max) {
      /* Reinstalling footer as per Aslan's request */
      //display: none;
    }
  }
}

footer a:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.footer-content {
  text-align: center;
  width: 100%;
}

.footer-logo {
  color: #0044cc;
}

.footer-nav {
  margin: 23px 10px 15px;
  padding: 0;

  & a {
    font-weight: bolder;
  }
}

.footer-nav li {
  display: inline;
}

.footer-nav li:after {
  content: " \00b7";
  font-size: 15px;
}

.footer-nav li:last-child:after {
  content: none;
 }
