.bg-image-calendar {

  &__container {
    padding: 15px 0;
    @include display(flex);
    @include flex-wrap(wrap);
  }

  &__day, &__control {
    list-style-type: none;
    margin-bottom: 20px;
    width: 100%;
    @include display(flex);
    @include flex-direction(column);

    @media (min-width: $screen-sm-min) {
      width: 50%;
    }

    @media (min-width: $screen-md-min) {
      width: 33.3%;
    }

    @media (min-width: $screen-lg-min) {
      width: 25%;
    }

    &__date {
      padding: 15px;
      font-size: 16px;
      @include lato-black;
      color: $main-accent-color;
      margin: 0;
      @include flex-grow(0);
    }
  }

  &__event {
    @include display(flex);
    @include flex-grow(1);
    background-size: cover;
    background-position: center center;
    position: relative;
    cursor: pointer;
    @include transition(all 0.3s ease-in-out);

    &:hover, &:focus {
      @include filter(brightness(80%));
    }

    &__content-container {
      @include display(flex);
      @include align-items(center);
      width: 100%;
      min-height: 120px;
      position: relative;
      z-index: 2;
      background-color: rgba(#000, 0.80);
      padding: 15px 40px 15px 15px;
    }

    .event-time {
      display: block;
      font-size: 12px;
      @include lato-black;
      color: $main-accent-color;
      margin: 0 0 7px;
    }

    .event-name {
      font-size: 14px;
      @include lato-bold;
      color: #fff;
      margin: 0;
      line-height: 150%;
    }

    &__media-indicators-container {
      @include linear-gradient(to right, rgba(#000, 0.0), rgba(#000, 0.8));
      width: 40px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      @include display(flex);
      @include align-items(center);
      @include flex-direction(row);
      @include flex-wrap(wrap);

      i {
        font-size: 14px;
        color: rgba(#fff, 0.6);
        display: block;
        margin: 10px;
      }
    }
  }

  &__control {
    padding-top: 47px;

    &__content-container {
      @include display(flex);
      @include align-items(center);
      @include flex-grow(1);
      background-color: $main-accent-color;
      color: #fff;
      padding: 30px;
      cursor: pointer;

      i {
        font-size: 64px;
        margin-right: 15px;
        @include flex-shrink(0);
      }

      h3 {
        text-transform: uppercase;
        font-size: 24px;
        @include lato;
        line-height: 130%;

        span {
          font-size: 18px;
          display: block;
          line-height: 130%;
        }
      }

      &:hover, &:focus {
        color: rgba(#fff, 0.8);
        background-color: darken($main-accent-color, 5%);
      }
    }
  }
}
