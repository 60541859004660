.about-smalls {
  @include bottom-section-border;
  background-color: white;

  & .row {
    @include display(flex);
    @include align-items(stretch);
  }

  &__info {
    padding: 40px 30px;
  }

  &__img {
    background-image: url('../image/about-smalls-bg.jpg');
    background-size: cover;
    min-height: 320px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    @include lato-black;
    color: $base-text-color;
    font-size: 28px;
    text-align: left;
    line-height: 130%;
  }

  &__text {
    @include lato;
    font-size: 14px;
    margin-bottom: 6px;
  }
}