.homepage-news {
  @include lato;
  background-color: #1F1F1F;
  color: white;
  min-height: 70px;
  padding: 15px 30px;

  &__title {
    @include lato-black;
    font-size: 24px;
    display: inline-block;
    margin-top: 10px;
    margin-right: 14px;
  }

  &__text {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 10px;
  }

  &__input {
    @include border-left-radius(4px);
    border: none;
    font-size: 14px;
    color: #333;
    height: 40px;
    padding-left: 15px;
    width: 70%;
    vertical-align: middle;
    float: left;
  }

  &__button {
    @include transition(all 0.2s ease-in-out);
    @include lato-black;
    @extend .btn;
    font-weight: bold;
    background-color: #555555;
    height: 40px;
    @include border-left-radius(0);
    margin-left: -5px;
    margin-right: 10px;
    float: left;
    width: 28%;

    &:hover {
      color: #fefefe;
      background-color: $main-accent-color;
    }
  }
}

@media (max-width: 700px) {
  .homepage-news {
    text-align: center;

    &__input {
      width: 80%;
      float: none;
      margin: 0 auto;
      margin-top: 10px;
    }

    &__button {
      width: 50%;
      margin: 0 auto;
      border-radius: 4px;
      float: none;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
}