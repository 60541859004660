.tax-letter-container {
  margin: 20px auto;
  width: 624px;

  .tax-letter-header {
    text-align: right;
  }

  .tax-letter-body {
    text-align: left;
  }
}
