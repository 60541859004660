.mini-artist {
  padding-bottom: 30px;

  &__image {
    display: table-cell;

    img {
      padding: 2px;
      border: 1px #ccc solid;
      width: 86px;
      height: 86px;
      @include filter(grayscale(100%));

      &:hover {
        @include filter(none);
      }
    }
  }

  & p {

  }
}

.mini-artist-info {
  display: table-cell;
  vertical-align: top;
  padding-left: 19px;

  &__title {
    @include lato-black;
    font-size: 18px;
    color: #131313;
    margin-top: 0;
    margin-bottom: 2px;
    text-transform: uppercase;

    & a {
      color: #131313;

      &:hover {
        text-decoration: none;
        color: #131313;
      }
    }
  }

  &__instrument {
    @include lato-bold;
    font-size: 14px;
    color: #131313;
    margin-bottom: 5px;
    text-transform: lowercase;
  }

  &__bio {
    @include lato;
    text-align: justify;
  }

  &__sidemen {
    @include lato-bold;
    font-size: 14px;
    color: #131313;
    margin-bottom: 10px;
  }

  &__button {
    @include lato-bold-italic;
    @include red-button;
  }
}