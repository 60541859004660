.archive-page-header {
  padding: 15px 30px 25px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  background-color: $main-accent-color;

  & h1 {
    @include lato-black;
    font-size: 32px;
    color: #fff;
    margin: 0;

    @media(max-width:$screen-xs-max) {
      font-size: 24px;
    }
  }
}

.archive-section-header {
  @include display(flex);
  @include align-items(center);
  @include flex-wrap(wrap);
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;

  &:before, &:after {
    display: none;
  }

  &__title {
    @include lato-black;
    color: #9e9d9d;
    margin: 5px 0;
    width: 50%;
    display: inline-block;
    font-size: 24px;

    @media(max-width:$screen-xs-max) {
      width: 100%;
    }
  }

  &__switches-container {
    display: inline-block;
    width: 50%;
    margin: 10px 0;
    font-size: 18px;
    @include lato-bold;

    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      @include display(flex);
      @include align-items(center);
      @include justify-content(flex-end);
    }

    @media(max-width:$screen-xs-max) {
      width: 100%;
    }
  }

  &__switch {
    display: inline-block;
    padding: 5px 0;
    margin: 0 10px;
    border-bottom: 4px solid transparent;
    color: rgba($base-text-color, 0.7);
    @include transition(all 0.3s ease-in-out);
    cursor: pointer;

    &.active {
      color: $base-text-color;
      border-bottom: 4px solid $main-accent-color;
    }

    &:hover, &:focus {
      color: $base-text-color;
    }
  }
}

.archive-lists {

  &__sidebar {
    background-color: #F7F7F7;
    border-right: 1px #DDDDDD solid;
    padding: 20px;
  }

  &__nav {
    list-style: none;
    padding-left: 0;

    &__item {
      @include lato-black;
      font-size: 18px;
      color: rgba(19, 19, 19, 0.5);
      border-bottom: 1px #DDDDDD solid;
      padding: 20px 0;

      & a {
        color: rgba(19, 19, 19, 0.5);
        text-decoration: none;

        &:hover {
          color: #131313;
          text-decoration: none;

          .badge {
            background-color: #131313;
          }
        }
      }

      &.active a {
        color: $main-accent-color;
      }

      &:last-child {
        border-bottom: 0;
      }

      .badge {
        vertical-align: middle;
        margin-top: -5px;
        margin-left: 2px;
      }
    }
  }
}


.archive-list {
  background-color: #fff;
  padding: 20px;

  &__section-title {
    @include lato-black;
    font-size: 32px;
    color: #999;
    margin-top: 10px;
  }

  &__event {
    padding-bottom: 19px;
    padding-top: 20px;
    border-bottom: 1px solid #ddd;
    @include display(flex);
    @include align-items(center);

    &:last-child {
      border-bottom: 0;
    }

    &__image {
      @include flex-shrink(0);
      width: 100px;
      height: 100px;
      padding: 5px;
      border: 1px solid #ddd;
      margin-right: 20px;

      & img {
        width: 100%;
        height: auto;
      }
    }

    &__info {
      @include flex-shrink(1);
    }

    &__date {
      @include lato-bold;
      font-size: 14px;
      color: $main-accent-color;
      margin-bottom: 3px;
    }

    &__title {
      @include lato-black;
      font-size: 22px;
      color: #131313;
      margin-top: 0;
      margin-bottom: 2px;

      & a {
        color: #131313;

        &:hover, &:focus {
          opacity: 0.8;
        }
      }
    }

    &__meta {
      @include display(flex);
      @include flex-wrap(nowrap);
      margin-top: 8px;

      &__labels {
        @include flex-shrink(0);
        padding-right: 10px;

        & p {
          @include lato;
          font-size: 12px;
          line-height: 17px;
          color: #777;
          margin: 0;
        }
      }

      &__values {
        @include flex-shrink(1);

        & p {
          @include lato-bold;
          font-size: 12px;
          line-height: 17px;
          color: #777;
          margin: 0;
        }
      }
    }

    &__sidemen {
      @include lato-bold;
      font-size: 12px;
      color: #131313;

      & a {
        color: #131313;
      }

      &__section-title {
        @include lato-bold;
        margin-top: 8px;
        margin-bottom: 3px;
        font-size: 12px;
        color: #777777;
      }

      &__instrument {
        @include lato;
        color: #777;
      }
    }
  }
}

.archive-membership {
  border-top: 8px solid $main-accent-color;
}

#most-popular--alltime {
  display: none;
}
