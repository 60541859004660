.page-heading {
  padding: 30px 20px 20px;
  & h1 {
    @include lato-black;
    font-size: 32px;
    color: black;
    margin-bottom: 10px;
  }
}

.artists-list__filters,
.artists-list__search {
  background-color: #f2f2f2;
  padding: 20px;
  border-top: 1px solid #ddd;
  color: #999;

  &:last-child {
    border-bottom: 1px solid #ddd;
  }

  &__heading {
    @include lato;
    font-size: 14px;
    margin-right: 10px;
  }

  & .search {
    margin: 0;
    width: 50%;
    padding: 6px 12px;
    line-height: 23px;
    display: inline-block;
  }

  & input[type="submit"] {
      font-size: 14px;
      padding: 8px 20px;
      color: #fff;
      background-color: $main-accent-color;
    }

  & ul {
      padding: 0;
      margin: 0;

      li {
        @include list-unstyled;
        padding: 5px 20px;
        border-right: 1px solid #ddd;
        display: inline-block;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          border-right: 0;
        }
      }
  }

  &__heading {
    @include lato;
    color: #999;
    font-size: 18px;
  }

  &__group {
    @include lato-bold;
    font-size: 12px;

    & span {
      display: inline-block;
      margin-right: 10px;
    }

    & select {
      display: inline-block;
      width: auto;
      padding: 4px;

      & .disabled {
        color: #cccccc;
      }
    }

    & input[type="submit"] {
      font-size: 12px;
      padding: 6px 14px;
      color: #fff;
      background-color: $main-accent-color;
    }
  }
}

.artists-list__search {
  display: flex;
  gap: 30px;
   justify-content: space-between;

  > form {
    flex-basis: calc(100% - 220px);
  }

  > a {

    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
      box-shadow: none;
      outline: none;
    }
  }
}

.artists-list__list {
  @include lato;
  font-size: 14px;

  & ul {
    padding: 0;
    margin: 0;
  }

  &__header {
    padding: 40px 10px 20px;
    background-color: $main-accent-color;
    color: #fff;
    @include lato;
    font-size: 14px;

    & .sort-column {
      cursor: pointer;

      &:hover {
        color: shade(#fff, 20%);
      }

      & i {
        margin-left: 8px;
      }
    }
  }

  &__states {
    @include display(flex);
  }

  &__state {
    width: 20%;
    text-align: center;

    &.sort-column i {
      font-size: 14px;
      color: #fff;
    }

    & i {
      font-size: 18px;
      color: #aaa;
    }

    & i.true  {
      color: #3F9E52;
    }
  }

  &__row {
    @include list-unstyled;
    @include display(flex);
    @include align-items(center);
    border-bottom: 1px solid #eee;
    background-color: #fff;
    padding: 10px;

    &--empty {
      @include list-unstyled;
      background-color: #fff;
      padding: 10px;
      border-bottom: 1px solid #eee;
    }
  }

  &__image {

    & img {
      width: 40px;
      height: 40px;
      margin: 2px;
      border: 1px solid #ddd;
    }
  }

  &__name {
    @include lato-bold;
  }

  &__actions {
    text-align: right;
  }

  & .btn-group {
    float: right;

    & .btn {
      @include lato;
      border-radius: 0;
      color: #666;
      background-color: #ccc;
      border: 1px solid rgba(0,0,0,0.1);
      border-right: none;

//      &:first-child {
//        border-radius: 4px 0 0 4px;
//        margin-right: -3px;
//      }

      &:last-of-type {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid rgba(0,0,0,0.1);
      }
    }

    & a .btn {
      border-radius: 4px 0 0 4px !important;
      margin-right: -3px;
    }

    & .dropdown-menu {
      padding: 5px 0;

      & li {
        padding: 5px 0;
        @include lato;

        &.warning a {
          color: #D21535;
        }
      }

      & .divider {
        padding: 0;
      }
    }
  }
}

.artists-list__pagination {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #eee;
  border-top: 2px solid #ddd;
}

.artist-list__email-button {
  display: inline-block;
  background-color: $main-accent-color;
  color: #fff;
  padding: 10px 45px;
  @include lato-bold;

  &:hover, &:focus {
    color: rgba(#fff, 0.75);
    padding: 10px 60px;
  }

  &__container {
    max-width: 640px;
    margin: 20px auto 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    text-align: center;
  }
}