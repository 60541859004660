.store-cart__table__container__new {
  padding-top: 30px;

  .ticket-info {
    @include oswald-regular();
    font-size: 24px;
  }
}

.ticket-confirmation-page {

  .title2 {
    font-size: 22px;
    text-transform: none;
    margin-bottom: 20px;
  }

  .text-danger {
    color: #d21535;
    font-weight: 600;

    &.mail-link {
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .normal-text {
    margin-bottom: 25px;
  }
}

#tickets-sold-out-dialog {

  .title2 {
    margin-bottom: 30px;
  }

  .text9 {
    text-align: left;
  }
}

//custom loader for order confirmation button click
#loaderOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.my-loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #d21535;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-left: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 767px) {

  .store-cart__table__container__new {

    > .row {
      align-items: center;
      flex-direction: column;
      gap: 30px;

      > .ticket-pricing {
        width: 100%;
        justify-content: center;

        .quantity {
          margin: 0;
        }
      }
    }
  
    .ticket-info {
      font-size: 18px;
    }

    .store-cart__table__cell__sub-total {
      margin-top: 0;
      justify-content: center;

      .title {
        margin: 0;
      }
    }
  }

  #tickets-sold-out-dialog {

    .modal-dialog {
      max-width: 95%;
    }
  }

  .ticket-confirmation-page {

    .title2 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .normal-text {
      margin-bottom: 20px;
    }

    .text-danger {
      font-size: 14px;

      &.mail-link {
        font-size: 16px;
      }
    }

    .store-header {
      display: none;
      padding: 0;
    }
  }
}
