.album {
  margin: auto;
  max-width: 1300px;
  width: 90%;

  &--library{
    margin-left: 3.5vw;
    margin-top: 30px;
  }

  @media(max-width: 1023px){
    margin-top: 30px;
  }

  &__header{
    display: flex;
    flex-direction: row;

    @media(max-width: 600px){
      display: block;
    }

    &__title{
      display: flex;
      align-self: flex-start;
      flex-direction: column;
      max-width: 65%;
      gap: 15px;

      @media(max-width: 720px){
        max-width: 100%;
      }

      &--library{

      }


      &--underline{
        border-bottom: 5px solid white;
        line-height: 29px;
        width: 113%;
        font-family: "Oswald", sans-serif;
        font-weight: 400;
        font-size: 30px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transform: scaleY(1.3);

        @media(max-width: 720px){
          font-size: 24px;
          line-height: 24px;
          width: 100%;
        }

      }

      &--no-underline{
        font-family: "Oswald", sans-serif;
        font-weight: 400;
        font-size: 30px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transform: scaleY(1.3);

        @media(max-width: 720px){
          font-size: 24px;
          width: 100%;
        }

      }

    }

    &__cover {
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;

      &__artwork{

        img{
          height: 35vw;
          max-height: 480px;

          @media(max-width: 600px){
            margin: auto;
            width: 100%;
            height: 100%;
            max-height: none;
          }
        }
      }

      &__player-controls{
        position: absolute;
        top: calc(17.4874vw - 43.9273875px);
        width: 100%;

        @media(min-width: 1372px){
        top: 196px;
        }

        @media(max-width: 600px){
          top: calc(41.071428571vw - 44.428571426px);
        }

        &__play{
          margin: auto;
        }

      }
    }
  }

  &__info__container {
    flex-direction: row;
    display: flex;
    margin-top: 20px;
  }

  &__support-buttons__container{
    margin-left: auto;
    display: inline-flex;


    @media(max-width: 720px){
      display: none;
    }
    &--mobile{
      margin: auto;
      display: none;
      width: 100%;
      button{
        margin: 5px 5px 0 5px;
      }
      @media(max-width: 720px){
        display: block;
      }
      @media(max-width: 600px){
        margin-top: 20px;
      }
    }

  }

//  &__download-buttons__container{
//    margin-left: auto;
//
//
//    @media(max-width: 720px){
//      display: none;
//    }
//    &--mobile{
//      margin: auto;
//      display: none;
//      width: 100%;
//      @media(max-width: 720px){
//        display: block;
//      }
//      @media(max-width: 600px){
//        margin-top: 20px;
//      }
//    }
//  }


  &__track-list{
    background: white;
    margin-left: 20px;
    width: 100%;
    padding: 0 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 310px;
    max-height: 480px;
    height: 48vw;

    @media(max-width: 600px){
      margin-left: 0;
      margin-top: 20px;
      max-height: none;
      height: 100%;
    }

    &__track{

      &__title{

      }
      &__time{

      }
      &__composer{

      }

    }
  }


  &__musician-list{


    ul {
    list-style: none;
    padding: 0;
    }

    &__musician{

      &__name{

      }
      &__instrument{

      }

    }
  }

  &__more-details{

  }
}
