.admin-nav {
  background-color: $main-accent-color;
  color: #fff;
  padding: 15px;
  @include lato;

  & .heading {
    font-size: 26px;
    @include lato-black;
    display: block;
    float: left;
  }

  & .navbar-toggle {
    color: #fff;
    margin-right: 0;
    border-color: #fff;

    & span {
      background-color: #fff;
    }
  }

  .navbar-toggle i {
    color: #fff;
  }

  & .navbar-collapse {
    float: left;

    & ul {
      margin-bottom: 0;
      padding-left: 0;
    }

    & ul li {
      @include list-unstyled;

      & a {
        display: block;
        font-size: 16px;
        color: #fff;
        padding: 10px 20px;

        &:hover {
          color: rgba(255, 255, 255, 0.6);
          text-decoration: none;
        }
      }
    }

    > ul {

      > li {

        > a {
          padding: 10px 25px 10px 0;
        }
      }
    }

    .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
      background: none;
      color: rgba(#fff, 0.5);
    }

    .dropdown-menu li a {
      color: $base-text-color;

      @media(max-width: $screen-sm-max) {
        color: #fff;

        &:hover, &:focus {
          color: rgba(#fff, 0.7);
        }
      }

      &:hover, &:focus {
        color: rgba($base-text-color, 0.7);
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .admin-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #121212;

    .container {
      padding: 0;
    }

    & .heading {
      display: block;
      margin-top: 6px;
    }

    & .navbar-collapse {
      width: 100%;
      padding-bottom: 15px;
    }

    & ul li {
      text-align: center;
    }
  }
}
