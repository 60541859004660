.home-store {
  @include bottom-section-border;
  background-color: white;
  padding: 10px 30px 30px;

  &__section-title {
    @include section-title;
    margin-bottom: 28px;
  }

  &__products {
    padding: 0;
    margin: 0 -10px;
  }

  &__product {
    list-style-type: none;

    @media (max-width: $screen-xs-max) {

      &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }

    @media (max-width: $screen-sm-max) {

      &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }

    @media (max-width: $screen-md-max) {

      &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }
  }

  &__title {
    @include lato-bold;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 2px;
    height: 40px;
    color: #131313;
    font-weight: 600;
    line-height: 130%;
    overflow: hidden;
    text-overflow: ellipsis;

    & a {
      color: #131313;
      text-decoration: none;
    }

    & a:hover {
      opacity: 0.8;
      color: #131313;
      text-decoration: none;
    }
  }

  &__type {
    @include lato-bold;
    color: #777777;
    font-size: 13px;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__price {
    @include lato-bold;
    color: $main-accent-color;
    font-size: 16px;
    font-weight: 800;
  }

  &__buynow {
    @include lato-bold-italic;
    @include red-button;
    margin-bottom: 20px;
  }

  &__img {
    @include center-block;
    @include img-responsive;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #DFDFDF;
  }
}

.arrow {
  display: block;
  float: left;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-left-color: $black;
}
